import { createI18n } from "vue-i18n";

const messages = {
  en: {
    jobsView: {
      index: {
        No_information_found: "No information was found with filters used.",
        days: " days ago",
        EXPLORER_JOB_OPPORTUNITIES: "EXPLORE JOB OPPORTUNITIES",
        saved_jobs: "Saved jobs",
        Date_Published: "Date Published",
        Deadline: "Deadline",
        create_new_pos_button: "Create new job position",
        edit_job_position_button: "Edit job position",
        apply_button: "Apply",
        applied_button: "Applied",
        go_Certifications_button: "Go to Certifications",
        go_Languages_button: "Go to Languages",
        continue_button: "Continue",
        ok_Got_It_button: "Ok, got it!",
        try_Again_button: "Try again!",
        go_Nist_Nice_button: "Go to NIST NICE",
        personal_information: "Personal information",
        jobs: "Jobs",
        expiration_Date: "Expiration Date",
        This_job_position: "This job position wil be published until",
        according_your_suscription: "according to your suscription plan.",
        Your_profile_has_been: "Your profile has been successfully updated",
        salary_Range: "Salary Range",
        view_Job: "View Job",
        please_Wait: "Please wait...",
        Required_Company: "If required by the company",
        experience_Deleted_msj: "The experience has been deleted.",
        deleting_Experience_msj: "We have an error deleting the experience.",
        your_Experiences_Have_msj:
          "Your experiences have been updated successfully.",
        certification_Been_Deleted_msj: "The certification has been deleted.",
        have_Deleting_Certification_msj:
          "We have an error deleting the certification.",
        have_SomeErrors: "We have some errors.",
        certification_Been_Updated_Successfully:
          "Your certification have been updated successfully.",
        please_Check_Red_Fields: "Please check the red fields.",
        roles_Succesfully_Updated:
          "Your NIST NICE roles have been succesfully updated!",
        invalid_Infromation: "invalid infromation.",
        full_Time: "Full-Time",
        part_Time: "Part-Time",
        self_Employed: "Self-Employed",
        freelance: "Freelance",
        contract: "Contract",
        internship: "Internship",
        apprenticeship: "Apprenticeship",
        seasonal: "Seasonal",
        not_Specified: "Not Specified",
        code: "code",
        description: "description",
        less_Than_High_School: "Less than high school",
        high_School_Diploma_Equivalent: "High school diploma or equivalent",
        Some_College_Degree: "Some college, no degree",
        postsecondary_Degree_Award: "Postsecondary non-degree award",
        associate_Degree: "Associate's degree",
        bachelor_Degree: "Bachelor's degree",
        master_Degree: "Master's degree",
        doctoral_Professional_Degree: "Doctoral or professional degree",
        //clear_filter_button: "Clear filter",
        salary_range_salary:
          "The value entered in the Salary Range Max field must be greater than Salary Range Min.",
      },

      language: {
        ok_Got_It_button: "Ok, got it!",
        try_Again_button: "Try again!",
        language: "Language",
        select_Level: "Select a level",
        begginer: "begginer",
        knowledgeBasic:
          "Knowledge of basic vocabulary words, speaks simple sentences, elementary reading and writing skills.",
        intermediate: "Intermediate",
        handle_Intermediate:
          "Can handle intermediate communication, reading and writing.",
        proficient: "Proficient",
        well_Advanced:
          "A well advanced use of the language, using fluid speech, reading and writing.",
        native_Biligual: "Native/Biligual",
        more_fluent: "More than fluent in the language.",
        language_Been_Deleted: "The language has been deleted.",
        error_deleting_language: "We have an error deleting the language.",
        languages_Succesfully_Updated:
          "Your languages have been succesfully updated",
      },
    },
    plans: {
      PostJobs: "Post Jobs",
      ViewTopTalent: "View Top Talent",
      ContactTalent: "Contact Talent",
      TrainTalent: "Talent Training",
      JobsPostNumber: "Number of Jobs",
      JobPostDuration: "Job Duration",
      AdminCompanyUser: "Platform Users",
      RecommendedTalent: "Top Talent",
      SoftSkill: "Soft Skills",
      HardSkill: "Hard Skills",
      CareerDevelopment: "Career Development",
    },
    trainingView: {
      index: {
        Status: "Status:",
        clear_filter_button: "Clear filter",
        search_placeholder: "Search",
        TRAINING: "TRAINING",
        CERTIFICATION: "CERTIFICATION",
        learn_New_Skills_Through:
          "Learn new skills through top trainings and certifactions to advance your CyberSec career!",
        learn_New_Skills_Through_t:
          "Discover new cybersecurity trainings for you!",
        Viewall: "View All",
        pricing: "Pricing",
        not_Started: "Not Started",
        free: "Free",
        paid: "Paid",
        done: "Done",
        all_Careers: "All Careers",
        training_Provider: "Provider",
        level: "Level:",
        all_Roles: "All Roles",
        categories: "Categories",
        in_Progress: "In Progress",
        certification: "Certification",
        have_Security_Clearance: "have Security Clearance",
        Security_Clearance_required: "Security Clearance required",
        privateJobCompany: "My Company Jobs",
        training: "Training",
        search: "Search",
        items_Found: "Items Found",
        by_Recently_Added: "by Recently Added ↓",
        recently_Updated: "Recently Added",
        last_Month: "Last Month",
        last_Quarter: "Last Quarter",
        last_Year: "Last Year",
        showing: "Showing",
        to: "to",
        of: "of",
        entries: "entries",
        by: "By:",
        author: "Author:",
        duration: "Duration:",
        modules: "Modules",
        regular_Price: "Regular price:",
        subscription_for_take_training:
          "Monthly subscription with possible free trial for first time users",
        education_for_take_training:
          "View provider's official website for pricing information",
        free_training: "Free",
        usd: "USD",
        seccuri_Price: "Seccuri Price",
        contact_Us: "Contact Us",
        target_Of_Training: "Training Details",
        required_For: "Associated Skills",
        There_are_no_skills:
          "There are no skills associated with this training yet.",
        contact_Seccuri_Team: "Contact Seccuri Team",
        special_Prices: "Seccuri's special prices",
        complete_Training: "Updated Training Progress",
        as_Completed: "?",
        contact_For_Special_Seccuri_Price:
          "Contact Us for a special Seccuri price for this training!",
        take_This_Training: "Take This Training >",
        Update_Training_Progress: "Update Training Progress >",
        back_To_Trainings: "Back to trainings",
        check_Training_progress: "Select a Progress Option",
        delete_progress: "Delete Progress",
        are_You_Interested_On_Taking_This_Training:
          "Are you interested on taking this training and gaining access to Seccuri's",
        by_Submitting_This_Request:
          "By submitting this request, we will be sending you an email with information on how to obtain and apply our promo code during this training's purchase process.",
        contact_To_Take_This_Training: "Contact to Take This Training",
        please_Wait: "Please wait...",
        are_You_Sure_You_Want_To_Mark: "Are you currently taking the Training ",
        or_have_already_completed_it: "or have already completed it",
        Change_the_Trainings_Progress_below:
          "Change the Training's Progress below!",
        by_Submitting_This_Request_We_Will_Send:
          "By submitting this request, we will send you an email with information on how we will validate the completed training.",
        SelectProvider: "Select a Provider",
        SelectLevel: "Select a Level",
        SelectLocation: "Select Location",
        SelectJobStatus: "Select Job Status",
        SelectJobPrivacy: "Select Job Privacy",
        InternalexternalRecruitment: "Internal and external recruitment",
        InternalRecruitment: "Internal recruitment",
        ExternalRecruitment: "External recruitment",
        are_You_Interested_On_Taking_This_subscription:
          "Are you interested on receiving more information about our",
        are_You_Interested_On_Taking_This_subscription_2:
          "Please leave your inquiry here",
        are_You_Interested_On_Taking_This_subscription_3: "Plan",
        by_Submitting_This_Request_We_Will_Send_subscription:
          "By submitting this request, we will send you an email with information on how we will validate the purchased process.",
        contact_To_Take_This_subscription: "Contact Us",
        by_Submitting_This_Request_subscription:
          "By submitting this request, one of our advisors will soon be contacting you via email with the rquested information.",
      },
    },
    talentView: {
      index: {
        Referrals: "Referrals",
        If_a_teacher:
          "If a teacher has invited you to join Seccuri enter their referred code to create the relationship",
        saved_Trainings: "Saved Trainings",
        edit_Profile_button: "Edit Profile",
        next_Step_button: "Next Step",
        career: "Career",
        profile_Details: "Profile Details",
        roles: "Roles",
        education: "Education",
        languages: "Language",
        certification: "Certification",
        courses: "Courses",
        recommendations: "Recommendations",
        personal_Information: "Personal Information",
        personal_Data: "Personal data",
        nIST_NICE: "NIST NICE",
        experience: "Experience",
        masters: "Academic degrees",
        certifications: "Certifications",
        courses_Finished: "Courses finished",
        external_Recommendations: "External recommendations",
        area: "Securely Provision",
        overview: "Talent Overview",
        account: "Account",
        is_Begginer: "New",
        is_BegginerDescription: "cybersecurity Talent",
        companies: "Companies",
        relations: "My Company",
        my_Careers: "My Careers",
        saved_Jobs: "Saved Jobs",
        training_Progress: "Training Progress",
        applied_Jobs: "Applied Jobs",
        jobs_with_candidates: "Jobs with Candidates",
        jobs: "Jobs",
        view_applied_talents_Jobs: "View Talent",
      },

      roles: {
        edit_Roles_button: "Edit your roles",
        select_Areas: "1. Select your Areas here",
        improve_Your_Roles: "Improve your cybersecurity Roles!",
        can_Improve_The_Progress:
          "You can improve the progress of your NIST NICE  roles at any moment by updating your areas, specialities and tasks in the ",
        your_Nist_Nice_Roles: "Your NIST NICE Roles",
        your_Roles_And_Their_Progress:
          "Your roles and their progress are based on your selected areas, specialities and tasks.",
      },

      job: {
        back_button: "Back",
        submit_button: "Submit",
        job_Placeholder1:
          "Tell the company why you want to apply for this position here.",
        position_Details: "Position Details",
        basic_Information: "Basic information",
        nist_Nice_Roles: "Cybersecurity Roles",
        specialities_Tasks: "Specialities and Tasks",
        certifications: "Certifications",
        certifications_Required: "Certifications Required",
        languages: "Languages",
        job_Title: "Job Title",
        company_Name: "Company Name",
        privacy: "Define job privacy",
        job_Department: "Job Department",
        job_Description: "Job Description",
        vacancy_Details: "Vacancy Details",
        salary_Range_Min: "Salary Range Min",
        salary_Range_Max: "Salary Range Max",
        employment_Type: "Employment Type",
        duration: "Duration",
        requirements: "Requirements",
        industry: "Industry",
        level_Education: "Level of Education",
        open_Travel: "Open to Travel",
        location: "Location",
        nationatily: "Nationatily",
        contact_Company: "Contact the Company",
        certification: "Certification",
        name: "Name",
        view_Position: "View Position",
        native_And_Learned_Languages_Required:
          "Native and learned languages required",
        units: "Units (Days, Months, etc.)",
        modality: "Modality",
        this_Job_Does_Not_Require_Certifications:
          "This job does not require any NIST NICE role.",
        please_Continue_Browsing_Through:
          "Please continue browsing through the next section.",
        nist_Nice_Roles_Required: "NIST NICE roles required",
        minimum_Percentage_Of_Nist_Nice_Roles:
          "Minimum percentage of NIST NICE roles required to apply to this job position",
        this_Position_Has_No_Specified:
          "This position has no specified Certifications yet!",
        this_Section_Provides_Information:
          "This section provides information about the certifications required by the Company to apply to this job.",
        this_Position_Has_No_specified_Languages:
          "This position has no specified languages yet!",
        provide_Information_About_Your_Languages:
          "Provide information about your languages.",
        use_The_Text_Box_Below:
          "Use the text box below to reach out to this Company.",
        by_Pressing_Submit_You_Consent:
          "By clicking Submit, you indicate that you have read and accepted the ",
        please_Wait: "Please wait...",
        jobs_you_Were_Interested_in_Saving:
          "Jobs you were interested in saving",
        saved_Trainings: "Trainings you were interested in saving",
        you_have_Already_Applied: "You have already applied for these jobs",
        Your_Professional_Careers: "Your Cybersecurity Career Paths",
      },

      settings: {
        update_Email_button: "Update Email",
        cancel_button: "Cancel",
        change_Email_button: "Change Email",
        update_Password_button: "Update Password",
        reset_Password_button: "Reset Password",
        eliminate_Account_button: "Eliminate Account",
        update_privacy: "Personal Data Use",
        Update_Consents: "Update Consents",
        privacy_Account_button: "Updated Privacy",
        placeholder_Email_Adress: "Email Address",
        placeholder_Confirm_Email_Address: "Confirm Email Address",
        email_Address: "Email Address",
        confirm_Email_Address: "Confirm New Email Address",
        new_Email_Address: "Enter New Email Address",
        confirm_Password: "Confirm Password",
        please_wait: "Please wait...",
        password: "Password",
        current_Password: "Current Password",
        new_Password: "New Password",
        confirm_New_Password: "Confirm New Password",
        confirm_Account_Elimination: "Confirm Account Elimination",
        Share_information_companies:
          "Allow Seccuri, Inc. to share your personal information with companies who are looking to hire candidates such as yourself or companies you currently have a relation to in the Seccuri Platfom.",
        Data_processing:
          "Allow Seccuri, Inc. to conduct data processing strictly for the proposes described in its Privacy Statement",
        enter_Email_Address: "Enter Email Address",
        sign_In_Method: "Sign-in Method",
        password_Must_Be_At_Least:
          "Password must be at least 8 character and contain symbols",
        you_Are_Deleting_Your_Account: "You are Deleting Your Account",
        by_Deleting_Your_Account:
          "By deleting your account, all the data and content associated to your profile will be lost.",
        for_Extra_Security:
          "For extra security, this requires you to confirm your email and password.",
        company_Can_See_Your_Profile:
          "The company can see your profile information and your career path.",
        information: "Information",
        relate_Your_Company:
          "Company currently related to your Seccuri Account",
        jobs_with_Talents: "Current Job Posts with Talent Candidates",
        no_relate_Company:
          "You currently have no related companies to your Seccuri Talent account.",
        active_User: "Active User",
        relationship_Status: "Relationship Status",
        ProfileEmployee_Status: "Employee Profile",
        unlink_Account_Button: "Unlink Company",
        Managing_Consent: "Managing Your Consent to Personal Data Usage",
        Managing_Consent_text1:
          "At Seccuri we believe your personal data is crucial to our mission, which is why we make sure your information is well protected and handled properly.",
        Managing_Consent_text2:
          "By registering at our Seccuri Platform, you confirmed your consent to our ",
        Managing_Consent_text3:
          "However, you have the right to withdraw such consent at any given moment for the use of the personal data you voluntarily provided to us.",
        Managing_Consent_text4:
          "If you do not agree, you can choose to discontinue using our services at the Seccuri Plantform by eliminated your account in the section below.",
        have_read: "I have read and agree to the",
        in_wish: "in which you agreed on:",
        communication_preferences: "Communication Preferences",
        privacity_preferences: "Privacy Preferences",
        marketing_communications: "Marketing communications",
        marketing_emails:
          "I agree to receiving ocasional marketing emails from Seccuri, Inc.",
        update_marketing_button: "Update Preferences",
        Top_Talent_Visibility: "Top Talent Visibility",
        Top_talent_description:
          "I authorize my profile to appear as Top Talent for companies interested in finding talent at Seccuri.",
      },

      details: {
        talent_Details: "Talent Details",
        Teacher_details: "Teacher details",
        contact: "Contact",
        basic_Information: "Basic Information",
        nist_Nice_Roles: "Cybersecurity Roles",
        career_path: "Cybersecurity Career",
        education: "Education",
        experience: "Experience",
        certifications: "Certifications",
        courses: "Courses",
        current_Entity: "Current Entity",
        current_Position: "Current Position",
        current_Location: "Current Location",
        nationality: "Nationality",
        seeking_Opportunities: "Seeking Opportunities",
        yes: "Yes",
        no: "No",
        open_Travel: "Open to Travel",
        desired_Employment_Type: "Desired Employment Type",
        highest_Level_Education: "Highest Level Of Education",
        job_Title: "Job Title",
        company: "Company",
        not_Specified: "Not specified",
        industry: "Industry",
        start_Date: "Start Date",
        end_Date: "End Date",
        currenty_Work_Here: "Currently Work Here",
        certification_Name: "certification name",
        issuing_Entity: "Issuing Entity",
        date_Issued: "Date Issued",
        this_certification_Expires: "This certification expires",
        this_Certification_Not_Expires: "This certification not expires",
        degree: "Degree",
        school: "School",
        field_Study: "Field Of Study",
        graduation_Date: "Graduation Date",
        not_Graduated: "Not Graduated",
        activities: "Activities",
        name: "Name",
        number: "Number",
        associated_With: "Associated With",
      },
    },
    companyView: {
      index: {
        Create_administrator_user: "Create administrator user",
        Contacted_by_Company: "Contacted by Company",
        Talent_Applied_to_Job: "Talent Applied to Job",
        Conversation_in_Progress: "Conversation in Progress",
        Hired: "Hired",
        Process_Closed: "Process Closed",
        active: "Active",
        Not_Acepted_jet: "Not Acepted jet",
        Public_Profile: "Public Profile",
        Private_Profile: "Private Profile",
        monthly_button: "Monthly",
        annual_button: "Annual",
        start_button: "Start",
        contact_us_button: "Contact us",
        subscribe_button: "Subscribe",
        edit_Profile_button: "Edit Profile",
        Industria: "Industry",
        back_button: "Back",
        submit_button: "Submit",
        Create: "Create",
        try_Again_button: "Try again!",
        ok_Got_It_button: "Ok, got it!",
        placeholder_Search: "Search",
        company_School_Name: "Company Name",
        security_clearance_level: "Security Clearance Level",
        security_clearance_lastGrantedDate: "Last Security Clearance Granted",
        contact_Number: "Contact Number",
        current_Position: "Current Position",
        country: "Country",
        seeking_Opportunities: "Seeking Opportunities",
        yes: "Yes",
        no: "No",
        company_Name: "Company Name",
        location: "Location",
        ceo: "CEO",
        company_Website: "Company Website",
        jobs: "Jobs",
        employees: "Company Admin Users",
        cyberTeams: "Cyber Team",
        contacted_Candidates: "Contacted Candidates",
        profile_Completion: "Profile Completion",
        full_Profile: "Profile Completion",
        overview: "Company Overview",
        account: "Account",
        settings: "Settings",
        Account_Privacy: "Account Privacy",
        subscription: "Change Subscription",
        profile_Overview: "Profile Overview",
        completed_Sections: "Completed sections: ",
        company_Information: "Company Information",
        company_industry: "Company industry",
        Security_Clearance_required: "Security Clearance required",
        basic_data: "Basic Data",
        soft_skill: "Soft skill",
        added_Employees: "Added Employees",
        created_Positions: "Created Positions",
        edit_Company_Profile: "Edit Company Profile",
        basic_Information: "Basic Information",
        company_Details: "Company Details",
        specified_Organization: "Specified Organization",
        contact_Information: "Contact Information",
        we_Need_Your_Attention: "We need your attention!",
        choose_Your_Plan: "Choose Your Plan",
        company_Profile_Details: "Company Profile Details",
        please_Make_Sure_To_Complete_Your_Talent:
          "To enhance your experience on Seccuri and make it easier for companies to find you, we highly recommend completing your entire profile.",
        you_can_upload:
          "You can upload LinkedIn’s PDF of your CV, and our Seccuri AI will assist in filling out the information!",
        if_You_Need_More_Info_About_Our_Pricing:
          "If you need more info about our pricing, please email us at ",
        Here: "here",
        or_send_us_a_message: "or send us a message",
        Upgrade_a_Plan: "Upgrade a Plan",
        fill_The_Basic_Data: "Fill the Basic data",
        fill_the_Contact_Information: "Fill the contact information",
        clear_filter_button: "Clear Filters",
        Filters: "Filters",
        items_Found: "Items Found",
        by_Recently_Added: "by Recently Added ↓",
        SelectLocation: "Select Location",
        SelectSalaryRange: "Select Salary Range",
        SelectSEducationLevel: "Select Education Levels",
        training_Pie_De_Pagina: "Showing",
        training_Pie_De_Pagina2: "to",
        training_Pie_De_Pagina3: "of",
        training_Pie_De_Pagina4: "entries",
        recently_Updated: "Recently Added",
        last_Month: "Last Month",
        last_Quarter: "Last Quarter",
        last_Year: "Last Year",
        please_Check_Fields_msj: "Please check the red fields.",
        have_SomeErrors: "We have some errors.",
        company_Information_Been_Saved:
          "Your company information has been saved!",
        Upgrade_Subscription: "Upgrade Subscription",
        bill_to: "Billing Email",
        customer_Name: "Customer Name",
        currency: "Currency",
        subscribed_Product: "Subscribed Product",
        billing_Method: "Billing method",
        Annually: "Annually",
        Monthly: "Monthly",
        USDDollar: "USD - US Dollar",
        phone: "Phone",
        talents: "Talent",
        billing_Address: "Billing Information: ",
        reset_Button: "Reset",
        apply_Button: "Apply",
        appliedJobs: "Applied Jobs",
        savedTrainings: "Saved Trainings",
        talent_List: "Talent",
        Back_to_Applied: "Back to Applied Jobs",
        for_Applied_Position_as: "Talent for Position as",
        jobs_with_candidates: "Jobs with Candidates",
        change_status: "Change Status",
      },

      ajustes: {
        update_Password_button: "Update Password",
        reset_Password_button: "Reset Password",
        email_Address: "Email Address",
        confirm_Password: "Confirm Password",
        update_Email: "Update Email",
        sign_In_Method: "Sign-in Method",
        cancel: "Cancel",
        change_Email: "Change Email",
        password: "Password",
        current_Password: "Current Password",
        new_Password: "New Password",
        confirm_New_Password: "Confirm New Password",
        eliminate_Account: "Eliminate Account",
        confirm_Account_Elimination: "Confirm Account Elimination",
        Share_information_companies: "Compartir informacion con empresas",
        Data_processing: "Data processing",
        enter_Email_Address: "Enter Email Address",
        enter_New_Email_Address: "Enter New Email Address",
        confirm_New_Email_Address: "Confirm New Email Address",
        please_Wait: "Please wait...",
        Password_Must_Be_At_Least_8_Character:
          "Password must be at least 8 character and contain symbols",
        deseas_Eliminar_Tu_Cuenta: "You are Deleting Your Account",
        by_Deleting_Your_Account:
          "By deleting your account, all the data and content associated to your company profile will be lost.",
        for_Extra_Security_This_Requires:
          "For extra security, this requires you to confirm your email and password.",
      },

      employees: {
        add_Admin_button: "Add Admin User",
        add_Talent_button: "Add Talent",
        cyberteam: "Your Seccuri's Cyber Team",
        delete_button: "Delete",
        submit_button: "Submit",
        current_Employees: "Current Employees",
        current_Admin_Employees: "Current Company Admin Users",
        instructions: "Instructions: ",
        In_this_section:
          "In this section you will be able to review your profile according to the information provided by the nominator and make any necessary changes.",
        first_Name: "First Name",
        last_Name: "Last Name",
        job_Title: "Job Title",
        password: "Password",
        email: "Email",
        confirm_Password: "Confirm Password",
        please_wait: "Please wait...",
        add_Company_Admin: "Add Company Admin Users",
        add_Company: "Add Company Talent to your Seccuri's Cyber Team!",
        subscription_details: "Subsciption Plan Details",
        add_A_Company_User: "Add A Company User to the Cyber Team",
        add_A_Company_Admin: "Add Company Admin User",
        add_An_Admin_User: "Add An Admin User",
        send_Invitation_Button: "Nominate a teacher",
        send_Invitation_ButtonEmployee: "Send Invitation",
        company_Admin_Users: "Company Admin User: ",
        company_Admin_User_You_Will:
          "A Company Admin User is any internal collaborator you wish to invite to the Seccuri AI platform to help you preform specific tasks.",
        Once_added_Company_Managers:
          "Once added, Company Admin User will be able to edit your Company's profile, add Company Talent, create Job Postings, and search for new Talent.",
        company_Users: "Company Talent: ",
        allow_Other_Members_Of_Your_Company:
          "Track your current employees' cybersecurity career development by inviting them to register at Seccuri AI as Talent users!",
        ask_Other_Members_Of_Your_Company:
          "Once signed up, they will be able to connect to your Company's profile and become part of your Seccuri's Cyber Team!",
        as_A_Company_Admin_User:
          "As the main Company Admin, you are responsible for creating all Company Admin Users' accounts and passwords.",
        Make_sure_to:
          " Make sure to internally share the designated passwords with the corresponding Company Admin User.",
        All_fields_are_mandatory: "All fields are mandatory",
        your_Company_Wants:
          "It will send an invitation to add the user with the assigned email to be part of the cybersecurity team. All fields are mandatory.",
        are_you_sure:
          "Are you sure you want to remove this Company Admin User? This user will no longer have access to your Company's Seccuri account.",
        delete_employee: "Remove Company Admin User",
        delete: "Delete",
        user: "User",
        location: "Location",
        current_Job_Title: "Current Job Title",
        join_Status: "Join Status",
        talent_profile: "User Profile",
        actions: "Actions",
        pending: "Pending",
        join_Talent: "Active Talent",
        contact_Talent_Button: "Contact Talent",
        delete_Talent_Button: "Delete Talent",
        export_Info_to_PDF_Button: "Export Info to PDF",
        public: "Public",
        private: "Private",
      },

      jobs: {
        Date_by_which_applications:
          "Date by which applications will be received.",
        create_new_pos_button: "Create new job position",
        save_As_button: "Save as ",
        change_Status_button: "Change Status",
        Status: "Status:",
        edit_Job_button: "Edit Job",
        show_Profile_button: "Show Profile",
        delete_button: "Delete",
        delete_Job: "Delete Job",
        back_button: "Back",
        jobs: "Jobs",
        Post_job: "Post a Job!",
        Job_Board: "Job Board",
        position_Details: "Position Details",
        nist_Nice_Roles: "Cybersecurity Roles",
        certifications: "Certifications",
        languages: "Languages",
        job_Title: "Job Title",
        company_Name: "Company Name",
        privacy: "Define job privacy",
        job_Department: "Job Department",
        job_Description: "Job Description",
        vacancy_Details: "Vacancy Details",
        salary_Range_Min: "Salary Range Min",
        salary_Range_Max: "Salary Range Max",
        select_Employment_Type: "Select Employment Type",
        duration: "Duration",
        requirements: "Requirements",
        industry: "Industry",
        level_Of_Education: "Level of Education",
        location: "Location",
        select_A_Country: "Select a Country...",
        All_Countries: "All Countries",
        select_a_city: "Select a city...",
        nationatily: "Nationatily",
        expiration_Date: "Expiration Date",
        salary_Range: "Salary Range",
        top: "Top ",
        candidates: "Candidates",
        details: "Details",
        contact: "Contact",
        email: "Email",
        langauge: "Language",
        english: "English",
        change_Job_Status: "Change Job Status",
        published: "Published",
        delete_Job_Position: "Delete Job Position",
        only_required_Company: "Only if required by the Company",
        create_New_Job_Position: "Create new job position",
        basic_Information: "Basic Information",
        are_You_Ready_To_Start_Finding_Talent:
          "Are you ready to start finding new Talent for your Company?",
        privateJobsforCompany: "private Jobs for Company",
        jobs_Subtitle2: "Showing 1 to 10 of 50 entries",
        edit_Job_Position: "Edit job position",
        fill_In_Your_Job_Data: "Fill in your Job data",
        specialities_And_Tasks: "Specialities and Tasks",
        list_Certifications_Required: "List certifications Required",
        fill_Native_And_Learned_Languages:
          "Fill native and learned languages required",
        please_Wait: "Please wait...",
        select_An_Option: "Select an Option...",
        public: " External recruitment",
        private: "Internal recruitment",
        units: "Units (Days, Months, etc.)",
        open_To_Travel: "Open to Travel",
        include_Job_Functions_And_Responsibilities:
          "(Include job functions and responsibilities)",
        these_Are_The: "These are the ",
        candidates_With_More_Match:
          "candidates with more match for this position.",
        Cant_find_any_Top_Talent:
          "Can't find any Top Talent yet? Try adjusting the information of your job posting to help us widen the search and smart matching of Top Talent candidates.",
        the_Percentage_Displayed_For_Each_Talent:
          "The percentage displayed for each Talent indicates how much the candidate matches each of your job posting's roles!",
        the_Current_Status_For_This_Job_Position:
          "The current status for this job position is",
        are_You_Sure_You_Want_To_Change:
          ". Are you sure you want to change the status of this job position to",
        saved_as_Draft: "Saved as Draft",
        are_You_Sure_You_Want_To_Delete:
          "Are you sure you want to delete the job position?",
        tell_this_talent:
          "Tell this Talent why your Company is interested on contacting them for this job",
        use_the_text:
          "Use the text box below to reach out to this candidate. Send them a short message to initiate contact!",
        use_the_text_placeholder: "Your message…",
        contact_to: "Contact",
        the_talent: "the Talent",
        here_You_Can_Send_Message:
          "Use the text box below to send a short message to your cybersecurity Talent!",
        write_Here_The_Message: "Your message…",
        view_applied_Talents: "View Applied Talent",
        Deadline: "Deadline",
      },

      profile: {
        select_Industry_Placeholder: "Select an Industry...",
        select_Country_Placeholder: "Select a Country...",
        select_year_Placeholder: "Select a Year...",
        select_Option_Placeholder: "Select an Option...",
        basic_Information: "Basic Information",
        company_Name: "Company Name",
        industry: "Industry",
        location: "Location",
        description: "Description",
        ceo: "CEO",
        year_Of_Fundation: "Year of Fundation",
        company_Details: "Company Details",
        number_Of_Employees: "Number of Employees",
        revenue_Range: "Revenue Range",
        contact_Information: "Contact Information",
        admin_First_Name: "Admin First Name",
        admin_Last_Name: "Admin Last Name",
        admin_Job_Title: "Admin Job Title",
        phone_Number: "Phone Number",
        Connected_Accounts: "Connected Accounts",
        Connect_authentication_services:
          "Connect the authentication services you need to use to access your seccuri account.",
        Connect: "Connect",
        Desconnect: "Desconnect",
        Microsoft: "You can sign in with your personal o bussiness account",
        LinkedIn: "Integrate your Professional profile",
        Google: "Plan properly your workflow",
        phone_Number_Example: "hone Number Example",
        website: "Website",
        secondary_Email: "Secondary Email",
        country_Code: "Country Code",
        this_Email_Will:
          "This email will allow you recover the main email in case that you forget it.",
        upload_Company_Logo: "Upload Company Logo",
        allowed_File_Types: "Allowed file types: png, jpg, jpeg.",
        select_An_Industry: "Select an Industry...",
        select_A_Country: "Select a Country...",
        select_a_city: "Select a city...",
        select_An_Option: "Select an Option...",
        give_Us_A_Short_Description:
          "Give us a short description of the Company (mission statement, history, business structure, etc.)",
      },

      roles: {
        delete_button: "Delete",
        save_button: "Save",
        placeholder_Search_Tasks: "Search Tasks",
        nist_Nice_Roles: "Cybersecurity Roles",
        options: "options.",
        next_Step: "Next Step",
        confirm_Changes: "Confirm changes",
        certifications: "Certifications",
        add_New: "Add New",
        Add_new_certification: "Add new certification",
        certification: "Certification",
        name: "Name",
        languages: "Languages",
        add_New_Language: "Add New Language",
        please_Define_The_Roles_Associated:
          "Please define the roles associated to your job position!",
        in_This_Section_Which_Consists:
          "In this section, which consists of three steps, you will be selecting the job's NIST NICE roles.",
        please_start_By_Selecting_At_Least:
          "Please start by selecting at least one area of expertise that relates to this job position",
        by_Clicking_Here: "by clicking here",
        improve_Your_Roles: "Improve your roles!",
        you_can_Improve_The_Progress_Of_Your_Roles:
          "You can improve the progress of your NIST NICE  roles at any moment by updating your areas, specialities and tasks in the ",
        Edit_Profile_section: "Edit Profile section.",
        select_Your_Areas: "1. Select your Areas",
        your_Nist_Nice_Roles: "Your NIST NICE Roles",
        the_Job_Position_Roles_And_Their_Progress:
          "The job position's roles and their progress are based on your selected specialities and tasks",
        edit_Your_Roles: "Edit your roles",
        select_Your_Specialities: "2. Select your Specialities",
        select_Your_Tasks: "3. Select your Tasks",
        please_Select_At_Least_One_Area: "Please select at least one area!",
        before_You_Proceed_With_Step:
          "Before you proceed with Step 2, please select at least one area of expertise...",
        please_Select_At_Least_One_Speciality:
          "Please select at least one speciality!",
        to_Continue_With_The_Next_Step:
          "To continue with the next step, please select at least one speciality for the job.",
        please_Select_At_Least_One_Task: "Please select at least one task!",
        to_Continue_With_The_Next_Step_Is_Important:
          "To continue with the next step is important that you select at least one task.",
        choose_Your_Areas: "Choose your areas",
        select_The_Main_Areas_That_Make:
          "Select the main areas that make up your job position. Choose up",
        choose_Your_Specialities: "Choose your specialities",
        select_The_Main_Specialities_Required:
          "Select the main specialities required for the job position. Choose up to",
        choose_Your_Tasks: "Choose your tasks",
        select_All_Of_The_Tasks_That_Will_Need:
          "Select all of the tasks that will need to be performed for the job.",
        You_can_use_the_Search_bar:
          "You can use the Search bar to search for key words related to your tasks.",
        show_Only_Selected: "Show only selected",
        provide_Information_About_Your_Certifications:
          "Provide information about your certifications.",
        provide_Information_About_The_Languages:
          "This section provides information about the languages required by the Company to apply to this job.",
      },

      language: {
        delete_button: "Delete",
        save_button: "Save",
        language: "Language",
        begginer: "Begginer",
        intermediate: "Intermediate",
        proficient: "Proficient",
        native_Biligual: "Native/Biligual",
        select_An_Option: "Select an Option...",
        select_A_Level: "Select a level",
        knowledge_Of_Basic_Vocabulary_Words:
          "Knowledge of basic vocabulary words, speaks simple sentences, elementary reading and writing skills.",
        can_Handle_Intermediate_Communication:
          "Can handle intermediate communication, reading and writing.",
        a_Well_Advanced_Use_Of_The_Language:
          "A well advanced use of the language, using fluid speech, reading and writing.",
        more_Than_Fluent_In_The_Language: "More than fluent in the language.",
      },

      searchTalent: {
        find_The_Best_Cybersecurity_Talent:
          "Find the best cybersecurity talent your company needs",
      },
    },
    loginView: {
      index: {
        continue_button: "Continue",
        register_button: "Register",
        email: "Email",
        password: "Password",
        forgot_Password: "Forgot Password ?",
        company_Account: "Company Account",
        talent_Account: "Talent Account",
        first_Name: "First Name",
        last_Name: "Last Name",
        admin_First_Name: "Admin First Name",
        admin_Last_Name: "Admin Last Name",
        admin_Email: "Admin Email",
        phone: "Phone",
        country: "Country",
        confirm_Password: "Confirm Password",
        login_Tittle1: "Sign In to Seccuri",
        login_Tittle2: "Choose Account Type",
        login_Titlle3: "Create a Talent Account",
        login_Tittle4: "Create a Company Account",
        login_Subtittle1: "New Here?",
        login_subtittle2: "Create an Account",
        login_Subtittle3: "Please wait...",
        login_subtittle4: "Looking for a job",
        login_Subtittle5: "Seeking training opportunities",
        login_Subtittle6: "Looking to hire Talent",
        login_Subtittle7: "Seeking training opportunities for employees",
        login_Subtittle8: "Already have an account?",
        login_Subtittle9: "Sign in here",
        login_Subtittle10: "Select a Country...",
        login_subtittle11:
          "Use 12 or more characters with a mix of letters (lower and upper), numbers & symbols.",
        login_Subtittle12: "I agree to the",
        login_Subtittle13: "Terms and Conditions.",
      },

      forgotPassword: {
        reset_Password_button: "Reset Password",
        cancel_button: "Cancel",
        continue_button: "Continue",
        forgotPassword: "Forgot Password",
        email: "Email",
        OldEmail: "Old Email",
        newEmail: " New Email",
        please_Wait: "Please wait...",
        reset_Your_Password: "Reset your password",
        reset_password_acept_invitation:
          "Reset your Password and Acept Invitation",
        acept_nomination:
          "¡Regístrate para aceptar tu nominación y participar en los Seccuri Awards!",
        reset_Your_Email: "Change your Email",
        token: "Token",
        password: "Password",
        confirm_Password: "Confirm Password",
        forgot_Subtitle1: "Enter your email to reset your password.",
        forgot_Subtitle2:
          "Use 12 or more characters with a mix of letters, numbers & symbols.",
        forgot_Subtitle3:
          "Enter and confirm your new password with the temporal code received by email.",
        forgot_Subtitle4:
          "Enter and confirm your new Email with the temporal code received by email.",
        To_login_to_your_new_seccuri:
          "To login to your new Seccuri account and agree to be part of your company's cybersecurity team, follow the steps below and select continue.",
        Accept_award_new_User:
          "Para acceder a tu nueva cuenta en Seccuri y aceptar tu participación en los Seccuri Awards, llena la siguiente información:",
        Accept_award_new_User_not_Accept:
          "Si por algún motivo no desea aceptar la nominacion pero quiere ser parte de la plataforma, puede registrarse desde nuestra pagina pricipal www.Seccuri.com",
        You_can_unlink_the_company:
          "You can unlink the company at any time from the company option.",
      },
    },
    profileView: {
      index: {
        next_Step_butoon: "Next Step",
        confirm_Changes_button: "Confirm changes",
        back_button: "Back",
        submit_button: "Submit",
        save_Continue_button: "Save and Continue",
        continue_button: "Continue",
        go_Education_button: "Go to Education",
        go_Experiences_button: "Go to Experiences",
        go_Certifications_button: "Go to Certifications",
        go_Courses_button: "Go to Courses",
        go_Languages_button: "Go to Languages",
        go_References_button: "Go to References",
        next_Section_button: "Next Section",
        search_Tasks_Placeholder: "Search Tasks",
        edit_Profile: "Edit Profile",
        personal_Details: "Personal Details",
        nist_Nice_Roles: "Roles cibersecuridad",
        experience: "Experience",
        education: "Education",
        certifications: "Certifications",
        languages: "Languages",
        references: "References",
        courses: "Courses",
        basic_Information: "Basic Information",
        first_Name: "First Name",
        middle_Name: "Middle Name",
        when_was_it_last: "When was it last granted?",
        security_clearance_renewal: "Security Clearance Renewal",
        last_Name: "Last Name",
        gender: "Gender",
        nationality: "Nationality",
        location: "Location",
        contact_Information: "Contact Information",
        phone_Number: "Phone Number",
        website: "Website",
        birthDate: "Birth Date",
        secondary_Email: "Secondary Email",
        this_Email_Will:
          "This email will allow you recover the main email in case that you forget it.",
        education_Information: "Current Information",
        school_Name: "School Name",
        degree_Title: "Lastest Degree Title",
        professional_Information: "New Opportunities",
        please_Take_this_Test:
          "Take your personality test and fill the required information below.",
        personality: "Personality",
        select_personalityType: "Select Personality Result",
        have_Personality_Test: "Have you already taken your personality test?",
        personality_Test_result: "Personality test result",
        personality_Test_Up: "Take your personality test!",
        current_Job_Title: "Current Job Title",
        desired_Employment_Type: "Desired Employment Type",
        company_Name: "Company Name",
        select_your_Areas: "1. Select your Areas",
        select_your_Specialities: "2. Select your Specialities",
        select_your_Tasks: "3. Select your Tasks",
        options: "options.",
        please_Wait: "Please wait...",
        upload_a_Photo: "Upload a Photo",
        profile_Tittle2: "Please select at least one area!",
        profile_Subtittle1: "Fill in your personal data",
        profile_Subtittle2: "Specialities and Tasks",
        profile_Subtittle3: "Fill in your employment history",
        profile_Subtittle4: "List your education history",
        profile_Subtittle5: "List your certifications",
        profile_Subtittle6: "List your native and learned languages",
        profile_Subtittle7: "List your external references",
        profile_Subtittle8: "List your history of courses",
        allowed_File_Types: "Allowed file types: png, jpg, jpeg.",
        gender_Profile: "Select a Gender...",
        select_security_level: "Select a security Level...",
        select_a_Country: "Select a Country...",
        select_a_city: "Select a city...",
        select_a_Department: "Select a department...",
        select_a_Region: "Select a region...",
        profile_Subtittle12: "Select Highest Level of Education",
        profile_Subtittle13: "Select an Option...",
        profile_Subtittle14: "Open to Travel",
        profile_Subtittle15: "Currently Seeking Opportunities?",
        profile_Subtittle16:
          "To continue with the next step is important that you select at least one area.",
        profile_Subtittle17: "Choose your areas",
        profile_Subtittle18:
          "Select the main areas that you have worked in. Choose up to",
        profile_subtittle19: "options.",
        profile_Subtitle20: "Please select at least one speciality!",
        profile_Subtitle21:
          "To continue with the next step is important that you select at least one speciality.",
        profile_Subtitle22: "Please select at least one task!",
        profile_Subtitle23:
          "To continue with the next step is important that you select at least one task.",
        profile_Subtitle24: "Choose your specialities",
        profile_Subtitle25:
          "Select the main specialities that you have worked in. Choose up to",
        profile_Subtitle26: "Choose your tasks",
        profile_Subtitleskill: "Choose your skills",
        profile_Subtitle26knowledge: "Choose your knowledge",
        profile_Subtitle27: "Select all the tasks you have performed.",
        profile_Subtitle28: "Show only selected",
        new_in_cybersecurity: "I am new to cybersecurity",
        hi_new_talent_cybersecurity: "Hello, cybersecurity enthusiast!",
        hi_talent_cybersecurity: "Welcome back, cybersecurity Talent!",
        experience_in_cybersecurity: "I have experience in cybersecurity",
        in_trainings:
          "We invite you to browse our Training section, where you can immerse yourself in different topics through courses and certifications, which will allow you to start your career in cybersecurity.",
        at_the_moment:
          "Once you have obtained some experience in cybersecurity, go to the",
        para_que:
          "option so that we can assess your current skills according to the NIST NICE framework. This will allow us to track your progress and ensure that your profile is more complete when applying for new job opportunities (if desired).",
        select_begginer:
          "By selecting this option, we will share with you all the information about the courses and trainings you can take to start your professional career in cybersecurity.",
        nist_nice: "What is the NIST NICE Framework?",
        here: "here.",
        start_your_career:
          "Feeling ready to assess your current cybersecurity profile?",
        confirm: "Confirm Selection",
        nist_nice_framework:
          "At Seccuri we use different mechanisms to evaluate your cybersecurity career; one of them is through the NIST NICE framework. If you want to learn more about this method click",
        your_nist_nice:
          "To know and modify your NIST NICE roles select the option",
        Seccuri_brings:
          "Seccuri brings you the Seccuri Awards, an exciting contest in which we seek to celebrate the best cybersecurity teachers in the world. Participate now by nominating your favorite cybersecurity teacher and start voting!",
        The_teacher:
          "The teacher with the most votes at the end of the competition will be named the 'Seccuri Star Professor' for Colombia 2024 and will win amazing prizes from our partner companies!",
        The_teacher_chile:
          "The teacher with the most votes at the end of the competition will be named the 'Seccuri Star Professor' for Chile 2025 and will win amazing prizes from our partner companies!",
        Criteriatoparticipate:
          "Criteria to participate as a nominated instructor:",
        Be_teacher_affiliated:
          "Be a teacher affiliated with an educational institution.",
        Teach_classes_in_Chile: "Teach classes in Chile",
        Have_at_least:
          "Have at least 1 year of experience teaching cybersecurity.",
        Teach_a_minimum: "Teach a minimum of 20 students in this field.",
        Provide_proof: "Provide proof of experience in cybersecurity.",
        Thank_you_for_your_interest:
          "Thank you for your interest in the Seccuri Awards!",
        Both_nominations:
          "Both nominations and voting have already closed. Stay tuned to our social networks to find out who is the winner of the 'Seccuri Star Professor' title and amazing prizes from our sponsor companies!",
        Mexico: "Seccuri Awards: México",
        Colombia: "Seccuri Awards: Colombia",
        Chile: "Seccuri Awards: Chile",
        Search_for_teacher: "Search for teacher by name",
        Nominate_Professor: "Nominate a teacher",
        Courses_taught_in_cybersecurity:
          "Courses taught in cybersecurity and academic activities",
        Cant_find_your_cybersecurity:
          "Can't find your cybersecurity teacher on the list? Don't worry, you can nominate and vote for him/her once we have evaluated his profile.",
        Share_Profile: "Share Profile",
        View_Profile: "View Profile",
        you_have_voted: "¡Thank you for your vote!",
        vote: "Vote",
        Knowledge_Area: "Knowledge Area",
        Educational_Institution: "Educational Institution",
        Nominate_your_teacher: "Nominate your teacher for the Seccuri Awards",
        Please_fill_out:
          "Please fill out the form with the requested information to nominate your favorite cybersecurity teacher and send them an invitation to the awards. ",
        self_nominating:
          "In case you are self-nominating, please enter the fields to automatically view your contestant card, vote and share with your colleagues and cyber students!",
        All_fields_marked: " All fields marked with an asterisk ",
        are_mandatory: " are mandatory",
        Describes_the_courses: "",
        comparte_tu_nominacion: "Share your nomination",
        Copia_pega:
          "Share the following text on your social media to announce your nomination and allow your students and colleagues to vote for you.",
        Orgulloso:
          "I am pleased to announce that I have been nominated for the Seccuri Awards, a prestigious event organized by Seccuri, the global platform for cybersecurity skills and talent, together with major international companies. This event aims to recognize the best cybersecurity teachers in the world.",
        ComoVotar_step1: "1. Register on Seccuri as a 'Talent' user at ",
        ComoVotar_step2: "2. Activate your account and log in to the platform.",
        ComoVotar_step3:
          "3. Access the Seccuri Awards - Chile Awards module and vote for my profile.",
        Te_invito:
          "I invite you to support me by voting for me to win the title of 'Seccuri Star Professor - Chile 2025'. To do so, simply follow these steps:",
        tu_apoyo_significa:
          "Your support means a lot to me. I'm counting on you!",
        Share_on_LinkedIn: "Share on LinkedIn",
        Copy_text: "Copy text",
        Ciberseguridad:
          "#Ciberseguridad #Tecnología #TI #Reconocimiento #Educación #Chile #Docentes",
        premiosseccuri: "Seccuri",
        eventoliderado: ", an event led by",
        seccuri: "Seccuri",
        plataforma_global:
          ", the global cybersecurity talent and skills platform, and leading global companies to recognize the best cybersecurity teachers in the world",
        apoyame: "Support me by voting for me to win the title of ",
        seccuriawards: "“Seccuri Star Professor - México 2023”",
        solo_debes:
          "All you have to do is register as a Talent in the link below, activate your account and log in to the platform, and in the Seccuri Awards section, vote for my profile.",
        Apoyame_votando: "Support me by voting for me to win the title of ",
        seccuri_star: "“Seccuri Star Professor - Colombia 2024”.",
        solo_debes_registrarte:
          "All you have to do is register as a Talent in the link below, log in to the Seccuri platform, and in the Seccuri Awards section vote for my profile.",
        hatstag:
          "#Cybersecurity #Tech #TI #Recognition #Education #Colombia #Cyber",
        link_seccuri: "Link to Seccuri: ",
        cuento_con_tu_voto: "I count on your vote!",
        seccuri_career_path: "Cybersecurity Career Paths",
        Develop_and_strengthen:
          "Develop and strengthen your company's cybersecurity workforce with a career development strategy for talent retention and enhanced protection!",
        Seccuri_Career_Paths: "Seccuri Career Paths: ",
        These_are:
          "These are career paths exclusively recommended by Seccuri based on market analysis.",
        public_Career_Paths: "Public Career Paths: ",
        explore_career:
          "Explore career paths created and openly shared by other companies using the Seccuri platform, serving as benchmarks for developing your cybersecurity teams.",
        Explore_relevant_cybersecurity:
          "First, explore relevant cybersecurity career paths, each with recommended trainings, certifications, and knowledge to gain the skillsets requiered to all levels of the career path. Then, assign Seccuri career paths to your Company Talent to manage and monitor their professional growth, aligned with organizational objectives.",
        Feel_free_to_save_and_duplicate:
          "You can duplicate them for customization to align with your company's specific training needs. All customized career paths will be automatically stored in the “Company Career Paths” module for easy access and use. Then, assign these career paths to your Cyber Team members to manage and monitor their professional growth.",
        seccuri_career_Path_this_module:
          "This module has been developed based on marketing analysis by the Seccuri team to offer the cybersecurity careers that are available on the market.",
        seccuri_career_Path_each_career:
          "Each Career Path provides the detailed progress, knowledge and trainings you need to continue to improve your level. Your current levels will be updated each time you finish a Training or complement the NIST NICE section of your profile.",
        seccuri_career_Path_being_updated:
          "This module is constantly being updated with more career paths, trainings and features. ",
        have_Security_Clearance: "I have Security Clearance",
        security_Clearance_required: "Security Clearance Required",
        polygraph_Performed: "Polygraph Performed",
        Make_Career_Path_Public: "Make Career Path Public",
        Make_Cyberteam_Public: "Make Employee Public",
        security_level: "Security Level",
        career_Progress: "Career progress: ",
        view_Button: "View",
        customize: "Customize",
        description: "Description",
        current_Career_Path_Progress: "Your Current Career Path Progress",
        entry_Level: "Entry Level",
        mid_Career: "Mid-Career",
        Professional: "Professional",
        for: "For",
        career_Path_Current_Level: " Career path, your current level is ",
        progress_Bar_Above_Serves_Tool:
          "The progress bar above serves as a tool to help you track your advances in the selected cybersecurity career path. The initial calculation comes from the NIST NICE analysis carried out in your Profile section, based on your current KSAs (Knowledge, Skills, Abilities) and preformed Tasks.",
        if_You_Have_Experience_Cybersecurity:
          "If you have experience in cybersecurity, and have not yet completed the NIST NICE analysis, please head back to the Edit Profile section. In case you are new to Cyber (cybersecurity Enthusiast), feel free to start some of the trainings listed below!",
        continue_Growing_Every_Time_Complete_Training:
          "This bar will continue growing every time you complete a training that certifies you on new required skills to advance in your selected career path.",
        browse_Suggested_Trainings_Below:
          "Browse the suggested trainings below to start upskilling and become one of the best cybersecurity Experts in your field!.",
        trainings_For_This_Career: "Trainings for this career",
        trainings_For_begginer: "Trainings for Beginners",
        back_All_Career_Paths: "Back to All Career Paths",
        view_All_Trainings: "View All Trainings",
        career_Path: "Cybersecurity Career Paths",
        overview: "Overview",
        account: "Account",
        settings: "Settings",
        hey_talent: "Hey Talent! What's your current cybersecurity status?",
        back_how_to_start: "Back",
        jobs_For_This_Career: "Jobs for Beginners",
        blogs_For_This_Career: "Basic Infor for you",
        view_All_jobs: "View All Jobs",
        view_All_blogs: "View All blogs",
      },
      personalities: {
        Inspector:
          "At first glance, ISTJs are intimidating. They appear serious, formal, and proper. They also love traditions and old-school values that uphold patience, hard work, honor, and social and cultural responsibility. They are reserved, calm, quiet, and upright. These traits result from the combination of I, S, T, and J, a personality type that is often misunderstood.",
        Counselor:
          "INFJs are visionaries and idealists who ooze creative imagination and brilliant ideas. They have a different, and usually more profound, way of looking at the world. They have a substance and depth in the way they think, never taking anything at surface level or accepting things the way they are. Others may sometimes perceive them as weird or amusing because of their different outlook on life.",
        Mastermind:
          "INTJs, as introverts, are quiet, reserved, and comfortable being alone. They are usually self-sufficient and would rather work alone than in a group. Socializing drains an introvert's energy, causing them to need to recharge. INTJs are interested in ideas and theories. When observing the world they are always questioning why things happen the way they do. They excel at developing plans and strategies, and don't like uncertainty.",
        Giver:
          "ENFJs are people-focused individuals. They are extroverted, idealistic, charismatic, outspoken, highly principled and ethical, and usually know how to connect with others no matter their background or personality. Mainly relying on intuition and feelings, they tend to live in their imagination rather than in the real world. Instead of focusing on living in the “now” and what is currently happening, ENFJs tend to concentrate on the abstract and what could possibly happen in the future.",
        Craftsman:
          "ISTPs are mysterious people who are usually very rational and logical, but also quite spontaneous and enthusiastic. Their personality traits are less easily recognizable than those of other types, and even people who know them well can't always anticipate their reactions. Deep down, ISTPs are spontaneous, unpredictable individuals, but they hide those traits from the outside world, often very successfully.",
        Provider:
          "ESFJs are the stereotypical extroverts. They are social butterflies, and their need to interact with others and make people happy usually ends up making them popular. The ESFJ usually tends to be the cheerleader or sports hero in high school and college. Later on in life, they continue to revel in the spotlight, and are primarily focused on organizing social events for their families, friends and communities. ESFJ is a common personality type and one that is liked by many people.",
        Idealist:
          "INFPs, like most introverts, are quiet and reserved. They prefer not to talk about themselves, especially in the first encounter with a new person. They like spending time alone in quiet places where they can make sense of what is happening around them. They love analyzing signs and symbols, and consider them to be metaphors that have deeper meanings related to life. They are lost in their imagination and daydreams, always drowned in the depth of their thoughts, fantasies, and ideas.",
        Performer:
          "ESFPs have an Extraverted, Observant, Feeling and Perceiving personality, and are commonly seen as Entertainers. Born to be in front of others and to capture the stage, ESFPs love the spotlight. ESFPs are thoughtful explorers who love learning and sharing what they learn with others. ESFPs are “people people” with strong interpersonal skills. They are lively and fun, and enjoy being the center of attention. They are warm, generous, and friendly, sympathetic and concerned for other people's well-being.",
        Champion:
          "ENFPs have an Extraverted, Intuitive, Feeling and Perceiving personality. This personality type is highly individualistic and Champions strive toward creating their own methods, looks, actions, habits, and ideas — they do not like cookie cutter people and hate when they are forced to live inside a box. They like to be around other people and have a strong intuitive nature when it comes to themselves and others. They operate from their feelings most of the time, and they are highly perceptive and thoughtful.",
        Doer: "ESTPs have an Extraverted, Sensing, Thinking, and Perceptive personality. ESTPs are governed by the need for social interaction, feelings and emotions, logical processes and reasoning, along with a need for freedom. Theory and abstracts don't keep ESTP's interested for long. ESTPs leap before they look, fixing their mistakes as they go, rather than sitting idle or preparing contingency plans.",
        Supervisor:
          "ESTJs are organized, honest, dedicated, dignified, traditional, and are great believers of doing what they believe is right and socially acceptable. Though the paths towards “good” and “right” are difficult, they are glad to take their place as the leaders of the pack. They are the epitome of good citizenry. People look to ESTJs for guidance and counsel, and ESTJs are always happy that they are approached for help.",
        Commander:
          "An ENTJ's primary mode of living focuses on external aspects and all things are dealt with rationally and logically. Their secondary mode of operation is internal, where intuition and reasoning take effect. ENTJs are natural born leaders among the 16 personality types and like being in charge. They live in a world of possibilities and they often see challenges and obstacles as great opportunities to push themselves. They seem to have a natural gift for leadership, making decisions, and considering options and ideas quickly yet carefully. They are “take charge” people who do not like to sit still.",
        Thinker:
          "INTPs are well known for their brilliant theories and unrelenting logic, which makes sense since they are arguably the most logical minded of all the personality types. They love patterns, have a keen eye for picking up on discrepancies, and a good ability to read people, making it a bad idea to lie to an INTP. People of this personality type aren't interested in practical, day-to-day activities and maintenance, but when they find an environment where their creative genius and potential can be expressed, there is no limit to the time and energy INTPs will expend in developing an insightful and unbiased solution.",
        Nurturer:
          "ISFJs are philanthropists and they are always ready to give back and return generosity with even more generosity. The people and things they believe in will be upheld and supported with enthusiasm and unselfishness. ISFJs are warm and kind-hearted. They value harmony and cooperation, and are likely to be very sensitive to other people's feelings. People value the ISFJ for their consideration and awareness, and their ability to bring out the best in others.",
        Visionary:
          "Those with the ENTP personality are some of the rarest in the world, which is completely understandable. Although they are extroverts, they don't enjoy small talk and may not thrive in many social situations, especially those that involve people who are too different from the ENTP. ENTPs are intelligent and knowledgeable need to be constantly mentally stimulated. They have the ability to discuss theories and facts in extensive detail. They are logical, rational, and objective in their approach to information and arguments.",
        Composer:
          "ISFPs are introverts that do not seem like introverts. It is because even if they have difficulties connecting to other people at first, they become warm, approachable, and friendly eventually. They are fun to be with and very spontaneous, which makes them the perfect friend to tag along in whatever activity, regardless if planned or unplanned. ISFPs want to live their life to the fullest and embrace the present, so they make sure they are always out to explore new things and discover new experiences. It is in experience that they find wisdom, so they do see more value in meeting new people than other introverts.",
        default:
          "A brief description of your personality test result will appear here once you have taken the test and included the result.",
        InspectorISTJ: "Inspector - ISTJ",
        CounselorINFJ: "Counselor - INFJ",
        MastermindINTJ: "Mastermind - INTJ",
        GiverENFJ: "Giver - ENFJ",
        CraftsmanISTP: "Craftsman - ISTP",
        ProviderESFJ: "Provider - ESFJ",
        IdealistINFP: "Idealist - INFP",
        PerformerESFP: "Performer - ESFP",
        ChampionENFP: "Champion - ENFP",
        DoerESTP: "Doer - ESTP",
        SupervisorESTJ: "Supervisor - ESTJ",
        CommanderENTJ: "Commander - ENTJ",
        ThinkerINTP: "Thinker - INTP",
        NurturerISFJ: "Nurturer - ISFJ",
        VisionaryENTP: "Visionary - ENTP",
        ComposerISFP: "Composer - ISFP",
      },

      workExperince: {
        add_Job_button: "Add New Job Experience",
        delete_button: "Delete",
        save_button: "Save",
        employment_History: "Employment History",
        employment: "Employment",
        job_Title: "Job Title",
        company: "Company",
        start_Date: "Start Date",
        end_Date: "End Date",
        employment_Type: "Employment Type",
        industry: "Industry",
        job_Description: "Description of your Current Job",
        experience_Subtitle1:
          "Tell us about your professional experience, starting with your latest recent work.",
        experience_Subtitle2: "Currently work here?",
        experience_Subtitle3: "Select an Option...",
        experience_Subtitle4: "About the Company",
        experience_Subtitle5:
          "(Include industry, location, mission statement, etc...)",
        experience_Subtitle6:
          "(Include job functions and responsibilities, work experience, accomplishments, etc...)",
      },

      education: {
        add_New_Education_button: "Add New Education",
        delete_button: "Delete",
        save_button: "Save",
        select_date_Placeholder: "Select date",
        education_Placeholder2:
          "(Write a summary of the groups and activities you were involved with while studying.)",
        education: "Education",
        education1: "Education",
        school: "School",
        degree: "Degree",
        field_Of_Study: "Field of Study",
        graduated: "Graduated",
        graduation_Date: "Graduation Date",
        groups_Activities: "Groups / Activities",
        education_Subtitle1:
          "A varied education on your resume sums up the value that your learnings and background will bring to a job.",
      },

      certifications: {
        add_New_Certification_button: "Add New Certification",
        delete_button: "Delete",
        save_button: "Save",
        select_month_Placeholder: "Select a month",
        certifications: "Certifications",
        certification: "Certification",
        name: "Name",
        issuing_Entity: "Issuing Entity",
        date_Issued: "Date Issued",
        date_Expired: "Date Expired",
        certifications_Subtitle1:
          "Provide information about the certifications achieved. In case you haven't completed a certification, just skip to the next section.",
        certifications_Subtitle_personality:
          "This section of your profile will give us information about the type of soft skills you possess according to a personality test. Be sure to take this test and include the results here so that Seccuri's algorithm can integrate it with your current cybersecurity profile.",
        certification_Subtitle2: "This certification expires",
        Please_provide_following:
          "Please provide the following general information about the company. ",
      },

      courses: {
        add_New_Course_button: "Add New Course",
        delete_button: "Delete",
        save_button: "Save",
        courses: "Courses",
        course: "Course",
        name: "Name",
        number: "Number",
        associated_With: "Associated with",
        courses_Subtitle1:
          "Provide information about the courses taken. In case you haven't completed a course, just skip to the next section",
      },

      languages: {
        add_New_Language_button: "Add New Language",
        delete_button: "Delete",
        save_button: "Save",
        select_Language_Placeholder: "Select a language...",
        languages: "Languages",
        language: "Language",
        select_level: "Select a level",
        begginer: "Begginer",
        intermediate: "Intermediate",
        proficient: "Proficient",
        native_Biligual: "Native/Biligual",
        languages_Subtitle1: "Provide information about your languages.",
        language_Subtitle2: "Select an Option...",
        language_Subtitle3:
          "Knowledge of basic vocabulary words, speaks simple sentences, elementary reading and writing skills.",
        language_Subtitle4:
          "Can handle intermediate communication, reading and writing.",
        language_Subtitle5:
          "A well advanced use of the language, using fluid speech, reading and writing.",
        fluent_in_the_Language: "You are fluent in the language.",
      },

      references: {
        add_New_button: "Add New",
        delete_button: "Delete",
        save_button: "Save",
        references: "References",
        reference: "Reference",
        email: "Email or referral code",
        references_Subtitle1: "Provide information about your references.",
      },
    },
    alertsView: {
      alerts: {
        form_Successfully_Submitted: "Form has been successfully submitted!",
        ok_Got_It_button: "Ok, got it!",
        Passwords_must_match: "Passwords must match",
        try_again_button: "Try again!",
        Ok_button: "Ok!",
        Got_it: "Got it!",
        reset_Password_button: "Reset password!",
        sorry_Looks_Like:
          "Sorry, looks like there are some errors detected, please try again.",
        customer_Required: "Customer name is required",
        customer_Email_Required: "Customer email is required",
        address_Required: "Address 1 is required",
        town_Required: "Town is required",
        state_Required: "State is required",
        post_Code_required: "Post code is required",
        last_Week: "Last week",
        last_month: "Last month",
        last_Three_months: "Last 3 months",
        date_Range_Required: "Date range is required",
        new_Been_Added:
          "Your new user has been registered in your company's cybersecurity team.",
        error_Adding_User: "Error adding user.",
        have_Some_Problems:
          "we have some problems with apply job or send de message to the company",
        hooray_Succesfully_Applied:
          "Hooray! You have succesfully applied to this job. The Company will be reviewing your profile soon and contacting you if interested.",
        Status_Job_Posting: "The status of this job posting has been changed.",
        error_Changing_Status: "We have an error changing status.",
        succesfullyCompleted_Training:
          "You have succesfully updated this status to  ",
        problem_Apply_Job:
          "we have some problems with apply job or send de message to the company",
        sending_Mail_Information:
          "We will be sending you an email with information on how to obtain apply our promo code soon.",
        employee_Been_Deleted:
          "This Company Admin User has been removed from your team.",
        error_Deleting_Employee: "We have an error deleting the employee.",
        join_talent_pending:
          "Your company is waiting for you to accept the invitation to join its team of cybersecurity talent.",
        position_Deleted: "The position has been deleted.",
        error_Deleting_Position: "We have an error deleting the position.",
        now_Submit_Form: "All is cool! Now you submit this form",
        your_Job_Information:
          "Your Job information has been saved! Our specialized AI Algorithm is ready to match your job with our Talent pool!",
        profile_Information_Saved: "Your profile information has been saved!",
        check_Red_Fields: "Please check the red fields.",
        securityClearanceRequired:
          "This Job have security Clearance required. Only the people that configured Security Clearance on your profile information can be apply.",
        Your_file_has_been_successfully:
          "Your file has been successfully uploaded. Our Seccuri AI will analyze it to improve the information provided so far.",
        sorry: "Sorry",
        All_set: "All set!",
        by_acepting_you:
          "By accepting you have security clearance (or have been granted one in the past) you are consenting to giving truthful information that might be needed for further background investigation and candidate-validation processes.",
        in_case_you_currently:
          "In case you currently have an “active” or “current” clearance status and are applying to a job position that requires a determined clearance level, you may still be subject to background revisions on behalf of a company's Human Resource Department or by the Federal Government.",
        We_have_successfully:
          "We have successfully received your nomination request!",
        If_you_have_nominated:
          "Si has nominado a un docente, te recomendamos estar alerta a la publicación de tu perfil en este módulo para empezar a votar cuanto antes.",
        If_you_are_a_teacher:
          "If you are a teacher and you have self-nominated, find your card among the competitors and vote!",
        form_Several_Errors:
          "The form have several errors, please check the red fields.",
        course_Deleted: "The course has been deleted.",
        error_Deleting_Course: "We have an error deleting the course.",
        courses_Updated_Successfully:
          "Your courses have been updated successfully.",
        certification_Deleted: "The certification has been deleted.",
        error_Deleting_Certification:
          "We have an error deleting the certification.",
        certifications_Updated_Successfully:
          "Your certifications have been updated successfully.",
        dateIssueIsRequired: "Please fill in all fields: Issue date",
        startdate: "Please fill in all fields: Start Date",
        enddate: "Please fill in all fields: End Date",
        please_name: "Please fill in all fields: Name",
        Select_level_error: "Please fill in all fields: Select a level",
        education_Deleted: "The education has been deleted.",
        error_deleting_Education: "We have an error deleting the education.",
        education_History_Updated_Successfully:
          "Your education history has been updated successfully.",
        Select_school_error: "Please fill in all fields: School",
        Select_degree_error: "Please fill in all fields: Degree",
        certification_error: "Error adding Certification",
        language_Deleted: "The language has been deleted.",
        error_Deleting_Language: "We have an error deleting the language.",
        languages_Updated_Successfully:
          "Your languages have been updated successfully.",
        reference_Deleted: "The reference has been deleted.",
        error_deleting_Reference: "We have an error deleting the reference.",
        references_Updated_Successfully:
          "Your references have been updated successfully.",
        information_Succesfully_Submitted:
          "your information has been succesfully submitted!",
        invalid_Infromation: "invalid infromation.",
        roles_Updated_Successfully:
          "Your roles have been updated successfully.",
        message_Already_Talent:
          "Your message has been succesfully delivered to your Cyber Talent's email!",
        problems_Sending_Message_Talent:
          "we have some problems sending the message to the talent.",
        account_Eliminated: "Your account has been eliminated.",
        Your_communication_preferences:
          "Your privacy preferences have been successfully updated.",
        email_Password_Invalid: "Email or password invalid.",
        email_Been_reset:
          "Your email has been reset. Please use the new email to login.",
        password_Been_Reset:
          "Your password has been reset. Please use the new email to login.",
        password_Confirmation_Password_Invalid:
          "New password or confirmation password invalid.",
        email_Successfully_Changed: "Email is successfully changed!",
        password_Successfully_Changed: "Password is successfully changed!",
        account_Already_Activated:
          "Your account has been succesfully activated! You will  now be directed to your Seccuri account.",
        token_Invalid: "Token invalid.",
        instructions_Reset_Password:
          "You will receive an email with the instructions to reset your password",
        user_Password_Invalid: "User or password invalid.",
        password_Must_More_Characters:
          "Password must be at least 12 or more characters with a mix of letters, numbers & symbols.",
        password_Changed_Succesfully:
          "Your password has been changed succesfully. Please use the new password to login.",
        email_Changed_Succesfully:
          "Your email has been changed succesfully. Please use the new email to login.",
        maximun_Number_Account_Blocked:
          "You have reached the maximun number of login attempts and your account is blocked. To begin using Seccuri's platform, you will need to reset your password",
        fill_Valid_Phone_Number: "Please fill a valid phone number.",
        password_More_Characters:
          "Password must be at least 12 or more characters with a mix of letters(lower and upper), numbers & symbols.",
        accept_Termns_Conditions:
          "Please read and accept the termns of conditions.",
        accept_privacy_statement:
          "Please read and accept our Privacy statement.",
        successfully_Registered_Seccuri:
          "Congratulations! You have successfully registered to Seccuri. Please check your inbox to verify and activate your account.",
        successfully_Registered_Seccuri_talent:
          "Congratulations! You have successfully registered in Seccuri. Please log in with your email and password.",
        happened_Error_During_Register:
          "Sorry! Happened an error during the register, please try again.",
        exit_edit_profile:
          "Wait! If you close your edit profile now, you could lose your profile information. Please make sure to save your profile before leaving. Are you sure you want to Exit?",
        confirm_exit_edit_profile: "Exit",
        Open_edit_profile_Hi: "Hi ",
        Open_edit_profile:
          "You are about to start editing your profile. Please make sure to save your information as you enter it using the blue “save” buttons for each section, to avoid losing it at the end of the process.",
        password_Expired:
          "For security reasons your password has expired. For continue using Seccuri's Platform you must change it to following the instructions",
        password_Expired_Alert:
          "For security reasons your password will expire in",
        password_Expired_Alert_2:
          "days. Please change your account password as soon as possible for continue using Seccuri's Platform. ",
        temporary_Emails:
          "Seccuri's Platform does not support temporary email addresses.",
        please_Wait: "Please wait...",
        submit: "Submit",
        we_have_errors:
          "We have errors loading the selected career path. Please try again.",
        we_have_errors_link_account:
          "We have errors to link your Talent Account. Please try again from your email Activation.",
        link_company:
          "We are going to proceed to link your Talent account with the company dashboard. Please, Sign in to complete.",
        link_talent_company_success:
          "We have linked your Talent account successfully. You can now see your related company in the section “My Company”.",
        link_accept_Award_success:
          "You are now in the competition! 🎉 You can now find your teacher card in the Seccuri Awards module, vote, and share it with your cybersecurity students and colleagues to gather more votes. Wishing you great success!🚀",
        link_talent_company_fail: "Vinculo no generado.",
        confirm_acept_Awards: "Login",
        confirm_link_talent_account: "Acept Invitation",
        not_acept_link_talent_account: "Not Acept",
        try_again: "Try Activation Again",
        settings: "Settings",
        account: "Account",
        the_Employee_Has_Been_Terminated:
          "The employee has been unlinked from the company.",
        the_Talent_Has_Been_Added:
          "An email notification has been sent to this Talent user, asking them to join your Cyber Team. Upon acceptance, you will gain access to their profile, enabling you to recommend career paths and track their training progress.",
        there_is_Already_a_User:
          "There is already a user related to a company with the same email address.",
        The_email_registered:
          "The email is registered in the platform as a company. Please use a different one.",
        your_info_was_saved: "Your information has been updated.",
        your_Experiences_Could_Not:
          "Your experiences could not be updated successfully. Please try again.",
        error_Occurred_in_the_Invitation:
          "An error occurred in the invitation you tried to make to the talent.",
        unlink_Company: "Unlink Company",
        you_want_to_Unlink_your_Account_From_the_Company:
          "Are you sure you want to unlink your account from the company? The company will be notified of your termination. You will be able to continue using your normal Seccuri account.",
        unlink_Button: "Unlink",
        unlinked_From_the_Company:
          "Your user account has been unlinked from the company. Keep using your free Seccuri account.",
        error_When_Unlinking_the_Company:
          "We have an error when unlinking the company.",
        presenting_Errors_to_Unlink_your_Talent:
          "We are presenting errors to unlink your Talent Account. Try again from your email.",
        already_Exists_an_Account_Registered:
          "Sorry! There already exists an account registered with email address.",
      },
    },
    careerPath: {
      RecommendedByCompany: "Recommended by your company",
      SubscriptionsPlans: "Subscriptions Plans",
      Assign_Career_Path:
        "Know the progress of this Company Talent regarding the selected career plan and recommend it if it aligns with their professional development goals.",
      Assign_Career_Path_Description:
        "Recommend the selected career path to Cyber Team members to ensure the development of necessary skills, in line with your company's cybersecurity objectives. ",
      Recommend: "Recommend",
      undo: "Withdraw Recommendation",
      Duplicate_and_Edit: " Duplicate and Edit",
      Duplicate_and_Edit_title: "Customize the Seccuri Career Path",
      Duplicate_and_Edit_description:
        "Personalize the selected career path by duplicating it and editing its associated trainings and certifications as needed.",
      Asign_careerPath_employee: "Assign Career Path to your Cyber Team",
      Asign_careerPath_employee_description:
        "Recommend the selected career path to Cyber Team members to ensure the development of necessary skills, in line with your company's cybersecurity objectives. ",
      edit_current_career: "Edit Selected career ",
      view_Button: "View",
      customize: "Customize",
      Your_Cybersecurity_Career_Paths: "Your Cybersecurity Career Paths",
      Your_Cybersecurity_Career_Paths_Descipiion:
        "In this module, you will find all your saved career paths and duplicates to use them as a guide and customize them according to your professional career goals.",
      Recommended_Company_Career_Paths: "Recommended Company Career Paths ",
      Recommended_Company_Career_Paths_description:
        "In this module, you will be able to explore the career paths recommended to you by your affiliated company, based on their cybersecurity talent development objectives.",
      Your_Company_Career_Paths: "Your Company's Career Paths",
      Your_Company_Career_Paths_Descroption:
        "In this module, you will find all your Company's saved and personalized career paths. Easily view, customize, and assign them to members of your Cyber Team.",
      Career_Path_Personalization: "Career Path Personalization",
      Career_Path_Personalization_descripcion:
        "Welcome to the Career Path Personalization module! Here, you can tailor the selected career path by customizing associated trainings. The original path comes with suggested trainings for each level, but in this section, you have the power to:",
      optimize_your_cubersecurity:
        "Optimize your cybersecurity journey by creating a personalized career path that aligns perfectly with your goals. Let's get started!",
      optimize_your_cubersecurity_company:
        "Optimize your Talent' cybersecurity journey by creating a personalized career path that aligns perfectly with your mutual goals. Let's get started!",

      Remove_Trainings: "Remove Trainings",
      Remove_Trainings_description:
        ": Eliminate existing trainings to refine the career path according to your career development objectives.",
      Remove_Trainings_description_company:
        ": Eliminate existing trainings to refine the career path according to your company's Talent development objectives.",
      ReCategorize: "Re-Categorize",
      ReCategorize_description:
        ": Place trainings under a career path level of your choice.",
      Add_New_Trainings: "Add New Trainings",
      Add_New_Trainings_description:
        ": Explore Seccuri's Training Ecosystem and assign new trainings to specific career path levels.",
      Remove: "Remove training",
      view_trainings: "Save and Edit Trainings",
      edit_trainings_info:
        "The Trainings you delete can be added back from the Search Trainings option.",
      delete_career: "Delete Career Path",
      are_you_sure: "Are you sure you want to delete the selected Career Path?",
      SearchTrainings: "Search Trainings",
      entry_Level: "Entry Level",
      mid_Career: "Mid-Career",
      Professional: "Professional",
      CareerPathName: "Career Path Name",
      Security_Architect: "Security Architect",
      SOC_Professional: "SOC Professional",
      Red_Team_Professional: "Red Team Professional",
      Cybersecurity_Auditor: "Cybersecurity Auditor",
      Security_ArchitectDescription:
        "This career path oversees assessing an organization's systems for weaknesses, and is responsible for designing, building, testing, and implementing IT security systems.",
      SOC_ProfessionalDescription:
        "A SOC professional monitors, analyzes, and responds to security incidents an organization might be facing. Most common functions include assessing IT systems, identifying, and closing vulnerabilities, and reinforcing security resilience.",
      Red_Team_ProfessionalDescription:
        "This career path oversees the actual strength of an organization's cybersecurity defense by finding weaknesses during system-testing practices and providing valuable feedback that will help reinforce cybersecurity controls.",
      Cybersecurity_AuditorDescription:
        "Evaluates and reports on the effectiveness of implemented cybersecurity controls, policies and overall security of an organization's IT Systems.",
      source: "Source:  ",
      Learning_Path: "Welcome to Cybersecurity From Scratch:",
      Learning_Paths: "Learning Path for Beginners",
      Start_your_journey:
        "Start your journey into cybersecurity with this module for individuals with no prior experience in the field.",
      Start_by_exploring:
        "Explore resources on cybersecurity, enroll in top online trainings to gain and certify expertise, and discover global entry-level jobs and internships to apply and solidify your knowledge.",
      Start_your_journey_company:
        "Introduce your organization’s digital teams to cybersecurity with this module designed to equip individuals with basic knowledge in the field.",
      Start_by_exploring_company:
        "Access open resources and key trainings covering cybersecurity fundamentals, ideal for enhancing organizational awareness and equipping individuals with the necessary insights to begin implementing effective cybersecurity practices or seek a particular career path in cyber.",
      Next_Steps: "Next Steps:",
      As_you_acquire:
        "As you acquire new skillsets and gain experience, revisit Seccuri's NIST NICE Roles module to understand your evolving cybersecurity profile and explore the suggested Career Paths that align with your growing expertise, to keep upskilling and reskilling according to your professional goals.",
      Let_get_started: "Let's get started!",
      Basic_Information: "Resources",
      Trainings: "Trainings",
      Jobs: "Jobs",
      Basic_Information_desc:
        "Access key materials to learn about cybersecurity and its fundamentals. Understand core principles of cybersecurity, different areas of specialization, and the  critical role it plays in the ever-changing digital landscape.",
      Trainings_desc:
        "Explore top-notch online courses to start a comprehensive cybersecurity career, gain essential technical skills and earn certifications to validate your expertise.",
      Basic_Information_desc_company:
        "Access key resources to learn about cybersecurity and its fundamentals and share them with your digital teams. Understand the core principles of cybersecurity, explore different areas of specialization, and recognize its critical role in the constantly evolving digital landscape.",
      Trainings_desc_company:
        "Explore and share top-notch online training courses and certifications with your digital teams to get a comprehensive understanding of cybersecurity or acquire the essential technical skills required to start a career in the field.",
      Jobs_desc:
        "Discover and apply to some of the most popular global entry-level jobs and internships to test your knowledge and skills and gain valuable expertise in the field!",
      Basic_Information_Cybersecurity_Foundations:
        "Resources: Cybersecurity Foundations",
      Welcome_starting:
        "Welcome to the starting point of your cybersecurity journey!",
      Welcome_starting_company:
        "Welcome to the starting point of your digital teams' cybersecurity journey!",
      variety_of_carefully_company:
        "In this module, you'll discover a variety of carefully selected resources, including websites, articles, blogs, videos, white papers, and more, to share with your collaborators for a global and systematic understanding of cybersecurity.",
      Explore_these_valuable_company:
        "Exploring these valuable references will help them gather foundational knowledge essential for their understanding of cybersecurity. Feel free to browse anytime and initiate the path towards having secure digital teams with cybersecurity expertise!",
      variety_of_carefully:
        "In this module, you'll discover a variety of carefully selected resources, including websites, articles, blogs, videos, white papers, and more, to provide you with a global and systematic understanding of cybersecurity. ",
      Explore_these_valuable:
        "Explore these valuable references at your own pace to gather foundational knowledge essential for your understanding of cybersecurity. Feel free to browse anytime and initiate the exciting path to becoming a cybersecurity expert!",
      Trainings_Cybersecurity_Skill_Building: "Trainings:",
      Cybersecurity_Skill: "Cybersecurity Skill Building",
      Find_a_selection:
        "Find a selection of some of the most recommended cybersecurity courses, trainings, and certifications for beginners, offered by leading global training providers.",
      As_you_explore:
        "As you explore and take these trainings and certifications, identify key topics that resonate with you. This knowledge will be valuable later when identifying cybersecurity career paths that best match your interests and goals.",
      Find_a_selection_company:
        "Find a selection of some of the most recommended cybersecurity courses, trainings, and certifications for beginners, offered by leading global training providers.",
      As_you_explore_company:
        "As you explore and share these trainings and certifications with company collaborators interested in initiating a career in cybersecurity, they will be able to identify key topics that resonate with them. This knowledge will be valuable later when identifying cybersecurity career paths that best match their interests and goals.",
      Entry_Level_Jobs: "Entry-Level Jobs:",
      Start_Gaining_Professional: "Start Gaining Professional Experience",
      Explore_top_entry_level:
        "Explore top entry-level jobs and internships worldwide to kickstart your professional experience. Find detailed job descriptions and connect directly with hiring companies to apply to positions that match your skills and interests. Your cybersecurity career starts here!",
      Learn_About_Top: "Learn About Top Cibersecurity Roles for Begginers",
      Top_Cibersecurity: "Top Cibersecurity Roles for Beginners",
      close: "Close",
      viewAll: "View All",
    },
    sessions: {
      description: "Don't miss our next",
      register_button: "Save in your calendar",
      know_more: "More info",
      SeccuriSession: "Seccuri Session",
      webinar: "webinar!",
      date: "Date",
      hour_PST: "PST",
      california_hour: "California Time",
      NewYork_hour: "New York Time",
    },
    SeccuriSessionsMenu: "Seccuri Session Webinars",
    SeccuriSessions: "Seccuri Sessions",
    jobs: "Jobs",
    trainings: "Trainings",
    Awareness: "Awareness",
    Education: "Education",
    level: "Level:",
    provider: "Provider:",
    Career_Path_Level: "Career Path Level:",
    Status: "Status:",
    statussinpoint: "Status",
    Company_Job_Listings: "Company Job Listings",
    Your_CV_is_in_process: "Your CV is being processed!",
    Our_AI_is_analyzing:
      "Our AI is analyzing your PDF file and this may take a few minutes. In the meantime, keep exploring Seccuri. We'll notify you when it's ready for review.",
    since_we_use_AI:
      "Important: Some data may not be 100% accurate, so we recommend checking the information.",
    Everything_ready: "Everything ready!",
    Your_file_has_been_successfully:
      "Your file has been successfully uploaded and analyzed by our Seccuri AI. We recommend that you refresh the page and review your profile information to confirm that everything is correct.",
    File_Upload: "File Upload",
    Drag_and_Drop: "Drag and Drop your file",
    Draganddropbrowse: "Drag & Drop your files or  <u>Browse</u>",
    Please_try_again: "Please try again",
    Oops: "Oops! ",
    Something_went_wrong:
      "Something went wrong while uploading your file. Please try again or upload a different file.",
    tap_to_retry: "Tap to retry",
    Completed: "Completed",
    InProgress: "In Progress",
    Certification: "Certification",
    Training: "Training",
    Course: "Course",
    Foundations: "Foundations",
    Introductory: "Introductory",
    Intermediate: "Intermediate",
    Advanced: "Advanced",
    New_to_Cyber: "New to Cyber",
    Training_in_Progress: "Training in Progress",
    Completed_Training: "Completed Training",
    Delete_Training: "Delete Training",
    language_English: "English",
    language_Spanish: "Spanish",
    language_Portugues: "Portuguese",
    learning: "My Learning",
    accomplishments: "My Accomplishments",
    path: "My Path",
    dashboard: "Dashboard",
    layoutBuilder: "Layout builder",
    craft: "Crafted",
    pages: "Pages",
    profile: "Profile",
    profileOverview: "Overview",
    projects: "Projects",
    campaigns: "Campaigns",
    documents: "Documents",
    connections: "Connections",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Account",
    accountOverview: "Overview",
    settings: "Settings",
    company_Related: "My Company",
    Account_Privacy: "Account Privacy",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    signIn: "Sign-in",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    multiStepSignUp: "Multi-steps Sign up",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Apps",
    chat: "Chat",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Lists",
    widgetsStatistics: "Statistics",
    widgetsCharts: "Charts",
    widgetsMixed: "Mixed",
    widgetsTables: "Tables",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    docsAndComponents: "Docs & Components",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    inviteFriends: "Invite Friends",
    viewUsers: "View Users",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Create Account",
    documentation: "Documentation",
    components: "Components",
    resources: "Resources",
    activity: "Activity",
    customers: "Customers",
    gettingStarted: "Getting Started",
    customersListing: "Customers Listing",
    customerDetails: "Customers Details",
    calendarApp: "Calendar",
    subscriptions: "Subscriptions",
    getStarted: "Getting Started",
    subscriptionList: "Subscription List",
    addSubscription: "Add Subscription",
    viewSubscription: "View Subscription",
    my_Profile: "My Profile",
    my_saved_jobs: "Saved Jobs",
    my_careers: "My careers",
    language: "Language",
    accountSettings: "Account Settings",
    sign_Out: "Sign Out",
    on_Favorites: "On Favorites",
    on_Results: "On Results",
    recommendations: "Recommendations",
    profile_Completion: "Profile Completion",
    overview: "Overview",
    copyright_Seccuri: "Copyright © 2024 Seccuri, Inc. All Rights Reserved.",
    terms_Conditions: "Terms & Conditions",
    and: " and ",
    privacy_Statement: "Privacy Statement",
    profile_Details: "Profile Details",
    talent_Tittle1: "We need your attention!",
    talent_Subtittle1:
      "Please make sure to complete your Talent profile to get a better rating and allow Companies that are publishing jobs find you easier!",
    please_Get_Your_Roles: "Please get your roles!",
    to_get_Roles_Please_Follow:
      "To get the NIST NICE roles, please follow the 1, 2 and 3 steps.",
    you_Can_Start_With_First_Step:
      "You can start with the first step from here",
    select_your_Areas: "1. Select your Areas",
    profile_Overview: "Profile Overview",
    completed_Sections: "Completed sections:",
    home: "Home",
    full_Profile: "Profile Completion",
    all_Jobs: "All Jobs",
    Saved_as_dratf_Jobs: "Saved as dratf Jobs",
    published_jobs: "Published Jobs",
    Your_company_Jobs: "Your company Jobs",
    jobs_Menu: "Jobs",
    edit_Profile: "Edit Profile",
    saved_Jobs: "Saved Jobs",
    applied_Jobs: "Applied Jobs",
    jobs_with_candidates: "Jobs with Candidates",
    trainings_Menu: "Trainings",
    all_Trainings: "All Trainings",
    saved_Trainings: "Saved Trainings",
    training_Progress: "Training Progress",
    career_Paths: "Career Paths",
    Cybersecurity_from_Scratch: "Cybersecurity from Scratch",
    all_Career_Paths: "Cybersecurity Career Paths",
    Company_Career_Paths: "Company Career Paths",
    my_Careers: "My Career Paths",
    saved_Careers: "Saved Careers",
    company_Related_Menu: "My Company",
    admin_Employees: "Company Admin Users",
    cybersecurity_Talent_Team: "Cyber Team",
    seccuriawards_path: "Seccuri Awards",
    MexicoAwards: "Awards Mexico",
    ColombiaAwards: "Awards Colombia",
    ChileAwards: "Awards Chile",
    RoleDiscoveryProgress:
      "Get Ready for your cybersecurity Role Discovery Quiz!",
    Are_you_new_to_cybersecurity: "Are you new to cybersecurity?",
    This_module_is_designed:
      "Discover your ideal cybersecurity roles! Answer a few questions and we'll match you with the best career paths based on your background and interests.",
    I_have_experience_in_cybersecurity: "I have experience in cybersecurity",
    I_am_an_enthusiast: "I am an enthusiast",
    Where_are_you_in_your_career: "Where are you in your career?",
    Graduated_High_School: "Graduated High School",
    I_am_just_graduated:
      "I am doing / just graduated from my Bachelor's Degree",
    I_want_to_change: "I want to change my current career",
    I_am_doing_just_graduated_from_my_Bachelors:
      "I am doing / just graduated from my Bachelor's Degree in cybersecurity",
    I_have_finished_at_least_one_training_in_cybersecurity:
      "I have finished at least one training in cybersecurity",
    I_work_in_cybersecurity: "I work in cybersecurity",
    Let_get_started: "Let's get started!",
    Choose_only_one: "Choose only one",
    Choose_all_that_apply: "Choose all that apply",
    Math: "Math",
    Languages: "Languages",
    Social_Studies: "Social Studies",
    Art: "Art",
    Sciences: "Sciences (physics, chemistry, biology)",
    Technology: "Technology / Computer Science",
    Business: "Business / Economy",
    Major: "Major",
    Write_in: "Write in",
    Minor_Specialization: "Minor / Specialization",
    Marketing: "Marketing",
    Human_Resources: "Human Resources",
    Physical_Security: "Physical Security",
    Accounting: "Accounting",
    Sales: "Sales",
    Data_Analysis: "Data Analysis",
    Other: "Other :",
    What_area_are_you_currently_working_school:
      "what subjects do you like in school?",
    Solving_riddles_and_puzzles_or_logical_problems:
      "Solving riddles and puzzles or logical problems",
    Reading_Writing: "Reading / Writing",
    Building_things_or_craft_making: "Building things or craft making",
    Learning_new_things: "Learning new things",
    Work_in_group_projects: "Work in group projects",
    What_are_you_interested_in: "What are you interested in?",
    What_skills_future: "What skills do you prefer to develop in the future?",
    Which_area_interesting: "Which area(s) do you find most interesting?",
    Would_you_like_problems_under_pressure:
      "Would you like to work in a position where you solve problems under pressure?",
    Information_security_and_system_protection:
      "Information security and system protection",
    Security_incident_and_event_response:
      "Security incident and event response",
    Understand_human_behavior_related_to_cybersecurity:
      "Understand human behavior related to cybersecurity",
    Analyze_data_and_identify_patterns: "Analyze data and identify patterns",
    Investigate_vulnerabilities_and_analyze_cyber_attacks:
      "Investigate vulnerabilities and analyze cyber attacks",
    Protect_and_improve_networks_IT_and_OT_systems:
      "Protect and improve networks, IT and OT systems",
    Design_security_policies_and_strategies:
      "Design security policies and strategies",
    Train_others_on_cybersecurity_good_practices:
      "Train others on cybersecurity good practices",
    Yes_I_work_well_under_pressure_and_solving_problems_quickly:
      "Yes, I work well under pressure and solving problems quickly",
    It_depends_on_the_situation: "It depends on the situation",
    I_prefer_to_work_on_a_less_pressure_environment:
      "I prefer to work on a less pressure environment",
    What_area_are_you_currently_working_in:
      "What area are you currently working in?",
    I_am_doing_just_graduated_from_my_Bachelor:
      "I am doing / just graduated from my Bachelor's Degree",
    What_are_you_studying_What_did_you_study_Major:
      "What are you studying / What did you study? Major",
    What_are_you_studying_What_did_you_study_Minor:
      "What are you studying / What did you study? Minor",
    What_did_you_study: "What are you studying / What did you study?",
    Major_This_includes_any_Graduate_Degree:
      "Major / This includes any Graduate Degree, trade school degree, community college, or other.",
    Minor_Specialization_description: "Minor / Specialization",
    What_coding_language_do_you_know: "What coding language(s) do you know?",
    I_dont_know_coding_languages: "I don't know/use coding languages",
    Endpoint_Security_Tools:
      "Endpoint Security Tools (i.e. Antivirus and Antimalware, EDR, MDM)",
    Encryption_and_Data_Protection_Tools:
      "Encryption and Data Protection Tools (i.e. Full Disk and File Encryption)",
    IAM_Tools:
      "IAM Tools (Identity and Access Management) (i.e. MFA, SSO, PAM)",
    Cloud_Security_Tools: "Cloud Security Tools (i.e. CSPM, CASB)",
    Security_Information_and_Event_Management:
      "Security Information and Event Management (SIEM) Tools",
    Penetration_Testing_Tools: "Penetration Testing Tools",
    Vulnerability_Management_Tools:
      "Vulnerability Management Tools (i.e. Vulnerability scanners, patch management)",
    Backup_and_Disaster_Recovery_Tools: "Backup and Disaster Recovery Tools",
    Network_Security_Tools:
      "Network Security Tools (i.e. Firewalls, IDS/IPS, NAC, VPN, DNS, WAF)",
    Risk_Management_and_Compliance_Tools:
      "Risk Management and Compliance Tools",
    Threat_Intelligence_Tools: "Threat Intelligence Tools",
    Incident_Response_Tools:
      "Incident Response Tools (i.e. incident management tools, forensics tools)",
    None: "None",
    What_tools_do_you_use_or_have_learned_how_to_use:
      "What tools do you use or have learned how to use?",
    What_areas_in_cybersecurity_are_you_interested_in:
      "What areas in cybersecurity are you interested in?",
    Securely_Provision: "Securely Provision",
    Operate_and_Maintain: "Operate and Maintain",
    Oversee_and_Govern: "Oversee and Govern",
    Protect_and_Defend: "Protect and Defend",
    Analyze: "Analyze",
    Collect_and_Operate: "Collect and Operate",
    Investigate: "Investigate",
    What_training_or_certification_in_cyberescurity_have_you_completed:
      "What training(s) or certification(s) in cybersecurity have you completed?",
    Training_certification: "Training / certification",
    How_long_have_you_worked_in_cybersecurity:
      "How long have you worked in cybersecurity?",
    Experience_less_than_1_year:
      "I have less than 1 year of experience in cybersecurity",
    Experience_1_to_3_years:
      "I have between 1-3 years of experience in cybersecurity",
    Experience_more_than_3_years:
      "I have more than 3 years of experience in cybersecurity",
    What_is_your_current_role: "What is your current role?",
    Role: "Role",
    Industry: "Industry",
    Which_tools_technologies_do_you_use_or_have_learned_how_to_use:
      "Which tools/technologies do you use or have learned how to use?",
    What_training_or_certification_in_cybersecurity_have_you_completed:
      "What training or certification in cybersecurity have you completed?",
    Your_Results_Are_In: "Your Results Are In!",
    Thanks_for_taking_the_quiz:
      "Thanks for taking the quiz! Below is a breakdown of how your profile aligns with cybersecurity roles (% fit) based on your answers. Explore Seccuri's modules to start training, earn certifications, and find jobs aligned with these roles when you're ready!",
    Enhance_Your_Results: "Enhance Your Results",
    Add_specific_NIST_NICE_KSTs:
      "Add specific NIST NICE KSTs for even better recommendations. AI suggestions are personalized but not always 100% accurate—your input makes them smarter!",
    The_quiz_is_complete_In_just_a_moment:
      "The quiz is complete! In just a moment, our AI will analyze your responses and show you the cybersecurity roles that best match you.",
    Please_select_an_answer_to_continue: "Please select an answer to continue.",
    Please_select_at_least_one_answer_to_continue:
      "Please select at least one answer to continue.",
    Please_include_answer_before_moving:
      "Please include an answer before moving to the next step",
    Go_to_Result: "Go to Result",
  },
  es: {
    jobsView: {
      index: {
        days: " días atrás",
        No_information_found:
          "No se ha encontrado información con los filtros utilizados.",
        EXPLORER_JOB_OPPORTUNITIES: "Oportunidades de empleo para explorar",
        saved_jobs: "Empleos guardados",
        Date_Published: "Fecha de publicación",
        Deadline: "Fecha de cierre",
        create_new_pos_button: "Crear nueva vacante",
        edit_job_position_button: "Editar puesto de trabajo",
        apply_button: "Aplicar",
        applied_button: "Aplicada",
        go_Certifications_button: "Ir a las certificaciones",
        go_Languages_button: "Ir a los idiomas",
        continue_button: "Continuar",
        ok_Got_It_button: "¡Listo!",
        try_Again_button: "or favor, intenta de nuevo.",
        go_Nist_Nice_button: "Ir a los roles NIST NICE",
        personal_information: "Información personal",
        jobs: "Empleos",
        expiration_Date: "Fecha de vencimiento",
        This_job_position: "Este puesto de trabajo se publicará hasta el",
        according_your_suscription: "según su plan de suscripción.",
        salary_Range: "Rango salarial",
        view_Job: "Información de la vacante",
        please_Wait: "Espera, por favor...",
        Your_profile_has_been: "Su perfil se ha actualizado correctamente",
        Required_Company:
          "En caso de ser requerido por la empresa contratante.",
        experience_Deleted_msj: "La experiencia ha sido eliminada.",
        deleting_Experience_msj:
          "Ha ocurrido un error eliminando la experiencia. Por favor, inténtalo más tarde.",
        your_Experiences_Have_msj:
          "Tus experiencias se han actualizado con éxito.",
        certification_Been_Deleted_msj: "La certificación ha sido eliminada.",
        have_Deleting_Certification_msj:
          "Ha ocurrido un error al eliminar la certificación. Por favor, inténtalo más tarde.",
        have_SomeErrors:
          "Se ha producido un error. Por favor, inténtalo más tarde.",
        certification_Been_Updated_Successfully:
          "Tu certificación ha sido actualizada con éxito.",
        please_Check_Red_Fields:
          "Por favor revisa los campos obligatorios en rojo.",
        roles_Succesfully_Updated:
          "¡Tus roles de NIST NICE se han actualizado con éxito!",
        invalid_Infromation: "Información no válida",
        full_Time: "Tiempo completo",
        part_Time: "Medio tiempo",
        self_Employed: "Independiente",
        freelance: "Freelance",
        contract: "Contrato de trabajo",
        internship: "Práctica",
        apprenticeship: "Aprendizaje",
        seasonal: "Temporal",
        not_Specified: "No especificado",
        code: "codigo",
        description: "descripcion",
        less_Than_High_School: "Menos que el Colegio",
        high_School_Diploma_Equivalent:
          "Diploma de escuela secundaria o su equivalente",
        Some_College_Degree: "Un poco de universidad, sin título",
        postsecondary_Degree_Award: "Título de educación superior",
        associate_Degree: "Título de asociado",
        bachelor_Degree: "Licenciatura",
        master_Degree: "Maestría",
        doctoral_Professional_Degree: "Doctorado",
        //clear_filter_button: "Limpiar Filtro",
        salary_range_salary:
          "El valor ingresado en el rango máximo del salario debe ser mayor que el rango mínimo.",
      },

      language: {
        ok_Got_It_button: "¡Hecho!",
        try_Again_button: "¡Intentar de nuevo!",
        language: "Idioma",
        select_Level: "Selecciona un nivel",
        begginer: "Nivel básico",
        knowledgeBasic:
          "Conoces palabras básicas, hablas oraciones simples, y tienes algunas habilidades de lectura y escritura.",
        intermediate: "Intermedio",
        handle_Intermediate:
          "Manejas una comunicación, lectura y escritura intermedia.",
        proficient: "Competente",
        well_Advanced:
          "Tienes un manejo avanzado del idioma, con fluidez en el habla, la lectura y la escritura.",
        native_Biligual: "Nativo/Bilingüe",
        more_fluent: "Eres más que fluido en el idioma.",
        language_Been_Deleted: "El idioma ha sido eliminado.",
        error_deleting_language: "Tenemos un error al eliminar el idioma.",
        languages_Succesfully_Updated:
          "Tus idiomas se han actualizado con éxito",
      },
    },
    plans: {
      PostJobs: "Publicar empleos",
      ViewTopTalent: "Ver los mejores Talent",
      ContactTalent: "Contactar talent",
      TrainTalent: "Formación de talent",
      JobsPostNumber: "Número de empleos",
      JobPostDuration: "Duración del trabajo",
      AdminCompanyUser: "Usuarios de la plataforma",
      RecommendedTalent: "Talent recomendado",
      SoftSkill: "Habilidades blandas",
      HardSkill: "Habilidades técnicas",
      CareerDevelopment: "Desarrollo profesional",
    },
    trainingView: {
      index: {
        Status: "Estado:",
        clear_filter_button: "Limpiar filtros",
        search_placeholder: "Buscador",
        TRAINING: "ENTRENAMIENTO",
        CERTIFICATION: "CERTIFICACIÓN",
        learn_New_Skills_Through:
          "¡Descubre nuevos retos de entrenamiento en ciberseguridad para tu equipo!",
        learn_New_Skills_Through_t:
          "¡Descubre nuevos entrenamientos de ciberseguridad para ti!",
        Viewall: "Ver todos",
        pricing: "Precio",
        not_Started: "Sin empezar",
        free: "Gratis",
        paid: "Con Cobro",
        done: "Terminado",
        all_Careers: "Todas las carreras",
        training_Provider: "Proveedor",
        level: "Level",
        all_Roles: "Todos los roles",
        categories: "Categorías",
        in_Progress: "En progreso",
        certification: "Certificación",
        Security_Clearance_required: "Autorización de seguridad requerida",
        privateJobCompany: "Empleos en mi empresa",
        training: "entrenamiento",
        search: "Buscar",
        items_Found: "Entrenamientos encontrados",
        by_Recently_Added: "Agregados Recientemente ↓",
        recently_Updated: "Agregado recientemente",
        last_Month: "El mes pasado",
        last_Quarter: "El trimestre pasado",
        last_Year: "El año pasado",
        showing: "Mostrando",
        to: "a",
        of: "de",
        entries: "entradas",
        by: "Por:",
        author: "Autor:",
        duration: "Duración:",
        modules: "Módulos",
        regular_Price: "Precio en el mercado:",
        subscription_for_take_training:
          "Suscripción mensual con posibilidad de prueba gratuita para los primeros usuarios",
        education_for_take_training:
          "Consulte el sitio web oficial del proveedor para obtener información sobre precios",
        free_training: "Gratuito",
        usd: "USD",
        seccuri_Price: "Precio con Seccuri",
        contact_Us: "Contáctate con nosotros",
        target_Of_Training: "Detalles del entrenamiento",
        required_For: "Habilidades asociadas",
        There_are_no_skills:
          "Aún no hay habildades asociadas a este entrenamiento",
        contact_Seccuri_Team:
          "Ponte en contacto con nuestro equipo en Seccuri!",
        special_Prices: "nuestros precios especiales",
        complete_Training: "Actualizar progreso de entrenamiento",
        as_Completed: "?",
        contact_For_Special_Seccuri_Price:
          "¡Contáctenos para un precio especial en esta entrenamiento!",
        take_This_Training: "Toma este entrenamiento",
        Update_Training_Progress: "Actualizar el progreso de el entrenamiento",
        back_To_Trainings: "Volver a los entrenamientos",
        check_Training_progress: "Seleccione una opción de progreso",
        delete_progress: "Eliminar progreso",
        are_You_Interested_On_Taking_This_Training:
          "¿Está interesado en tomar esta entrenamiento de Seccuri y obtener su acceso?",
        by_Submitting_This_Request:
          "Al enviar esta solicitud, le enviaremos un correo electrónico con información sobre cómo obtener y aplicar nuestro código de promoción durante el proceso de compra de esta entrenamiento.",
        contact_To_Take_This_Training: "Contacto para tomar esta entrenamiento",
        please_Wait: "Espera, por favor...",
        are_You_Sure_You_Want_To_Mark:
          "Estás cursando actualmente el entrenamiento",
        or_have_already_completed_it: "o ya lo ha completado",
        Change_the_Trainings_Progress_below:
          "Cambia el Progreso del Entrenamiento a continuación!",
        by_Submitting_This_Request_We_Will_Send:
          "Al enviar esta solicitud, le enviaremos un correo electrónico con información sobre cómo validaremos la entrenamiento completa.",
        SelectProvider: "Selecciona un proveedor",
        SelectLevel: "Selecciona un nivel",
        SelectLocation: "Selecciona una ubicación",
        SelectJobStatus: "Selecciona el estado del trabajo",
        SelectJobPrivacy: "Selecciona la privacidad del trabajo",
        are_You_Interested_On_Taking_This_subscription:
          "¿Estás interesado en subscribirte al",
        are_You_Interested_On_Taking_This_subscription_2:
          "Por favor deje aquí su consulta",
        by_Submitting_This_Request_We_Will_Send_subscription:
          "Al enviar esta solicitud, le enviaremos un correo electrónico con información sobre cómo validaremos el proceso de compra.",
        contact_To_Take_This_subscription: "Contáctanos",
        by_Submitting_This_Request_subscription:
          "Al enviar esta solicitud, uno de nuestros asesores se pondrá pronto en contacto con usted por correo electrónico para facilitarle la información solicitada.",
      },
    },
    talentView: {
      index: {
        Referrals: "Referidos",
        If_a_teacher:
          "Si un docente te ha invitado a unirte a Seccuri ingresa el codigo de referido para crear la relación",
        saved_Trainings: "Entrenamientos guardadas",
        edit_Profile_button: "Editar perfil",
        next_Step_button: "Siguiente paso",
        career: "Carrera profesional",
        profile_Details: "Detalles del perfil",
        roles: "Roles",
        education: "Educación",
        languages: "Idioma",
        certification: "Certificación",
        courses: "Cursos",
        recommendations: "Recomendaciones",
        personal_Information: "Información personal",
        personal_Data: "Datos personales",
        nIST_NICE: "NIST NICE",
        experience: "Experiencia",
        masters: "Títulos profesionales",
        certifications: "Certificaciones",
        courses_Finished: "Cursos terminados",
        external_Recommendations: "Recomendaciones externas",
        area: "Provisión segura",
        overview: "Información general",
        jobs_with_candidates: "Empleos con candidatos",
        account: "Cuenta",
        is_Begginer: "Nuevo",
        is_BegginerDescription: "Talent de ciberseguridad",
        companies: "Empresas",
        relations: "Empresa relacionada",
        my_Careers: "Mis planes de carrera",
        saved_Jobs: "Empleos guardados",
        training_Progress: "Progreso de entrenamientos",
      },
      roles: {
        edit_Roles_button: "Editar Roles",
        select_Areas: "1. Selecciona tus Áreas para empezar.",
        improve_Your_Roles: "¡Actualiza tus roles de ciberseguridad!",
        can_Improve_The_Progress:
          "Puede mejorar tus roles de NIST NICE en cualquier momento actualizando tus áreas, especialidades y tareas de ciberseguridad editando esta sección ",
        your_Nist_Nice_Roles: "Roles NIST NICE",
        your_Roles_And_Their_Progress:
          "Tus roles y su progreso se basan en las áreas, especialidades y tareas seleccionadas.",
      },

      job: {
        back_button: "Atrás",
        submit_button: "Enviar",
        job_Placeholder1:
          "Cuéntale a la empresa por qué estás interesado en postularte a esta vacante aquí.",
        position_Details: "Detalles de la vacante",
        basic_Information: "Información básica",
        nist_Nice_Roles: "Roles cibersecuridad",
        specialities_Tasks: "Especialidades y tareas",
        certifications: "Certificaciones",
        certifications_Required: "Certificaciones requeridas para el cargo",
        languages: "Idiomas",
        job_Title: "Título del empleo",
        company_Name: "Nombre de la empresa",
        privacy: "Definir privacidade do trabalho",
        job_Description: "Descripción del empleo",
        job_Department: "Departamento del empleo",
        vacancy_Details: "Detalles de la vacante",
        salary_Range_Min: "Rango mínimo de salario",
        salary_Range_Max: "Rango máximo de salario",
        employment_Type: "Descripción del empleo",
        duration: "Duración",
        requirements: "Requerimientos",
        industry: "Industria",
        level_Education: "Nivel de educación",
        open_Travel: "Disponibilidad para viajar",
        location: "Ubicación",
        nationatily: "Nacionalidad",
        contact_Company: "¡Ponte en contacto con la empresa!",
        certification: "Certificación",
        name: "Nombre",
        view_Position: "Información de la vacante",
        native_And_Learned_Languages_Required:
          "Idiomas requeridos para el cargo",
        units: "Unidades (Días, Meses, etc.)",
        modality: "Modalidad",
        this_Job_Does_Not_Require_Certifications:
          "Este trabajo no requiere ninguna rol NIST NICE.",
        please_Continue_Browsing_Through:
          "Por favor, continúa con la siguiente sección.",
        nist_Nice_Roles_Required: "Roles requeridos de NIST NICE",
        minimum_Percentage_Of_Nist_Nice_Roles:
          "Porcentajes mínimos de los roles NIST NICE requeridos para postularse a este puesto de trabajo",
        this_Position_Has_No_Specified:
          "¡Esta vacante aún no tiene certificaciones especificadas!",
        this_Section_Provides_Information:
          "Esta sección proporciona información sobre las certificaciones requeridas por la empresa para aplicar a esta vacante.",
        this_Position_Has_No_specified_Languages:
          "¡Esta vacante aún no tiene idiomas especificados!",
        provide_Information_About_Your_Languages:
          "A continuación encontrarás los idiomas requeridos para aplicar al cargo.",
        use_The_Text_Box_Below:
          "Usa el siguiente cuadro de texto para ponerte en contacto con la empresa y contarle tu interés en la vacante.",
        by_Pressing_Submit_You_Consent: `Al hacer clic en "Enviar", indicas que has leído y aceptado los `,
        please_Wait: "Espera, por favor...",
        jobs_you_Were_Interested_in_Saving:
          "Empleos que te interesaste en guardar",
        saved_Trainings: "Entrenamientos guardados",
        you_have_Already_Applied: "Ya has aplicado a estos empleos",
        Your_Professional_Careers: "Tus planes de carrera de ciberseguridad",
      },

      settings: {
        update_Email_button: "Actualizar el correo electrónico",
        cancel_button: "Cancelar",
        change_Email_button: "Cambiar correo electrónico",
        update_Password_button: "Actualizar Contraseña",
        reset_Password_button: "Restablecer Contraseña",
        eliminate_Account_button: "Eliminar cuenta",
        update_privacy: "Uso de datos personales",
        privacy_Account_button: "Actualizar Privacidad",
        placeholder_Email_Adress: "Correo electrónico",
        placeholder_Confirm_Email_Address: "Confirma el correo electrónico",
        email_Address: "Correo electrónico",
        confirm_Email_Address: "Confirma el nuevo correo electrónico",
        new_Email_Address: "Ingrese una nueva dirección de correo electrónico",
        confirm_Password: "Confirma la Contraseña",
        please_wait: "Espera, por favor...",
        password: "Contraseña",
        current_Password: "Contraseña actual",
        new_Password: "Contraseña nueva",
        confirm_New_Password: "Confirma la nueva contraseña",
        confirm_Account_Elimination: "Confirmar la eliminación de la cuenta ",
        Share_information_companies:
          "Permitir que Seccuri, Inc. comparta tu información personal con empresas que buscan contratar candidatos como tú o empresas con las que actualmente estás relacionado en Seccuri.",
        Data_processing:
          "Permitir que Seccuri, Inc. lleve a cabo el tratamiento de datos personales estrictamente para los fines descritos en su Declaración de Privacidad.",
        enter_Email_Address: "Ingresa la dirección de correo electrónico",
        sign_In_Method: "Configuración de inicio de sesión",
        password_Must_Be_At_Least:
          "La contraseña debe tener al menos 8 caracteres y contener símbolos",
        you_Are_Deleting_Your_Account: "¿Deseas eliminar tu cuenta?",
        by_Deleting_Your_Account:
          "Al eliminar tu cuenta, se perderán todos los datos y contenidos asociados a tu perfil.",
        for_Extra_Security:
          "Para mayor seguridad, confirma tu correo electrónico y contraseña.",
        company_Can_See_Your_Profile:
          "La empresa puede ver la información de tu perfil y el avance de tu carrera profesional.",
        information: "Información",
        relate_Your_Company: "Empresa relacionada con su cuenta Seccuri",
        jobs_with_Talents: "Puestos de trabajo actuales con candidatos",
        active_User: "Usuario Activo",
        relationship_Status: "Estado de Relación",
        ProfileEmployee_Status: "Perfil del empleado",
        unlink_Account_Button: "Desvincular Empresa",
        no_relate_Company:
          "Actualmente no tienes una empresa vinculada a tu cuenta de Seccuri",
        Managing_Consent: "Consentimiento para el uso de datos personales",
        Managing_Consent_text1:
          "En Seccuri, Inc. creemos que tus datos personales son cruciales para nuestra misión, por lo que nos aseguramos de que tu información esté bien protegida y se maneje adecuadamente.",
        Managing_Consent_text2:
          "Al registrarte en la Plataforma Seccuri, otorgaste consentimiento a los",
        Managing_Consent_text3:
          "No obstante, tienes el derecho a retirar en cualquier momento este consentimiento para el uso de datos personales (facilitados voluntariamente a través de la plataforma).",
        Managing_Consent_text4:
          "Si no estás de acuerdo, puedes optar por dejar de utilizar los servicios de Seccuri, eliminando tu cuenta en la siguiente sección.",
        have_read: "He leído y acepto la",
        in_wish: "en los que estuviste de acuerdo con:",
        communication_preferences: "Preferencias de comunicación",
        privacity_preferences: "Preferencias de privacidad",
        marketing_communications: "Comunicaciones de marketing",
        marketing_emails:
          "Acepto recibir ocasionalmente correos electrónicos de marketing de Seccuri, Inc.",
        update_marketing_button: "Actualizar preferencias",
        Top_Talent_Visibility: "Visibilidad Top Talent",
        Top_talent_description:
          "Autorizo que mi perfil aparezca como Top Talent para empresas interesadas en encontrar talento en Seccuri.",
      },

      details: {
        talent_Details: "Detalles del Talent",
        Teacher_details: "Detalles del docente",
        contact: "Contacto",
        basic_Information: "Información básica",
        nist_Nice_Roles: "Roles ciberseguridad",
        career_path: "Planes de Carrera",
        education: "Educación",
        experience: "Experiencia",
        certifications: "Certificaciones",
        courses: "Cursos",
        current_Entity: "Entidad actual",
        current_Position: "Empleo actual",
        current_Location: "Ubicación Actual",
        nationality: "Nacionalidad",
        seeking_Opportunities: "Buscando Oportunidades",
        yes: "Si",
        no: "No",
        open_Travel: "Disponibilidad Para viajar",
        desired_Employment_Type: "Tipo de empleo Deseado",
        highest_Level_Education: "Nivel más alto de Educación",
        job_Title: "Título del empleo",
        company: "Empresa",
        not_Specified: "No especificado",
        industry: "Industria",
        start_Date: "Fecha de Inicio",
        end_Date: "Fecha de finalización",
        currenty_Work_Here: "Trabajo aquí actualmente",
        certification_Name: "Nombre de la Certificación",
        issuing_Entity: "Entidad Emisora",
        date_Issued: "Fecha de Emisión",
        this_certification_Expires: "Esta ceriticación vence",
        this_Certification_Not_Expires: "Esta certificación no vence",
        degree: "Título Obtenido",
        school: "Institución Educativa",
        field_Study: "Campo de Estudio",
        graduation_Date: "Fecha de Graduación",
        not_Graduated: "No Graduado",
        activities: "Actividades",
        name: "Nombre",
        number: "Número",
        associated_With: "Asociado con",
      },
    },
    companyView: {
      index: {
        Create_administrator_user: "Crear usuario administrador",
        Contacted_by_Company: "Contactado por la empresa",
        Talent_Applied_to_Job: "Talent postulado a la vacante",
        Conversation_in_Progress: "Conversación en curso",
        Hired: "Contratado",
        Process_Closed: "Proceso cerrado",
        active: "Activo",
        Not_Acepted_jet: "Pendiente por aceptar",
        Public_Profile: "Perfil público",
        Private_Profile: "Perfil privado",
        monthly_button: "Mensual",
        annual_button: "Anual",
        start_button: "Iniciar",
        contact_us_button: "Contáctanos",
        subscribe_button: "Suscribirse",
        edit_Profile_button: "Editar perfil",
        Industria: "Industria",
        back_button: "Atrás",
        submit_button: "Enviar",
        Create: "Crear",
        try_Again_button: "¡Intentar de nuevo!",
        ok_Got_It_button: "¡Hecho!",
        placeholder_Search: "Buscar",
        company_School_Name: "Empresa",
        security_clearance_level: "Nivel de seguridad Habilitado",
        security_clearance_lastGrantedDate:
          "Última habilitación de seguridad concedida",
        contact_Number: "Número de contacto",
        current_Position: "Cargo actual",
        country: "País",
        seeking_Opportunities: "Buscando oportunidades",
        yes: "SI",
        no: "NO",
        company_Name: "Nombre de la empresa",
        location: "Ubicación",
        ceo: "CEO",
        company_Website: "Página web de la empresa",
        jobs: "Empleos",
        employees: "Usuarios",
        cyberTeams: "Equipo Ciberseguridad",
        contacted_Candidates: "Candidatos Contactados",
        profile_Completion: "Completar Perfil",
        full_Profile: "Perfil completo",
        overview: "Información general",
        account: "Cuenta",
        settings: "Configuraciones",
        Account_Privacy: "Privacidad de la cuenta",
        subscription: "Cambiar suscripción",
        profile_Overview: "Información general del perfil",
        completed_Sections: "Secciones completas: ",
        company_Information: "Información de la empresa",
        company_industry: "Industria",
        Security_Clearance_required: "Se requiere habilitación de seguridad",
        basic_data: "Datos básicos",
        soft_skill: "Habilidades blandas",
        added_Employees: "usuarios agregados",
        created_Positions: " Posiciones creadas",
        edit_Company_Profile: "Editar perfil de empresa",
        basic_Information: "Información básica",
        company_Details: "Detalles de la empresa",
        specified_Organization: "Organización Especificada",
        contact_Information: "Información de contacto",
        we_Need_Your_Attention: "¡Necesitamos tu atención!",
        choose_Your_Plan: "Selecciona tu Plan",
        company_Profile_Details: "Detalles del perfil de la empresa",
        please_Make_Sure_To_Complete_Your_Talent:
          "Para mejorar tu experiencia en Seccuri y facilitar que las empresas te encuentren, te recomendamos completar tu perfil.",
        you_can_upload:
          "Puedes cargar el PDF de LinkedIn con tu CV y ​​nuestro Seccuri AI te ayudará a completar la información.",
        if_You_Need_More_Info_About_Our_Pricing:
          "Si necesita más información sobre nuestros precios, envíenos un correo electrónico a ",
        or_send_us_a_message: " o un mensaje",
        Upgrade_a_Plan: "Actualizar un plan",
        Here: "aquí",
        fill_The_Basic_Data: "Complete los datos básicos",
        fill_the_Contact_Information: "Complete la información de contacto",
        clear_filter_button: "Limpiar filtros",
        Filters: "Filtros",
        items_Found: "items encontrados",
        by_Recently_Added: "por Agregados Recientes ↓",
        SelectLocation: "Selecciona una ubicación",
        SelectSalaryRange: "Selecciona un rango salarial",
        SelectSEducationLevel: "Selecciona nivel de educación",
        training_Pie_De_Pagina: "Mostrando",
        training_Pie_De_Pagina2: "a",
        training_Pie_De_Pagina3: "de",
        training_Pie_De_Pagina4: "entradas",
        recently_Updated: "Agregado Recientemente",
        last_Month: "El mes pasado",
        last_Quarter: "El trimestre pasado",
        last_Year: "El año pasado",
        please_Check_Fields_msj: "Por favor revisa los campos rojos.",
        have_SomeErrors: "Estamos presentando algunos errores.",
        company_Information_Been_Saved:
          "¡La información de tu empresa ha sido guardada!",
        Upgrade_Subscription: "Actualizar suscripción",
        bill_to: "Correo electrónico de facturación",
        customer_Name: "Nombre del cliente",
        currency: "Moneda",
        subscribed_Product: "Plan de suscripción",
        billing_Method: "Método de facturación",
        Annually: "Anualmente",
        Monthly: "Mensualmente",
        USDDollar: "USD - Dólar americano",
        phone: "Teléfono",
        talents: "Talent",
        billing_Address: "Información de facturación: ",
        reset_Button: "Limpiar",
        apply_Button: "Aplicar",
        appliedJobs: "Empleos aplicados",
        savedTrainings: "Entrenamientos guardados",
        talent_List: "Talent",
        for_Applied_Position_as: "Talent para la posición de",
        change_status: "Cambiar estado",
        Back_to_Applied: "Volver a empleos con candidatos",
      },

      ajustes: {
        update_Password_button: "Actualizar contraseña",
        reset_Password_button: "Restablecer la contraseña",
        email_Address: "Correo electrónico",
        confirm_Password: "Confirma tu contraseña",
        update_Email: "Actualizar correo electrónico",
        cancel: "Cancelar",
        change_Email: "Cambiar el correo electrónico",
        password: "Contraseña",
        current_Password: "Contraseña actual",
        new_Password: "Nueva contraseña",
        confirm_New_Password: "Confirma la nueva contraseña",
        eliminate_Account: "Eliminar cuenta",
        confirm_Account_Elimination: "Confirmar eliminación de la cuenta",
        enter_Email_Address: "Ingrese el correo electrónico",
        sign_In_Method: "Configuración de inicio de sesión",
        enter_New_Email_Address: "Ingresa un nuevo correo electrónico",
        confirm_New_Email_Address: "Confirma el nuevo correo electrónico",
        please_Wait: "Espera, por favor...",
        Password_Must_Be_At_Least_8_Character:
          "La contraseña debe tener al menos 8 caracteres y contener caracteres especiales",
        deseas_Eliminar_Tu_Cuenta: "¿Deseas eliminar tu cuenta?",
        by_Deleting_Your_Account:
          "Al eliminar la cuenta, todos los datos y contenidos asociados al perfil de la empresa se perderán. Para mayor seguridad, por favor confirma la eliminación y proporciona el correo electrónico y la contraseña del usuario administrador.",
        for_Extra_Security_This_Requires:
          "Para mayor seguridad, esto requiere que se confirme el correo electrónico y contraseña del administrador.",
      },

      employees: {
        add_Admin_button: "Añadir usuario administrador",
        add_Talent_button: "Agregar Company Talent",
        cyberteam: "Equipo de Ciberseguridad en Seccuri",
        delete_button: "Eliminar",
        submit_button: "Enviar",
        current_Employees: "Colaboradores Activos",
        current_Admin_Employees:
          "Actuales usuarios administradores de la empresa",
        instructions: "Instrucciones: ",
        In_this_section:
          "En esta sección podrás revisar tu perfil según la información proporcionada por quien te nominó y hacer los cambios necesarios.",
        first_Name: "Nombre",
        last_Name: "Apellido",
        job_Title: "Título profesional",
        password: "Contraseña",
        email: "Correo electrónico",
        confirm_Password: "Confirmar contraseña",
        please_wait: "Espera, por favor...",
        add_Company_Admin: "Agregar usuarios administradores de empresa",
        add_Company:
          "Agregar Company Talent al Equipo de Ciberseguridad en Seccuri",
        subscription_details: "Detalles del plan de suscripción",
        add_A_Company_User: "Agregar un usuario a la empresa",
        send_Invitation_Button: "Nominar docente",
        send_Invitation_ButtonEmployee: "Enviar invitación",
        add_A_Company_Admin: "Agregar un usuario administrador   ",
        add_An_Admin_User: "Agregar un Usuario Administrador",
        company_Admin_Users: "Usuario administrador de la empresa: ",
        company_Admin_User_You_Will:
          "Un usuario administrador de la empresa es cualquier colaborador interno que desees invitar a la plataforma Seccuri AI para que apoye la realización de tareas específicas. Una vez añadidos, los usuarios adminitradores podrán editar el perfil de la empresa, añadir colaboradores del equipo interno de ciberseguridad, crear ofertas de empleo y buscar nuevos candidatos Talent para estas vacantes.",
        Once_added_Company_Managers:
          "Una vez añadidos, los usuarios adminitradores podrán editar el perfil de la empresa, añadir colaboradores del equipo interno de ciberseguridad, crear ofertas de empleo y buscar nuevos candidatos Talent para estas vacantes.",
        company_Users: "Company Talent:",
        allow_Other_Members_Of_Your_Company:
          " Haz seguimiento al desarrollo profesional en ciberseguridad de tus colaboradores actuales invitándolos a registrarse en Seccuri AI como usuarios Talent.",
        ask_Other_Members_Of_Your_Company:
          "Una vez inscritos, podrán vincularse al perfil de la empresa y formar parte de tu equipo de ciberseguridad en Seccuri.",
        as_A_Company_Admin_User:
          "Como administrador principal de la empresa, eres responsable de crear todas las cuentas y contraseñas de los usuarios administradores de la empresa.",
        Make_sure_to:
          " Asegúrate de compartir internamente la contraseña designada con el usuario administrador de la empresa correspondiente.",
        All_fields_are_mandatory: "Todos los campos son obligatorios",
        your_Company_Wants:
          "Enviaremos una invitación para agregar el usuario con el correo electrónico asignado para formar parte del equipo de ciberseguridad. Todos los campos son obligatorios.",
        are_you_sure:
          "¿Estás seguro de que deseas eliminar a este Talent del equipo de ciberseguridad de la empresa? Ya no tendrás acceso como empresa a la cuenta del usuario.",
        delete_employee: "Eliminar Talent del Equipo de Ciberseguridad",
        delete: "Eliminar",
        user: "Usuario",
        location: "Ubicación",
        current_Job_Title: "TÍTULO DEL CARGO ACTUAL",
        join_Status: "ESTADO DE LA INVITACIÓN",
        talent_profile: "Perfil de usuario",
        actions: "Acciones",
        pending: "Pendiente",
        join_Talent: "Empleado activo",
        contact_Talent_Button: "Contactar Talent",
        delete_Talent_Button: "Eliminar el talent",
        export_Info_to_PDF_Button: "Exportar información a PDF",
        public: "Público",
        private: "Privado",
      },

      jobs: {
        Date_by_which_applications:
          "Fecha hasta la cual se recibirán aplicaciones de candidatos.",
        create_new_pos_button: "Crear nueva vacante",
        save_As_button: "Guardar cambios ",
        change_Status_button: "Cambiar estado",
        edit_Job_button: "Editar posición",
        show_Profile_button: "Mostrar perfil",
        delete_button: "Eliminar",
        delete_Job: "Eliminar empleo",
        back_button: "Atrás",
        jobs: "Empleos",
        Post_job: "Publicar un empleo",
        Job_Board: "Bolsa de trabajo",
        position_Details: "Detalles de la vacante",
        nist_Nice_Roles: "Roles cibersecuridad",
        certifications: "Certificaciones",
        languages: "Idiomas",
        job_Title: "Título del empleo",
        company_Name: "Nombre de la empresa",
        privacy: "Definir la privacidad del empleo",
        job_Department: "Departamento de trabajo",
        job_Description: "Descripción del empleo",
        vacancy_Details: "Detalles de la vacante",
        salary_Range_Min: "Rango mínimo de salario",
        salary_Range_Max: "Rango máximo de salario",
        select_Employment_Type: "Selecciona el tipo de empleo",
        duration: "Duración",
        requirements: "Requerimientos",
        industry: "Industria",
        level_Of_Education: "Nivel de educación",
        location: "Ubicación",
        select_A_Country: "Selecciona un país...",
        All_Countries: "Todos los países",
        select_a_city: "Selecciona una ciudad...",
        nationatily: "Nacionalidad",
        expiration_Date: "Fecha de caducidad",
        salary_Range: "Rango salarial",
        top: "Top ",
        candidates: "Candidatos",
        details: "Detalles",
        contact: "Contacto",
        email: "Correo electrónico",
        langauge: "Idioma",
        english: "Inglés",
        change_Job_Status: "Cambiar el estado del empleo",
        published: "Publicado",
        delete_Job_Position: "Elimar puesto de trabajo",
        only_required_Company: "Sólo en caso de ser requerido por la empresa",
        create_New_Job_Position: "Crear nuevo puesto de trabajo",
        basic_Information: "Información básica",
        are_You_Ready_To_Start_Finding_Talent:
          "¿Estás preparado para empezar a encontrar nuevos Talent para tu Empresa?",
        privateJobsforCompany: "private Jobs for Company",
        jobs_Subtitle2: "Mostrando 1 a 10 entrenamientos de 50 entradas",
        edit_Job_Position: "Editar puesto de trabajo",
        fill_In_Your_Job_Data: "Completa los datos generales del empleo",
        specialities_And_Tasks: "Define sus especialidades y tareas",
        list_Certifications_Required:
          "Menciona la lista de certificaciones requeridas",
        fill_Native_And_Learned_Languages: "Incluye los idiomas requeridos ",
        please_Wait: "Espera, por favor...",
        select_An_Option: "Selecciona una opción...",
        units: "Unidades (días, meses, etc.)",
        open_To_Travel: "Disponibilidad Para viajar",
        public: "Reclutamiento externo",
        private: "Reclutamiento interno",
        include_Job_Functions_And_Responsibilities:
          "(Incluir funciones y responsabilidades laborales)",
        these_Are_The: "Estos son los ",
        candidates_With_More_Match:
          "candidatos que mejor se acomodan a este empleo",
        Cant_find_any_Top_Talent:
          "¿Aún no ha encontrado ningún Top Talent? Intente ajustar la información de tu oferta de empleo para ayudarnos a ampliar la búsqueda y el emparejamiento inteligente de candidatos Top Talent.",
        the_Percentage_Displayed_For_Each_Talent:
          "¡El porcentaje que se muestra para cada Talent indica cuánto coincide el candidato con cada uno de los roles de tu publicación de trabajo!",
        the_Current_Status_For_This_Job_Position:
          "El estado actual de este puesto de trabajo es",
        are_You_Sure_You_Want_To_Change:
          ". ¿Estás seguro de que deseas cambiar el estado de este puesto de trabajo a",
        saved_as_Draft: "Guardado como borrador",
        are_You_Sure_You_Want_To_Delete:
          "¿Estás seguro de que quiere eliminar la vacante del trabajo?",
        use_the_text:
          "Utiliza el siguiente cuadro de texto para ponerte en contacto con este candidato. Envíale un mensaje corto para iniciar el contacto!",
        use_the_text_placeholder: "Su mensaje...",
        contact_to: "Contactar",
        the_talent: "al Talent",
        here_You_Can_Send_Message:
          "Utiliza el siguiente cuadro de texto para enviar un breve mensaje al Company Talent.",
        write_Here_The_Message: "Tu mensaje...",
        view_applied_Talents: "Ver Talent que ha aplicado",
        Deadline: "Fecha de cierre",
      },

      profile: {
        select_Industry_Placeholder: "Selecciona una industria...",
        select_Country_Placeholder: "Selecciona un país...",
        select_year_Placeholder: "Selecciona un año...",
        select_Option_Placeholder: "Selecciona una opción...",
        basic_Information: "Información básica",
        company_Name: "Nombre de la empresa",
        industry: "Industria",
        location: "Ubicación",
        description: "Descripción",
        company_Details: "Detalles de la empresa",
        ceo: "CEO",
        year_Of_Fundation: "Año de fundación",
        number_Of_Employees: "Número de usuarios",
        revenue_Range: "Rango de ingresos",
        contact_Information: "Información de contacto",
        admin_First_Name: "Nombre del administrador",
        admin_Last_Name: "Apellido del administrador",
        admin_Job_Title: "Cargo del administrador",
        phone_Number: "Número de teléfono",
        Connected_Accounts: "Cuentas conectadas",
        Connect_authentication_services:
          "Conecte los servicios de autenticacion que requiera usar para acceder a su cuenta seccuri.",
        Connect: "Conectar",
        Desconnect: "Desconectar",
        phone_Number_Example: "Ejemplo número de teléfono",
        website: "Sitio web",
        secondary_Email: "Correo electrónico secundario",
        country_Code: "Código del País",
        this_Email_Will:
          "Este segundo correo te permitirá recuperar tu correo electrónico inicialmente registrado, en caso de que lo hayas olvidado.",
        upload_Company_Logo: "Cargar el logo de la empresa",
        allowed_File_Types: "Tipos de archivos permitidos: png, jpg, jpeg.",
        select_An_Industry: "Selecciona una Industria...",
        select_A_Country: "Selecciona un País...",
        select_a_city: "Selecciona una ciudad...",
        select_An_Option: "Selecciona una Opción...",
        give_Us_A_Short_Description:
          "Danos una breve descripción de la empresa (declaración de misión, historia, estructura empresarial, etc.)",
      },

      roles: {
        delete_button: "Eliminar",
        save_button: "Guardar",
        placeholder_Search_Tasks: "Buscar Tareas",
        nist_Nice_Roles: "Roles cibersecuridad",
        options: "opciones.",
        next_Step: "Próximo paso",
        confirm_Changes: "Confirmar cambios",
        certifications: "Certificaciones",
        add_New: "Agregar Nuevo",
        Add_new_certification: "Agregar nueva certificación",
        certification: "Certificación",
        name: "Nombre",
        languages: "Idiomas",
        add_New_Language: "Agregar nuevo idioma",
        please_Define_The_Roles_Associated:
          "¡Por favor, define los roles asociados al puesto de trabajo!",
        in_This_Section_Which_Consists:
          "En esta sección, que consta de tres pasos, seleccionarás los roles NIST NICE asociados a la vacante.",
        please_start_By_Selecting_At_Least:
          "Empieza seleccionando al menos un área de especialización que se relaciona con esta vacante",
        by_Clicking_Here: "haciendo click aquí",
        improve_Your_Roles: "¡Mejora los roles de tu vacante! ",
        you_can_Improve_The_Progress_Of_Your_Roles:
          "Puedes mejorar el progreso de los roles de NIST NICE en cualquier momento actualizando las áreas, especialidades y tareas de ciberseguridad del empleo en esta sección.",
        select_Your_Areas: "1. Selecciona tus áreas",
        Edit_Profile_section: "Editar perfil.",
        your_Nist_Nice_Roles: "Sus funciones NIST NICE",
        the_Job_Position_Roles_And_Their_Progress:
          "Roles de NIST NICE asociados a la vacante Los roles del puesto de trabajo y su progreso se basan en las áreas, especialidades y tareas seleccionadas.",
        edit_Your_Roles: "Edita tus roles",
        select_Your_Specialities: "2. Selecciona tus especialidades",
        select_Your_Tasks: "3. Selecciona tus tareas",
        please_Select_At_Least_One_Area: "¡Selecciona al menos un área!",
        before_You_Proceed_With_Step:
          "Antes de continuar con el Paso 2, selecciona al menos un área de experiencia...",
        please_Select_At_Least_One_Speciality:
          "¡Selecciona al menos una especialidad!",
        to_Continue_With_The_Next_Step:
          "Para continuar con el siguiente paso, selecciona al menos uno especialidad para el trabajo.",
        please_Select_At_Least_One_Task: "¡Selecciona al menos una tarea!",
        to_Continue_With_The_Next_Step_Is_Important:
          "Para continuar con el siguiente paso es importante que selecciones al menos una tarea.",
        choose_Your_Areas: "Elige tus áreas",
        select_The_Main_Areas_That_Make:
          "Selecciona las principales áreas que componen este puesto de trabajo. Escoge máximo",
        choose_Your_Specialities: "Elige tus especialidades",
        select_The_Main_Specialities_Required:
          "Para continuar con el siguiente paso, selecciona al menos una especialidad relacionada a este puesto de trabajo. Escoge máximo ",
        choose_Your_Tasks: "Elige tus tareas",
        select_All_Of_The_Tasks_That_Will_Need:
          "Para finalizar esta sección, selecciona todas las tareas que deberán realizarse en este puesto de trabajo.",
        You_can_use_the_Search_bar:
          "Agiliza este paso usando la barra navegadora con palabras claves asociadas a las tareas del puesto de trabajo. ",
        show_Only_Selected: "Mostrar solo seleccionados",
        provide_Information_About_Your_Certifications:
          "Proporciona el nombre de las certificaciones requeridas para el cargo.",
        provide_Information_About_The_Languages:
          "En esta sección proporciona información sobre los idiomas requeridos por la empresa para aplicar a este puesto de trabajo.",
      },

      language: {
        delete_button: "Eliminar",
        save_button: "Guardar",
        language: "Idioma",
        begginer: "Nivel básico",
        intermediate: "Intermedio",
        proficient: "Competente",
        native_Biligual: "Nativo/Bilingüe",
        select_An_Option: "Selecciona una opción...",
        select_A_Level: "Selecciona un Nivel",
        knowledge_Of_Basic_Vocabulary_Words:
          "Conocimiento de palabras de vocabulario básico, habla oraciones simples, habilidades elementales de lectura y escritura.",
        can_Handle_Intermediate_Communication:
          "Puede manejar comunicación intermedia, lectura y escritura.",
        a_Well_Advanced_Use_Of_The_Language:
          "Tiene un uso muy avanzado del idioma, utilizando fluidez en el habla, la lectura y la escritura.",
        more_Than_Fluent_In_The_Language: "Más que fluido en el idioma.",
      },

      searchTalent: {
        find_The_Best_Cybersecurity_Talent:
          "Encuentra el mejor Talent de ciberseguridad que tu empresa necesita",
      },
    },
    loginView: {
      index: {
        continue_button: "Continuar",
        register_button: "Registrar",
        email: "Correo electrónico",
        password: "Contraseña",
        forgot_Password: "¿Has olvidado tu contraseña ?",
        company_Account: "Cuenta para Empresa",
        talent_Account: "Cuenta para Talent",
        first_Name: "Nombre",
        last_Name: "Apellido",
        admin_First_Name: "Nombre del Administrador",
        admin_Last_Name: "Apellido del Administrador",
        admin_Email: "Correo electrónico del Administrador",
        phone: "Teléfono",
        country: "País",
        confirm_Password: "Confirmar Contraseña",
        login_Tittle1: "Iniciar Sesión en Seccuri",
        login_Tittle2: "Elija el tipo de cuenta",
        login_Titlle3: "Crear una Cuenta",
        login_Tittle4: "Crear una cuenta de Empresa",
        login_Subtittle1: "¿Eres nuevo aquí?",
        login_subtittle2: "Crea una Cuenta",
        login_Subtittle3: "Espera, por favor...",
        login_subtittle4: "En búsqueda de empleo",
        login_Subtittle5: "Buscando Oportunidades de Formación",
        login_Subtittle6: "Buscando Contratar Talent",
        login_Subtittle7:
          "Buscando oportunidades de entrenamiento para los usuarios",
        login_Subtittle8: "¿Ya tienes una cuenta?",
        login_Subtittle9: "Ingresa aquí",
        login_Subtittle10: "Selecciona un País...",
        login_subtittle11:
          "Use 12 o más caracteres con una combinación de letras (minúsculas y mayúsculas), números y símbolos.",
        login_Subtittle12: "Acepto los",
        login_Subtittle13: "Términos y Condiciones.",
      },

      forgotPassword: {
        reset_Password_button: "Restablecer Contraseña",
        cancel_button: "Cancelar",
        continue_button: "Continuar",
        forgotPassword: "Olvidaste tu Contraseña ?",
        email: "Correo electrónico",
        please_Wait: "Espera, por favor...",
        reset_Your_Password: "Restrablecer su contraseña",
        token: "Token",
        password: "Contfraseña",
        confirm_Password: "Confirmar Contraseña",
        forgot_Subtitle1:
          "Ingrese su correo electrónico para restablecer su contraseña.",

        forgot_Subtitle2:
          "Use 12 o más caracteres con una combinación de letras, números y símbolos.",
        forgot_Subtitle3:
          "Ingrese y confirme su nueva contraseña con el código temporal recibido por correo electrónico.",
        To_login_to_your_new_seccuri:
          "Para ingresar a su nueva cuenta de Seccuri y aceptar ser parte del equipo de Ciberseguridad de tu empresa, siga los pasos a continuacion y seleccione continuar.",
        You_can_unlink_the_company:
          "Puede desvincular la empresa en cualquier momento de la opcion de companya relacionada. ",
        Accept_award_new_User:
          "Para acceder a su nueva cuenta Seccuri y aceptar la participacion en los Seccuri Awards, siga los pasos que se indican a continuación",
        Accept_award_new_User_not_Accept:
          "Si por algún motivo no desea aceptar la nominacion pero quiere ser parte de la plataforma, puede registrarse desde nuestra pagina pricipal www.Seccuri.com",
      },
    },
    profileView: {
      index: {
        next_Step_butoon: "Siguiente paso",
        birthDate: "Fecha de nacimiento",
        confirm_Changes_button: "Confirmar cambios",
        search_Tasks_Placeholder: "Buscar tareas",
        back_button: "Atrás",
        submit_button: "Enviar",
        save_Continue_button: "Guardar y Continuar",
        continue_button: "Continuar",
        go_Education_button: "Ir a Educación",
        go_Experiences_button: "Ir a las Experiencias",
        go_Certifications_button: "Ir a las Certificaciones",
        go_Courses_button: "Ir a los Cursos",
        go_Languages_button: "Ir a los Idiomas",
        go_References_button: "Ir a las Referencias",
        next_Section_button: "Sguiente Sección",
        edit_Profile: "Editar perfil",
        personal_Details: "Información personal",
        nist_Nice_Roles: "Roles cibersecuridad",
        experience: "Experiencia",
        education: "Eduación",
        certifications: "Certificaciones",
        languages: "Idiomas",
        references: "Referencias",
        courses: "Cursos",
        basic_Information: "Información básica",
        first_Name: "Primer nombre",
        middle_Name: "Segundo nombre",
        last_Name: "Apellido",
        gender: "Género",
        nationality: "Nacionalidad",
        location: "Ubicación",
        contact_Information: "Información de contacto",
        phone_Number: "Número de teléfono",
        website: "Sitio Web",
        secondary_Email: "Segundo correo electrónico",
        this_Email_Will:
          "Este segundo correo te permitirá recuperar tu correo electrónico inicialmente registrado, en caso de que lo hayas olvidado.",
        education_Information: "Información actual",
        school_Name: "Nombre de la institución educativa",
        degree_Title: "Último título obtenido",
        personality: "Personalidad",
        select_personalityType: "Selecciona tu resultado",
        have_Personality_Test: "¿Ya tomaste el test de personalidad?",
        personality_Test_result: "Resultado del test",
        personality_Test_Up: "Hacer test",
        professional_Information: "Nuevas oportunidades",
        please_Take_this_Test:
          "Realiza este test de personalidad e ingresa tus resultados en la parte infrerior.",
        current_Job_Title: "Cargo actual",
        desired_Employment_Type: "Tipo de empleo deseado",
        company_Name: "Nombre de la empresa",
        select_your_Areas: "1. Selecciona tus áreas",
        select_your_Specialities: "2. Selecciona tus especialidades",
        select_your_Tasks: "3. Selecciona tus tareas",
        options: "opciones",
        please_Wait: "Espera, por favor...",
        upload_a_Photo: "Subir una foto",
        profile_Tittle2: "¡Selecciona al menos un área!",
        profile_Subtittle1: "Completa tus datos personales",
        profile_Subtittle2: "Define tus especialidades y tareas",
        profile_Subtittle3: "Completa tu experiencia laboral",
        profile_Subtittle4: "Completa tu información académica",
        profile_Subtittle5: "Indica las certificaciones obtenidas",
        profile_Subtittle6: "Indica los idiomas que manejas",
        profile_Subtittle7: "Indica tus referencias externas",
        profile_Subtittle8: "Indica los cursos obtenidos",
        allowed_File_Types: "Tipos de archivos permitidos: png, jpg, jpeg.",
        gender_Profile: "Selecciona un género",
        select_a_Country: "Selecciona un País...",
        select_a_city: "Selecciona una ciudad...",
        select_a_Department: "Selecciona un departmento...",
        select_a_Region: "Seleccione una región...",
        profile_Subtittle12: "Selecciona tu nivel más alto de educación",
        profile_Subtittle13: "Selecciona una opción",
        profile_Subtittle14: "Disponibilidad para viajar",
        profile_Subtittle15:
          "¿Estás buscando oportunidades laborales actualmente?",
        profile_Subtittle16:
          "Para continuar con el siguiente paso es importante que selecciones al menos un área.",
        profile_Subtittle17: "Define tus áreas de ciberseguridad",
        profile_Subtittle18:
          "Selecciona las principales áreas en las que tienes experiencia. Escoge máximo ",
        profile_subtittle19: "opciones.",
        profile_Subtitle20: "¡Selecciona al menos una especialidad!",
        profile_Subtitle21:
          "Para continuar con el siguiente paso es importante que selecciones al menos una especialidad.",
        profile_Subtitle22: "¡Selecciona al menos una tarea!",
        profile_Subtitle23:
          "Para continuar con el siguiente paso es importante que selecciones al menos una tarea.",
        profile_Subtitle24: "Define tus especialidades de ciberseguridad",
        profile_Subtitle25:
          "Selecciona las principales especialidades en las que tienes experiencia. Escoge máximo ",
        profile_Subtitle26: "Define tus tareas de ciberseguridad",
        profile_Subtitleskill: "Define tus habilidades de ciberseguridad",
        profile_Subtitle26knowledge:
          "Define tus conocimientos de ciberseguridad",
        profile_Subtitle27:
          "Selecciona todas las tareas de ciberseguridad que has realizado hasta el momento.",
        profile_Subtitle28: "Mostrar solo los seleccionados",
        new_in_cybersecurity: "Soy nuevo en ciberseguridad",
        hi_new_talent_cybersecurity: "Hola, ¡entusiasta en ciberseguridad!",
        hi_talent_cybersecurity:
          "Bienvenido de nuevo, ¡Talent de ciberseguridad!",
        experience_in_cybersecurity: "Tengo experiencia en ciberseguridad",
        in_trainings:
          "Te invitamos a navegar por nuestra sección de Entrenamiento, donde podrás sumergirte en diferentes temas a través de cursos y certificaciones, que te permitirán empezar tu carrera en ciberseguridad.",
        at_the_moment:
          "Una vez hayas obtenido alguna experiencia en ciberseguridad, ingresa a la opción",
        para_que:
          "para que podamos evaluar tus habilidades actuales según el marco de trabajo NIST NICE. Con ello podremos ir rastreando cada vez tu progreso y asegurarnos de que tu perfil esté más completo a la hora de aplicar a nuevas oportunidades de empleo (en caso deseado).",
        select_begginer:
          "Al seleccionar esta opción, te compartiremos toda la información sobre los cursos y entrenamientos que podrás tomar para iniciar tu carrera profesional en ciberseguridad.",
        nist_nice: "¿Qué es el marco de trabajo NIST NICE?",
        here: "aquí.",
        start_your_career:
          "¿Te sientes preparado para evaluar tu perfil actual de Ciberseguridad?",
        confirm: "Confirmar Selección",
        nist_nice_framework:
          "En Seccuri usamos diferentes mecanismos para evaluar tu carrera profesional en ciberseguridad; uno de ellos es a través del marco de trabajo NIST NICE. Si quieres conocer más sobre este método ingresa",
        your_nist_nice:
          "Para conocer y modificar tus roles de NIST NICE selecciona la opción",
        Seccuri_brings:
          "Seccuri te trae los Seccuri Awards, un emocionante concurso en el que buscamos celebrar a los mejores docentes de ciberseguridad del mundo. ¡Participa ahora nominando a tu docente de ciberseguridad favorito y empieza a votar!",
        The_teacher:
          "¡El docente que más votos tenga al final de la competencia será nombrado el 'Seccuri Star Professor' para Colombia 2024 y obtendrá increíbles premios de nuestras empresas aliadas!",
        The_teacher_chile:
          "¡El docente que más votos tenga al final de la competencia será nombrado el 'Seccuri Star Professor' para Chile 2025 y obtendrá increíbles premios de nuestras empresas aliadas!",
        Criteriatoparticipate:
          "Criterios para participar como docente nominado:",
        Be_teacher_affiliated: "Ser docente vinculado a una entidad educativa.",
        Teach_classes_in_Chile: "Impartir clases en Chile.",
        Have_at_least:
          "Tener al menos 1 año de experiencia enseñando ciberseguridad.",
        Teach_a_minimum: "Enseñar a un mínimo de 20 estudiantes en esta área.",
        Provide_proof: "Acreditar experiencia en ciberseguridad.",
        Thank_you_for_your_interest:
          "¡Gracias por tu interés en los Seccuri Awards! Tanto las nominaciones como las votaciones ya están cerradas.",
        Both_nominations:
          "Permanece atento a nuestras redes sociales para saber quién es el ganador del título 'Seccuri Star Professor' e increíbles premios de nuestras empresas patrocinadoras.",
        Mexico: "Seccuri Awards: México",
        Colombia: "Seccuri Awards: Colombia",
        Chile: "Seccuri Awards: Chile",
        Search_for_teacher: "Buscar docente por nombre",
        Nominate_Professor: "Nominar docente",
        Courses_taught_in_cybersecurity:
          "Cursos dictados en ciberseguridad y actividades académicas",
        Cant_find_your_cybersecurity:
          " ¿No encuentras a tu docente de ciber en nuestra lista? No te preocupes, puedes nominarlo y votar por él una vez hayamos  evaluado su perfil.",
        Share_Profile: "Compartir Perfil",
        View_Profile: "Ver perfil",
        you_have_voted: "¡Gracias por tu voto!",
        vote: "Votar",
        Knowledge_Area: "Área de conocimiento",
        Educational_Institution: "Institución educativa",
        Nominate_your_teacher: "Nomina tu docente a los Seccuri Awards",
        Please_fill_out:
          "Por favor llena el formulario con la información solicitada para nominar a tu docente de ciberseguridad favorito y enviarle una invitación a los premios. ",
        self_nominating:
          "En caso de que te estés autonominando, por favor ingresa los campos para ver automáticamente tu tarjeta de concursante, votar y compartir con tus colegas y estudiantes de ciber!",
        All_fields_marked: " Todos los campos con asterisco ",
        are_mandatory: " son obligatorios",
        Describes_the_courses: "",
        comparte_tu_nominacion: "Comparte tu nominación",
        Copia_pega:
          "Comparte el siguiente texto en tus redes sociales para anunciar tu nominación y permitir que tus estudiantes y colegas voten por ti.",
        Orgulloso:
          "Me complace anunciar que he sido nominado a los Seccuri Awards, un prestigioso evento organizado por Seccuri, la plataforma global de habilidades y talento de ciberseguridad, junto con importantes empresas internacionales. Este evento tiene como objetivo reconocer a los mejores docentes de ciberseguridad en el mundo.",
        ComoVotar_step1: "1. Regístrate en Seccuri como usuario 'Talent' en ",
        ComoVotar_step2:
          "2. Activa tu cuenta e inicia sesión en la plataforma.",
        ComoVotar_step3:
          "3. Accede al módulo Seccuri Awards - Chile Awards y vota por mi perfil.",
        Te_invito:
          "Te invito a apoyarme votando por mí para ganar el título de “Seccuri Star Professor - Chile 2025”. Para hacerlo, simplemente sigue estos pasos:",
        tu_apoyo_significa:
          "Tu apoyo significa mucho para mí. ¡Cuento contigo!",
        Copy_text: "Copiar texto",
        Share_on_LinkedIn: "Compartir en LinkedIn",
        Ciberseguridad:
          "#Ciberseguridad #Tecnología #TI #Reconocimiento #Educación #Chile #Docentes",
        premiosseccuri: "Seccuri",
        eventoliderado: ", un evento liderado por",
        seccuri: "Seccuri",
        plataforma_global:
          ", la plataforma global de talent y habilidades en ciberseguridad, e importantes empresas globales para reconocer a los mejores docentes de ciberseguridad en el mundo",
        apoyame: "Apoyáme votando por mí para ganar el título de .",
        seccuriawards: "“Seccuri Star Professor - México 2023”",
        solo_debes:
          "Sólo debes registrarte como Talent en el enlace que incluyo abajo, activar tu cuenta e iniciar sesión en la plataforma, y en la sección de Seccuri Awards, votar por mi perfil.",
        Apoyame_votando: "Apóyame votando por mí para ganar el título de ",
        seccuri_star: "“Seccuri Star Professor - Colombia 2024”.",
        solo_debes_registrarte:
          "Todo lo que tienes que hacer es registrarte como Talent en el enlace a continuación, iniciar sesión en la plataforma Seccuri y acceder a la sección Seccuri Awards para votar por mi perfil. ",
        hatstag:
          "#Ciberseguridad #Tecnología #TI #Reconocimiento #Educación #Colombia #Ciber",
        link_seccuri: "Link a Seccuri: ",
        cuento_con_tu_voto: "Cuento con tu voto!",
        seccuri_career_path: "Planes de Carrera de ciberseguridad",
        Develop_and_strengthen:
          "Desarrolla y fortalece los equipos de ciberseguridad de tu empresa con una estrategia de desarrollo profesional orientada a la retención de talent y la protección mejorada.",
        Seccuri_Career_Paths: "Planes de carrera Seccuri: ",
        These_are:
          "Estos son los planes de carrera exclusivamente recomendados por Seccuri, basados en análisis de mercado.",
        public_Career_Paths: "Planes de carrera públicos: ",
        explore_career:
          "Explora planes de carrera creados y compartidos abiertamente por otras empresas utilizando la plataforma Seccuri, sirviendo como referencia para el desarrollo de tus equipos de ciberseguridad.",
        Explore_relevant_cybersecurity:
          "Primero, explora los diferentes planes de carrera en ciberseguridad, cada uno equipado con los entrenamientos, certificaciones y conocimientos necesarios para avanzar en todos los niveles de la carrera.",
        Feel_free_to_save_and_duplicate:
          "Puedes duplicar los planes de carrera para personalizarlos según las necesidades de entrenamiento de tu empresa. Éstos se almacenarán automáticamente en el módulo Planes de Carrera de la Empresa para tu fácil acceso y uso. Luego, asigna los planes de carrera a miembros de tu Equipo de Ciberseguridad para gestionar y monitorear su crecimiento profesional.",
        seccuri_career_Path_this_module:
          "Guarda y duplica los planes para personalizarlos según las necesidades de entrenamiento. Los planes creados se almacenarán automáticamente en el módulo 'Planes de Carrera de la Empresa' para un acceso fácil.",
        seccuri_career_Path_each_career:
          "Cada plan de carrera proporciona el progreso detallado, los conocimientos y los entrenamientos que necesitas para seguir mejorando tu nivel. Tus niveles actuales se actualizarán cada vez que finalices una Formación o complementes la sección NIST NICE de tu perfil.",
        seccuri_career_Path_being_updated:
          "Este módulo se actualiza constantemente con más planes de carrera, entrenamientos y funcionalidades. ",
        have_Security_Clearance: "Tengo autorización de seguridad",
        security_Clearance_required: "Autorización de seguridad requerida",
        polygraph_Performed: "Poligrafía realizada",
        Make_Career_Path_Public: "Hacer público el plan de carrera",
        Make_Cyberteam_Public: "Hacer público el empleado",
        security_level: "Nivel de seguridad",
        career_Progress: "Progreso profesional: ",
        view_Button: "Vista",
        customize: "Personalizar",
        description: "Descripción",
        current_Career_Path_Progress:
          "El progreso actual de tu carrera profesional",
        entry_Level: "Nivel básico",
        mid_Career: "Carrera intermedia",
        Professional: "Profesional",
        for: "Para",
        career_Path_Current_Level:
          " como carrera profesional, tu nivel actual es ",
        progress_Bar_Above_Serves_Tool:
          "La barra de progreso anterior sirve como una herramienta para ayudarlo a realizar un seguimiento de sus avances en el plan de carrera de ciberseguridad  seleccionada. El cálculo inicial proviene del análisis NIST NICE realizado en su sección de Perfil, basado en sus KSA actuales (Conocimiento, Habilidades, Capacidades) y Tareas realizadas.",
        if_You_Have_Experience_Cybersecurity:
          "Si tiene experiencia en ciberseguridad y aún no ha completado el análisis NIST NICE, vuelva a la sección Editar perfil. En caso de que sea nuevo en Cyber (entusiasta de la ciberseguridad), ¡siéntase libre de comenzar algunas de las capacitaciones que se enumeran a continuación!",
        continue_Growing_Every_Time_Complete_Training:
          "Esta barra seguirá creciendo cada vez que complete una entrenamiento que lo certifique en las nuevas habilidades requeridas para avanzar en la carrera profesional seleccionada.",
        browse_Suggested_Trainings_Below:
          "Explore las capacitaciones sugeridas a continuación para comenzar a mejorar tus habilidades y convertirte en uno de los mejores expertos en ciberseguridad en tu campo.",
        trainings_For_This_Career: "Entrenamientos para esta carrera",
        trainings_For_begginer: "Entrenamientos para principiantes",
        back_All_Career_Paths: "Volver a  planes de carrera",
        view_All_Trainings: "Ver todos los entrenamientos",
        career_Path: "Planes de Carrera de ciberseguridad",
        overview: "Descripción General",
        account: "Cuenta",
        settings: "Ajustes",
        hey_talent:
          "¡Hola, Talent! ¿Cuál es tu nivel de conocimiento en temas de ciberseguridad?",
        back_how_to_start: "Atrás",
        jobs_For_This_Career: "Trabajos para principiantes",
        view_All_jobs: "Ver todos los empleos",
        view_All_blogs: "Ver todos los blogs",
        blogs_For_This_Career: "Información básica",
      },
      personalities: {
        Inspector:
          "A primera vista, los ISTJ resultan intimidantes. Parecen serios, formales y correctos. También aman las tradiciones y los valores de la vieja escuela que defienden la paciencia, el trabajo duro, el honor y la responsabilidad social y cultural. Son reservados, tranquilos, silenciosos y rectos. Estos rasgos son el resultado de la combinación de I, S, T y J, un tipo de personalidad que a menudo se malinterpreta.",
        Counselor:
          "Los INFJ son visionarios e idealistas que rezuman imaginación creativa e ideas brillantes. Tienen una forma diferente, y normalmente más profunda, de ver el mundo. Tienen sustancia y profundidad en su forma de pensar, nunca se toman las cosas a la ligera ni aceptan las cosas tal y como son. A veces, los demás pueden percibirlos como raros o divertidos por su forma diferente de ver la vida.",
        Mastermind:
          "Los INTJ, como introvertidos, son tranquilos, reservados y se sienten cómodos estando solos. Suelen ser autosuficientes y prefieren trabajar solos que en grupo. Socializar agota la energía de una persona introvertida, por lo que necesita recargarse. A los INTJ les interesan las ideas y las teorías. Cuando observan el mundo, siempre se preguntan por qué las cosas suceden como suceden. Destacan en el desarrollo de planes y estrategias, y no les gusta la incertidumbre.",
        Giver:
          "Los ENFJ son personas centradas en las personas. Son extrovertidos, idealistas, carismáticos, francos, de principios y ética, y por lo general saben cómo conectarse con los demás sin importar su origen o personalidad. Basándose principalmente en la intuición y los sentimientos, tienden a vivir en su imaginación en lugar del mundo real. En vez de centrarse en vivir en el 'ahora' y lo que está sucediendo actualmente, los ENFJ tienden a concentrarse en lo abstracto y lo que posiblemente podría suceder en el futuro.",
        Craftsman:
          "Los ISTP son personas misteriosas que suelen ser muy racionales y lógicas, pero también bastante espontáneas y entusiastas. Sus rasgos de personalidad son menos reconocibles que los de otros tipos, e incluso las personas que los conocen bien no siempre pueden anticipar sus reacciones. En el fondo, los ISTP son individuos espontáneos e impredecibles, pero ocultan esos rasgos del mundo exterior, a menudo con mucho éxito.",
        Provider:
          " Los ESFJ son los extrovertidos estereotipados. Son mariposas sociales, y su necesidad de interactuar con los demás y hacer felices a las personas generalmente termina haciéndolos populares. El ESFJ generalmente tiende a ser el animador o el héroe deportivo en el colegio y la universidad. Más adelante en la vida, continúan deleitándose siendo el centro de atención y se enfocan principalmente en organizar eventos sociales para sus familias, amigos y comunidades. ESFJ es un tipo de personalidad común y que gusta a muchas personas.",
        Idealist:
          "Los INFP, como la mayoría de los introvertidos, son callados y reservados. Prefieren no hablar de sí mismos, especialmente en el primer encuentro con una persona nueva. Les gusta pasar tiempo a solas en lugares tranquilos donde pueden entender lo que sucede a su alrededor. Les encanta analizar signos y símbolos, y los consideran metáforas que tienen significados más profundos relacionados con la vida. Están perdidos en su imaginación y ensoñaciones, siempre sumergidos en la profundidad de sus pensamientos, fantasías e ideas.",
        Performer:
          "Los ESFP tienen una personalidad extrovertida, observadora, sentimental y perceptiva, y comúnmente se les considera animadores. Nacidos para estar frente a los demás y capturar el escenario, a los ESFP les encanta ser el centro de atención. Los ESFP son exploradores reflexivos a los que les encanta aprender y compartir lo que aprenden con los demás. Los ESFP son 'personas sociable' con fuertes habilidades interpersonales. Son animados y divertidos, y disfrutan ser el centro de atención. Son cálidos, generosos y amigables, comprensivos y preocupados por el bienestar de otras personas.",
        Champion:
          "Los ENFP tienen una personalidad extrovertida, intuitiva, emocional y perceptiva. Este tipo de personalidad es muy individualista y se esfuerza por crear sus propios métodos, apariencias, acciones, hábitos e ideas; no les gustan las personas prefabricadas y odian cuando se ven obligados a vivir “dentro de una caja”. Les gusta estar cerca de otras personas y tienen una fuerte naturaleza intuitiva cuando se trata de ellos mismos y de los demás. Operan a partir de sus sentimientos la mayor parte del tiempo, y son muy perceptivos y reflexivos.",
        Doer: "Los ESTP tienen una personalidad extravertida, sensorial, pensante y perceptiva. Los ESTP se rigen por la necesidad de interacción social, sentimientos y emociones, procesos lógicos y razonamiento, junto con la necesidad de libertad. La teoría y los resúmenes no mantienen el interés de los ESTP por mucho tiempo. Los ESTP saltan antes de mirar, solucionan sus errores a medida que avanzan en lugar de permanecer inactivos o preparar planes de contingencia.",
        Supervisor:
          "Los ESTJ son organizados, honestos, dedicados, dignos, tradicionales y son grandes creyentes de hacer lo que creen que es correcto y socialmente aceptable. Aunque los caminos hacia el 'bien' y 'lo correcto' son difíciles, están felices de tomar su lugar como los líderes de la manada. Son el epítome de la buena ciudadanía. La gente busca a los ESTJ en busca de orientación y consejo, y los ESTJ siempre están felices de que se les acerque en busca de ayuda.",
        Commander:
          "El estilo de vida principal de un ENTJ se centra en los aspectos externos y todas las cosas se tratan de manera racional y lógica. Su modo secundario de operación es interno, donde la intuición y el razonamiento tienen efecto. Los ENTJ son líderes natos entre los 16 tipos de personalidad y les gusta estar a cargo. Viven en un mundo de posibilidades y, a menudo, ven los desafíos y obstáculos como grandes oportunidades para esforzarse. Parecen tener un don natural para el liderazgo, la toma de decisiones y la consideración de opciones e ideas de forma rápida pero cuidadosa. Son personas que “se hacen cargo” a las que no les gusta quedarse quietas.",
        Thinker:
          "Los INTP son bien conocidos por sus teorías brillantes y su lógica implacable, lo cual tiene sentido ya que se puede decir que son los más lógicos de todos los tipos de personalidad. Les encantan los patrones, tienen buen ojo para detectar discrepancias y una buena capacidad para leer personas, por lo que es una mala idea mentirle a un INTP. Las personas con este tipo de personalidad no están interesadas en las actividades y el mantenimiento práctico del día a día, pero cuando encuentran un entorno en el que pueden expresar su genio creativo y potencial, no hay límite para el tiempo y la energía que los INTP gastarán en desarrollar una solución perspicaz e imparcial.",
        Nurturer:
          "Los ISFJ son filántropos y siempre están listos para devolver generosidad con aún más generosidad. Las personas y las cosas en las que creen serán defendidas y apoyadas con entusiasmo y generosidad. Los ISFJ son cálidos y de buen corazón. Valoran la armonía y la cooperación, y es probable que sean muy sensibles a los sentimientos de los demás. Las personas valoran el ISFJ por su consideración y conciencia, y su capacidad para sacar lo mejor de los demás.",
        Visionary:
          "Aquellos con la personalidad ENTP son algunos de los más raros del mundo, lo cual es completamente comprensible. Aunque son extrovertidos, no disfrutan de las conversaciones triviales y pueden no prosperar en muchas situaciones sociales, especialmente aquellas que involucran a personas que son demasiado diferentes a ellos. Los ENTP son inteligentes y necesitan constantemente ser estimulados mentalmente. Tienen la capacidad de discutir teorías y hechos con gran detalle. Son lógicos, racionales y objetivos en su enfoque a la información y los argumentos.",
        Composer:
          "Los ISFP son introvertidos que no parecen introvertidos. Es porque incluso si tienen dificultades para conectarse con otras personas al principio, eventualmente se vuelven cálidos, accesibles y amigables. Es divertido estar con ellos y son muy espontáneos, lo que los convierte en los amigos perfectos para acompañarlos en cualquier actividad, sin importar si está planeada o no. Los ISFP quieren vivir su vida al máximo y abrazar el presente, por lo que se aseguran de que siempre estén explorando cosas nuevas y descubriendo nuevas experiencias. Es en la experiencia donde encuentran la sabiduría, por lo que ven más valor en conocer gente nueva que otros introvertidos.",
        default:
          "Aquí aparecerá una breve descripción de tu resultado del test de personalidad, una vez lo hayas realizado.",
        InspectorISTJ: "Inspector - ISTJ",
        CounselorINFJ: "Consejero - INFJ",
        MastermindINTJ: "Mente Directora - INTJ",
        GiverENFJ: "El Dador - ENFJ",
        CraftsmanISTP: "Artesano - ISTP",
        ProviderESFJ: "Proveedor - ESFJ",
        IdealistINFP: "Idealista - INFP",
        PerformerESFP: "Ejecutante - ESFP",
        ChampionENFP: "Campeón - ENFP",
        DoerESTP: "Hacedor - ESTP",
        SupervisorESTJ: "Supervisor - ESTJ",
        CommanderENTJ: "Comandante - ENTJ",
        ThinkerINTP: "Pensador - INTP",
        NurturerISFJ: "Cuidador - ISFJ",
        VisionaryENTP: "Visionario - ENTP",
        ComposerISFP: "Compositor - ISFP",
      },

      workExperince: {
        add_Job_button: "Agregar nueva experiencia laboral",
        delete_button: "Eliminar",
        save_button: "Guardar",
        employment: "Experiencia Laboral",
        employment_History: "Historial de Empleo",
        job_Title: "Nombre del cargo",
        company: "Empresa",
        start_Date: "Fecha de Inicio",
        end_Date: "Fecha de finalización",
        employment_Type: "Tipo de empleo",
        industry: "Industria",
        job_Description: "Descripción del empleo",
        experience_Subtitle1:
          "Cuéntanos tu experiencia profesional, empezando por tu trabajo más reciente.",
        experience_Subtitle2: "¿Actualmente trabajas aquí?",
        experience_Subtitle3: "Selecciona una opción...",
        experience_Subtitle4: "Acerca de la empresa",
        experience_Subtitle5:
          "(Incluye la industria, ubicación, misión de la organización, etc...)",
        experience_Subtitle6:
          "(Incluir funciones y responsabilidades laborales, experiencia laboral, logros, etc...)",
      },

      education: {
        add_New_Education_button: "Agregar nuevo estudio",
        delete_button: "Eliminar",
        save_button: "Guardar",
        select_date_Placeholder: "Selecciona una fecha",
        education_Placeholder2:
          "(Escriba un resumen de los grupos y actividades en los que estuvo involucrado mientras estudiaba).",
        education: "Formación académica",
        education1: "Educación",
        school: "Institución educativa",
        degree: "Título obtenido",
        field_Of_Study: "Campo de estudio",
        graduated: "Graduado",
        graduation_Date: "Fecha de graduación",
        groups_Activities: "Grupos / Actividades",
        education_Subtitle1:
          "Tener una educación variada en tu currículum resume el valor de tus aprendizajes y antecedentes, aportando valor a tu desempeño laboral.",
      },

      certifications: {
        add_New_Certification_button: "Agregar nueva certificación",
        delete_button: "Eliminar",
        save_button: "Guardar",
        select_month_Placeholder: "Selecciona un mes",
        certifications: "Certificaciones",
        certification: "Certificación",
        name: "Nombre",
        issuing_Entity: "Entidad emisora",
        date_Issued: "Fecha de emisión",
        date_Expired: "Fecha de vencimiento",
        certifications_Subtitle1:
          "Esta sección está compuesta por diferentes módulos. Asegúrate de completar cada uno de ellos para permitirle a nuestro algoritmo definir tu perfil actual de ciberseguridad y poder optimizar tu experiencia usando Seccuri.",
        certifications_Subtitle_personality:
          "Esta sección de tu perfil nos dará información sobre el tipo de habilidades blandas que posees según un test de personalidad. Asegúrate de realizar este test e incluir aquí los resultados para que el algoritmo de Seccuri pueda integrarlo a tu perfil actual de ciberseguridad.",
        certification_Subtitle2: "Esta certificación expira",
        Please_provide_following:
          "Por favor, proporciona la siguiente información general sobre la empresa. ",
      },

      courses: {
        add_New_Course_button: "Agregar nuevo curso",
        delete_button: "Eliminar",
        save_button: "Guardar",
        courses: "Cursos",
        course: "Curso",
        name: "Nombre del Curso",
        number: "Número",
        associated_With: "Asociado con",
        courses_Subtitle1:
          "Proporcione información sobre los cursos realizados. En caso de que no haya completado ningún curso, pase a la siguiente sección.",
      },

      languages: {
        add_New_Language_button: "Agregar nuevo idioma",
        delete_button: "Eliminar",
        save_button: "Guardar",
        select_Language_Placeholder: "Selecciona un idioma...",
        languages: "Idiomas",
        language: "Idioma",
        select_level: "Selecciona un nivel",
        begginer: "Nivel básico",
        intermediate: "Intermedio",
        proficient: "Competente",
        native_Biligual: "Nativo/Bilingüe",
        languages_Subtitle1:
          "Proporciona información sobre los idiomas que actualmente manejas.",
        language_Subtitle2: "Selecciona un idioma...",
        language_Subtitle3:
          " Conoces palabras básicas, hablas oraciones simples, y tienes algunas habilidades de lectura y escritura.",
        language_Subtitle4:
          "Manejas una comunicación, lectura y escritura intermedia.",
        language_Subtitle5:
          "Tienes un manejo avanzado del idioma, con fluidez en el habla, la lectura y la escritura.",
        fluent_in_the_Language: "Eres más que fluido en el idioma.",
      },

      references: {
        add_New_button: "Agregar nueva referencia",
        delete_button: "Eliminar",
        save_button: "Guardar",
        references: "Referencias",
        reference: "Referencia",
        email: "Correo electrónico o código de referido",
        references_Subtitle1:
          "Proporciona información sobre tus contactos de referencia.",
      },
    },
    alertsView: {
      alerts: {
        form_Successfully_Submitted:
          "¡El formulario ha sido enviado con éxito!",
        ok_Got_It_button: "¡Hecho!",
        Passwords_must_match: "Las contraseñas deben coincidir",
        try_again_button: "¡Intentar de nuevo!",
        Ok_button: "¡Listo!",
        Got_it: "¡Entendido!",
        reset_Password_button: "¡Restablecer la contraseña!",
        sorry_Looks_Like:
          "Lo sentimos, parece que se han detectado algunos errores, inténtalo de nuevo.",
        customer_Required: "El nombre del cliente es un campo requerido",
        customer_Email_Required:
          "El correo electrónico del cliente es un campo requerido",
        address_Required: "El correo electrónico es un campo requerido",
        town_Required: "La ciudad es un campo requerido",
        state_Required: "El estado es un campo requerido",
        post_Code_required: "El código postal es un campo requerido",
        last_Week: "La semana pasada",
        last_month: "El mes pasado",
        last_Three_months: "Hace 3 meses",
        date_Range_Required: "El intervalo de fechas es requerido",
        new_Been_Added:
          "Tu nuevo usuario ha sido registrado en el equipo de ciberseguridad de tu empresa.",
        error_Adding_User: "Error al agregar usuario",
        have_Some_Problems:
          "tenemos algunos problemas con el proceso de aplicar al empleo o enviar el mensaje a la empresa",
        hooray_Succesfully_Applied:
          "¡Hurra! Has aplicado con éxito a este empleo. La empresa revisará tu perfil pronto y se comunicará contigo si está interesada.",
        Status_Job_Posting:
          "El estado de esta publicación de trabajo ha cambiado.",
        error_Changing_Status: "Tenemos un error al cambiar de estado.",
        succesfullyCompleted_Training:
          "¡Hurra! Has completado con éxito este Entrenamiento. Visita el módulo carrera profesional para ver cómo aumentan tus niveles actuales y no olvides seguir con tus entrenamientos.",
        problem_Apply_Job:
          "tenemos algunos problemas con el proceso de aplicar a este empleo o enviar el mensaje a la empresa",
        sending_Mail_Information:
          "Te enviaremos un correo electrónico con información sobre cómo aplicar nuestro código de promoción pronto.",
        employee_Been_Deleted:
          "El Administrador de la empresa ha sido eliminado de tu equipo.",
        error_Deleting_Employee: "Tenemos un error al eliminar al empleado.",
        join_talent_pending:
          "Tu empresa está esperando que aceptes la invitación a unirte a su equipo de talent en ciber seguridad.",
        position_Deleted: "El empleo ha sido eliminado",
        error_Deleting_Position: "Tenemos un error al eliminar la posición.",
        now_Submit_Form: "¡Todo está genial! Ahora envía este formulario",
        your_Job_Information:
          "La información de tu puesto de trabajo ha sido guardada. ¡Nuestro algoritmo especializado de IA está listo para hacer match entre tu vacante y nuestro pool de Talent!",
        profile_Information_Saved:
          "¡La información de tu perfil ha sido guardada!",
        check_Red_Fields: "Por favor revisa los campos rojos.",
        securityClearanceRequired:
          "Este trabajo requiere autorización de seguridad. Solo las personas que tengan configurada la autorización de seguridad en la información de su perfil pueden postularse.",
        Your_file_has_been_successfully:
          "Tu archivo ha sido subido exitosamente. Nuestra IA de Seccuri lo analizará para mejorar la información proporcionada hasta ahora.",
        sorry: "Lo Sentimos",
        All_set: "¡Todo listo!",
        by_acepting_you:
          "Al aceptar que dispone de una habilitación de seguridad (o que se le ha concedido una en el pasado), da su consentimiento para facilitar información veraz que pueda ser necesaria para posteriores investigaciones de antecedentes y procesos de validación de candidatos.",
        in_case_you_currently:
          "En caso de que actualmente tenga una habilitación 'activa' o 'vigente' y solicite un puesto de trabajo que requiera un nivel de habilitación determinado, aún puede estar sujeto a revisiones de antecedentes por parte del Departamento de Recursos Humanos de una empresa o por parte del Gobierno Federal.",
        We_have_successfully:
          "¡Hemos recibido tu solicitud de nominación con éxito!",
        If_you_have_nominated:
          "Si has nominado a un docente, te recomendamos estar alerta a la publicación de su perfil en este módulo para empezar a votar cuanto antes.",
        If_you_are_a_teacher:
          "Si eres docente y te has autonominado, ¡busca tu tarjeta entre los competidores y a votar!",
        form_Several_Errors:
          "El formulario tiene varios errores, por favor revisa los campos rojos.",
        course_Deleted: "El curso ha sido eliminado.",
        error_Deleting_Course: "Tenemos un error al eliminar el curso.",
        courses_Updated_Successfully:
          "Tus cursos se han actualizado con éxito.",
        certification_Deleted: "La certificación ha sido eliminada.",
        error_Deleting_Certification:
          "Tenemos un error al eliminar la certificación.",
        certifications_Updated_Successfully:
          "Tus certificaciones se han actualizado con éxito.",
        dateIssueIsRequired:
          "Por favor llenar todos los campos: Fecha de emisión.",
        startdate: "Por favor llenar todos los campos: Fecha de Inicio",
        enddate: "Por favor llenar todos los campos: Fecha de finalización",
        please_name: "Por favor llenar todos los campos: Nombre del Curso",
        Select_level_error: "Rellene todos los campos: Seleccione un nivel",
        certification_error: "Error al agregar la Certificación",
        education_Deleted: "El entrenamiento ha sido eliminada.",
        error_deleting_Education:
          "Tenemos un error al eliminar el entrenamiento.",
        education_History_Updated_Successfully:
          "Tu historial académico se ha actualizado correctamente.",
        Select_school_error: "Rellene todos los campos: Institución educativa",
        Select_degree_error: "Rellene todos los campos: Título obtenido",
        language_Deleted: "El idioma ha sido eliminado.",
        error_Deleting_Language: "Tenemos un error al eliminar el idioma.",
        languages_Updated_Successfully:
          "Tus idiomas se han actualizado con éxito.",
        reference_Deleted: "La referencia ha sido eliminada.",
        error_deleting_Reference: "Tenemos un error al eliminar la referencia.",
        references_Updated_Successfully:
          "Tus referencias se han actualizado con éxito.",
        information_Succesfully_Submitted:
          "¡Tu información ha sido enviada con éxito!",
        invalid_Infromation: "información no válida.",
        roles_Updated_Successfully:
          "Tus roles de NIST NICE han sido actualizados exitosamente. ",
        message_Already_Talent:
          "Su mensaje ha sido enviado con éxito al correo electrónico de Cyber Talent.",
        problems_Sending_Message_Talent:
          "tenemos algunos problemas para enviar el mensaje al talent.",
        account_Eliminated: "Tu cuenta ha sido eliminada.",
        Your_communication_preferences:
          "Tus preferencias de privacidad han sido actualizadas con éxito",
        email_Password_Invalid: "Correo electrónico o contraseña no válidos.",
        email_Been_reset:
          "Tu correo electrónico ha sido reiniciado. Utiliza el nuevo correo electrónico para iniciar sesión.",
        password_Been_Reset:
          "Tu contraseña ha sido restablecida. Utiliza el nuevo correo electrónico para iniciar sesión.",
        password_Confirmation_Password_Invalid:
          "Nueva contraseña o contraseña de confirmación no válida.",
        email_Successfully_Changed:
          "¡El correo electrónico se cambió con éxito!",
        password_Successfully_Changed: "¡La contraseña se cambió con éxito!",
        account_Already_Activated:
          "Su cuenta se ha activado correctamente. Ahora se le dirigirá a su cuenta Seccuri.",
        token_Invalid: "Token inválido",
        instructions_Reset_Password:
          "Recibirás un correo electrónico con las instrucciones para restablecer tu contraseña",
        user_Password_Invalid: "Usuario o contraseña inválidos.",
        password_Must_More_Characters:
          "La contraseña debe tener al menos 12 o más caracteres con una combinación de letras, números y símbolos.",
        password_Changed_Succesfully:
          "Tu contraseña ha sido cambiada con éxito. Utiliza la nueva contraseña para iniciar sesión.",
        email_Changed_Succesfully:
          "El Correo electrónico ha sido cambiado correctamente y puede usarlo para iniciar sesión.",
        maximun_Number_Account_Blocked:
          "Has alcanzado el número máximo de intentos de inicio de sesión y tu cuenta está bloqueada. Para comenzar a usar la plataforma de Seccuri, deberás restablecer tu contraseña",
        fill_Valid_Phone_Number:
          "Por favor, introduce un número de teléfono válido.",
        password_More_Characters:
          "La contraseña debe tener al menos 12 o más caracteres con una combinación de letras (inferior y superior), números y símbolos.",
        accept_Termns_Conditions:
          "Por favor, lee y acepta los términos de condiciones.",
        accept_privacy_statement:
          "Por favor, lea y acepte nuestra declaración de privacidad.",
        successfully_Registered_Seccuri:
          "¡Felicidades! Te has registrado con éxito en Seccuri. Por favor revisa tu bandeja de entrada para verificar y activar tu cuenta.",
        successfully_Registered_Seccuri_talent:
          "¡Felicidades! Te has registrado con éxito en Seccuri. Por favor inicie sesión con su correo electrónico y contraseña.",
        happened_Error_During_Register:
          "¡Lo siento! Ocurrió un error durante el registro, intenta nuevamente.",
        exit_edit_profile:
          "Espera! Si cierras la edición del perfil ahora podrías perder la información. Por favor asegúrate de guardar tu perfil antes de salir. Estás seguro que desea salir? ",
        confirm_exit_edit_profile: "Salir",
        Open_edit_profile_Hi: "Hola ",
        Open_edit_profile:
          "Estás a punto de iniciar la edición de tu perfil. Por favor asegúrate de ir guardando tu información a medida que la ingresas usando los botones azules que hay para cada sección, así evitarás perderla al final del proceso.",
        password_Expired:
          "Su contraseña ha caducado y la cuenta ha sido bloqueada por motivos de seguridad. Para seguir utilizando la plataforma debe cambiarla siguiendo las instrucciones",
        password_Expired_Alert:
          "Por razones de seguridad, su contraseña caducará en",
        password_Expired_Alert_2:
          "días. Por favor, cambie la contraseña de tu cuenta lo antes posible para seguir utilizando la Plataforma Seccuri.",
        temporary_Emails:
          "La plataforma Seccuri no admite direcciones de correo temporales.",
        please_Wait: "Por favor espere...",
        submit: "Enviar",
        we_have_errors:
          "Se han producido errores al cargar el Plan de Carrera seleccionada. Por favor, inténtelo de nuevo.",
        we_have_errors_link_account:
          "Tenemos errores para vincular su cuenta de Talent. Por favor, inténtelo de nuevo desde su correo electrónico de activación.",
        link_company:
          "Vamos a proceder a vincular su cuenta de Talent con el panel de control de la empresa. Por favor, Inicia sesión para completar.",
        link_talent_company_success:
          "Hemos vinculado su cuenta de Talent con éxito. Puede ver la pestaña 'Relacionado con la empresa' en la información de tu perfil.",
        link_talent_company_fail: "Vinculo no generado.",
        link_accept_Award_success:
          "¡Ya estás en la competencia! 🎉 Ahora puedes encontrar tu tarjeta de docente en el módulo Seccuri Awards, votar y compartirla con tus estudiantes y colegas de ciberseguridad para sumar más votos. ¡Muchos éxitos!🚀",

        confirm_acept_Awards: "Iniciar sesión",
        confirm_link_talent_account: "Aceptar invitación",
        not_acept_link_talent_account: "No aceptar",
        try_again: "Vuelva a intentar la activación",
        settings: "Configuración",
        account: "Cuenta",
        the_Employee_Has_Been_Terminated:
          "El empleado ha sido desvinculado de la empresa.",
        the_Talent_Has_Been_Added:
          "El talent ha sido agregado a la empresa y se le ha enviado una notificación por correo electrónico. ",
        there_is_Already_a_User:
          "Ya existe un usuario relacionado con una empresa con la misma dirección de correo electrónico.",
        The_email_registered:
          "El correo esta registrado en la plataforma como empresa. Por favor usar uno diferente.",
        your_info_was_saved: "Su información ha sido actualizada.",
        your_Experiences_Could_Not:
          "Tus experiencias no se han podido actualizar correctamente. Por favor inténtalo de nuevo.",
        error_Occurred_in_the_Invitation:
          "Ha ocurrido un error en la invitación que has tratado de hacer al talent.",
        unlink_Company: "Desvincular Empresa",
        you_want_to_Unlink_your_Account_From_the_Company:
          "Estás seguro que deseas desvincular tu cuenta de la empresa? La empresa será notificada de tu desvinculación. Podrás seguir usando tu cuenta normal en Seccuri.",
        unlink_Button: "Desvincular",
        unlinked_From_the_Company:
          "Tu cuenta de usuario ha sido desvinculada de la empresa. Sigue usando tu cuenta libre en Seccuri.",
        error_When_Unlinking_the_Company:
          "Tenemos un error al desvincular la empresa.",
        presenting_Errors_to_Unlink_your_Talent:
          "Estamos presentando errores para desvincular tu Cuenta Talent. Vuelva a intentarlo desde su correo electrónico.",
        already_Exists_an_Account_Registered:
          "¡Lo siento! Ya existe una cuenta registrada con dirección de correo electrónico.",
      },
    },
    careerPath: {
      RecommendedByCompany: "Recomendado por su empresa",
      SubscriptionsPlans: "Planes de suscripción",
      Assign_Career_Path:
        "Conoce el progreso de este Company Talent respecto al plan de carrera seleccionado y recomiéndaselo en caso de estar alineado con sus objetivos de desarrollo profesional.",
      Assign_Career_Path_Description:
        "Recomienda el plan de carrera seleccionado a miembros de tu Equipo de Ciberseguridad para garantizar el desarrollo de las habilidades necesarias, en línea con los objetivos de ciberseguridad de tu empresa.",
      Recommend: "Recomendar",
      undo: "Retirar la recomendación",
      Asign_careerPath_employee:
        "Asigna el plan de carrera a tu Equipo de Ciberseguridad",
      Asign_careerPath_employee_description:
        "Recomienda el plan de carrera seleccionado a miembros de tu Equipo de Ciberseguridad para garantizar el desarrollo de las habilidades necesarias, en línea con los objetivos de ciberseguridad de tu empresa.",
      Duplicate_and_Edit: "Duplicar y editar",
      Duplicate_and_Edit_title: "Personaliza el plan de carrera Seccuri",
      Duplicate_and_Edit_description:
        "Personaliza el plan de carrera seleccionado duplicándolo y editando sus entrenamientos según sea necesario.",
      edit_current_career: "Editar carrera Seleccionada",
      view_Button: "Ver",
      customize: "Personalizar",
      Your_Cybersecurity_Career_Paths:
        "Tus planes de carrera profesional en ciberseguridad",
      Your_Cybersecurity_Career_Paths_Descipiion:
        "En este módulo encontrarás todos tus planes de carrera guardados y duplicados para que los uses como guía y los personalices según tus objetivos profesionales.",
      Recommended_Company_Career_Paths:
        "Planes de carrera recomendados por tu empresa",
      Recommended_Company_Career_Paths_description:
        "En este módulo podrás conocer los planes de carrera recomendados por tu empresa vinculada, basados en sus objetivos de desarrollo de talent en ciberseguridad.",
      Your_Company_Career_Paths: "Planes de carrera de la empresa",
      Your_Company_Career_Paths_Descroption:
        "En este módulo encontrarás todos los planes de carrera guardados y duplicados por tu empresa. Podrás verlos, personalizarlos y asignarlos fácilmente a los miembros de tu Equipo de Ciberseguridad.",

      Career_Path_Personalization: "Personalización de planes de carrera",
      Career_Path_Personalization_descripcion:
        "¡Bienvenido al módulo de Edición de Planes de Carrera! Aquí puedes adaptar tu plan de carrera seleccionado personalizando los entrenamientos asociados. El plan de carrera original incluye entrenamientos sugeridos para cada nivel, pero en esta sección, tienes la posibilidad de:",
      optimize_your_cubersecurity:
        "Optimiza tu careera en ciberseguridad creando un plan profesional personalizado que se ajuste perfectamente a tus objetivos. ¡Comencemos!",
      optimize_your_cubersecurity_company:
        "Optimice el viaje de ciberseguridad de sus Talent creando un plan de carrera personalizado que se alinee perfectamente con sus objetivos mutuos. ¡Empecemos ya!",
      Remove_Trainings: "Eliminar entrenamientos",
      Remove_Trainings_description:
        ": Elimina los entrenamientos existentes para perfeccionar tu plan de carrera según tus objetivos.",
      Remove_Trainings_description_company:
        ": Elimine los entrenamientos existentes para perfeccionar el plan de carrera de acuerdo con los objetivos de desarrollo del talent de tu empresa.",
      ReCategorize: "Recategorizar",
      ReCategorize_description:
        ": Redefine los entrenamientos según el nivel de carrera de tu elección.",
      Add_New_Trainings: "Agregar nuevos entrenamientos",
      Add_New_Trainings_description:
        ": Explora el ecosistema de entrenamiento de Seccuri y asigna nuevos programas a niveles específicos del plan de carrera.",
      Remove: "Eliminar entrenamiento asociado",
      view_trainings: "Guardar y editar entrenamientos",
      edit_trainings_info:
        "Los cursos que elimines puedes volver a añadirlos desde la opción Buscar Entrenamientos.",
      delete_career: "Eliminar",
      are_you_sure:
        "¿Estás seguro de que deseas eliminar el plan de carrera seleccionado?",
      SearchTrainings: "Buscar Entrenamientos",
      entry_Level: "Nivel básico",
      mid_Career: "Carrera intermedia",
      Professional: "Profesional",
      CareerPathName: "Nombre del plan de carrera",
      Security_Architect: "Arquitecto de Seguridad",
      SOC_Professional: "Profesional de SOC",
      Red_Team_Professional: "Profesional del Equipo Rojo",
      Cybersecurity_Auditor: "Auditor de Ciberseguridad",
      Security_ArchitectDescription:
        "Este plan de carrera supervisa la evaluación de los sistemas de una organización en busca de debilidades y es responsable de diseñar, construir, probar e implementar sistemas de seguridad informática.",
      SOC_ProfessionalDescription:
        "Un profesional de SOC monitorea, analiza y responde a incidentes de seguridad que una organización podría enfrentar. Las funciones más comunes incluyen evaluar sistemas de TI, identificar y cerrar vulnerabilidades, y reforzar la resistencia de la seguridad.",
      Red_Team_ProfessionalDescription:
        "Este plan de carrera supervisa la fortaleza real de la defensa cibernética de una organización al encontrar debilidades durante las prácticas de prueba del sistema y proporcionar retroalimentación valiosa que ayudará a reforzar los controles de ciberseguridad.",
      Cybersecurity_AuditorDescription:
        "Evalúa e informa sobre la efectividad de los controles de ciberseguridad implementados, las políticas y la seguridad general de los sistemas de TI de una organización.",
      source: "Fuente: ",
      Learning_Path: "Ciberseguridad desde cero:",
      Learning_Paths: "Plan de aprendizaje para principiantes",
      Start_your_journey:
        "Inicia tu viaje en ciberseguridad con este módulo diseñado para personas sin experiencia previa en el campo.",
      Start_by_exploring:
        "Explora recursos sobre ciberseguridad, inscríbete en los mejores entrenamientos en línea para obtener y certificar tu experiencia, y descubre empleos y pasantías globales de nivel inicial para aplicar y consolidar tus conocimientos.",
      Start_your_journey_company:
        "Introduce a los equipos digitales de tu organización a la ciberseguridad con este módulo diseñado para dotar a tus colaboradores con los conocimientos básicos en el campo",
      Start_by_exploring_company:
        "Accede a recursos, entrenamientos y certificaciones clave que cubren los fundamentos de la ciberseguridad, ideales para mejorar la conciencia organizacional y equiparlos con los conocimientos necesarios para comenzar a implementar prácticas efectivas de ciberseguridad o buscar un plan de carrera profesional en este campo.",
      Next_Steps: "Próximos pasos:",
      As_you_acquire:
        "A medida que adquieras nuevas habilidades y experiencia, vuelve al módulo de Roles NIST NICE de Seccuri para actualizar tu perfil de ciberseguridad y explora los planes de carrera sugeridos que se alineen con tu experiencia en crecimiento, para seguir desarrollando tus habilidades según tus metas profesionales.",
      Let_get_started: "¡Comencemos!",
      Basic_Information: "Recursos",
      Trainings: "Entrenamientos",
      Jobs: "Empleos",
      Basic_Information_desc:
        "Accede a material clave para aprender sobre ciberseguridad y sus fundamentos. Conoce los principios básicos de la ciberseguridad, las distintas áreas de especialización y el papel fundamental que desempeña en un panorama digital en constante evolución.",
      Trainings_desc:
        "Explora entrenamientos  en línea de alta calidad para iniciar una carrera integral en ciberseguridad, adquirir habilidades técnicas esenciales y obtener certificaciones para validar tu experiencia.",
      Basic_Information_desc_company:
        "Accede a recursos clave para aprender sobre la ciberseguridad y sus fundamentos, y compártelos con tus equipos digitales. Conoce sus principios básicos, las diferentes áreas de especialización y su papel crítico en el entorno digital.",
      Trainings_desc_company:
        "Explora y comparte cursos, entrenamiento y certificaciones en línea de alta calidad con tus equipos digitales para brindarles una comprensión integral de la ciberseguridad o ayudarles a adquirir las habilidades técnicas necesarias para empezar una carrera profesional en el campo.",
      Jobs_desc:
        "Descubre y postúlate a algunos de los empleos y pasantías globales más populares para poner a prueba tus conocimientos y habilidades, y obtener experiencia valiosa en el campo.",
      Basic_Information_Cybersecurity_Foundations:
        "Recursos: Fundamentos de Ciberseguridad",
      Welcome_starting_company:
        "¡Bienvenido al punto de partida para que tus equipos digitales comiencen a adquirir habilidades y conocimientos en ciberseguridad!",
      variety_of_carefully_company:
        "En este módulo, descubrirás una variedad de recursos cuidadosamente seleccionados, que incluyen sitios web, artículos, blogs, videos, documentos técnicos y más, para compartir con tus colaboradores y obtener una comprensión global y sistemática de la ciberseguridad. ",
      Explore_these_valuable_company:
        "Explorar estas valiosas referencias les ayudará a adquirir los conocimientos esenciales para su comprensión de la ciberseguridad. ¡Ingresa a este módulo en cualquier momento e inicia la formación de tus equipos digitales en ciberseguridad!",

      Welcome_starting:
        "¡Bienvenido al punto de partida de tu viaje en ciberseguridad!",
      variety_of_carefully:
        "En este módulo, descubrirás una variedad de recursos cuidadosamente seleccionados, como sitios web, artículos, blogs, videos, documentos técnicos, y más, para brindarte una comprensión global y sistemática de la ciberseguridad.",
      Explore_these_valuable:
        "Explora estas referencias valiosas a tu propio ritmo para obtener conocimientos fundamentales esenciales para tu comprensión de la ciberseguridad. Siéntete libre de explorar en cualquier momento e inicia el emocionante camino para convertirte en un experto en ciberseguridad.",
      Trainings_Cybersecurity_Skill_Building: "Entrenamientos:",
      Cybersecurity_Skill: "Desarrollo de habilidades en ciberseguridad",
      Find_a_selection:
        "Encuentra una selección de algunos de los cursos, entrenamientos y certificaciones de ciberseguridad más recomendados para principiantes, ofrecidos por destacados proveedores globales de entrenamiento.",
      As_you_explore:
        "A medida que explores y realices estos entrenamientos y certificaciones, identifica los temas clave que resuenen contigo. Este conocimiento será valioso más adelante para identificar planes de carrera profesionales en ciberseguridad que se alineen mejor con tus intereses y objetivos.",
      Entry_Level_Jobs: "Empleos de nivel de entrada:",
      Find_a_selection_company:
        "Encuentra una selección de algunos de los cursos, entrenamientos y certificaciones de ciberseguridad más recomendados para principiantes, ofrecidos por los principales proveedores mundiales en entrenamiento.",
      As_you_explore_company:
        "Al compartirlos con colaboradores interesados en iniciar una carrera en ciberseguridad, podrán identificar los temas clave que les interesen. Este conocimiento será valioso más adelante para identificar los planes de carrera en ciberseguridad que mejor se adapten a sus intereses y objetivos.",
      Start_Gaining_Professional: "Comienza a obtener experiencia profesional",
      Explore_top_entry_level:
        "Descubre algunas de las mejores oportunidades laborales y pasantías a nivel mundial para iniciar tu carrera profesional. Encuentra descripciones detalladas de cada posición y conecta directamente con las empresas empleadoras para postularte a las vacantes que se alineen con tus habilidades e intereses. ¡Tu carrera en ciberseguridad comienza aquí!",
      Learn_About_Top:
        "Conoce los principales roles de ciberseguridad para principiantes",
      Top_Cibersecurity:
        "Principales roles de ciberseguridad para principiantes",
      close: "Cerrar",
      viewAll: "Ver todos",
    },
    sessions: {
      description: "No te pierdas nuestro próximo",
      register_button: "Guarda el envento en tu calendario",
      know_more: "Más información",
      SeccuriSession: "Seccuri Session",
      webinar: "webinar!",
      date: "Fecha",
      hour_PST: "Hora",
      california_hour: "California",
      NewYork_hour: "New York",
    },
    SeccuriSessionsMenu: "Seccuri Session Webinars",
    SeccuriSessions: "Seccuri Sessions",
    level: "Nivel:",
    provider: "Proveedor:",
    Career_Path_Level: "Nivel del plan de carrera:",
    Status: "Estado:",
    statussinpoint: "Estado",
    Company_Job_Listings: "Ofertas de empleo en empresas",
    Your_CV_is_in_process: "¡Tu CV está en proceso!",
    Our_AI_is_analyzing:
      "Nuestra IA está analizando tu archivo PDF y puede tardar unos minutos. Mientras tanto, sigue explorando Seccuri. Te avisaremos cuando esté listo para que lo revises.",
    since_we_use_AI:
      "Importante: algunos datos pueden no ser 100% precisos, así que te recomendamos verificar la información.",
    Everything_ready: "¡Todo listo!",
    Your_file_has_been_successfully:
      "Tu archivo se ha subido y analizado exitosamente por nuestra IA de Seccuri. Te recomendamos refrescar la página y revisar la información de tu perfil para confirmar que todo esté correcto.",
    File_Upload: "Subir archivo",
    Drag_and_Drop: "Arrastra y suelta tu archivo.",
    Draganddropbrowse: "Arrastra y suelta tu archivo o <u>explora</u>",
    Please_try_again: "Intenta de nuevo",
    Oops: "¡Ups!",
    Something_went_wrong:
      "Algo salió mal al cargar tu archivo. Por favor, intenta nuevamente o sube un archivo diferente.",
    tap_to_retry: "Toca para reintentar",
    Completed: "Completado",
    InProgress: "En progreso",
    Certification: "Certificación",
    Training: "Entrenamiento",
    Awareness: "Concienciación",
    Education: "Educación",
    Course: "Curso",
    Foundations: "Básico",
    Introductory: "Introducción",
    Intermediate: "Intermedio",
    Advanced: "Avanzado",
    New_to_Cyber: "Nuevo en ciber",
    Training_in_Progress: "Entrenamiento en progreso",
    Completed_Training: "Entrenamiento completado",
    Delete_Training: "Entrenamiento eliminado",
    language_English: "Inglés",
    language_Spanish: "Español",
    language_Portugues: "Portugués",
    dashboard: "Tablero",
    layoutBuilder: "Constructor de maquetación",
    craft: "Elaborado",
    pages: "Paginas",
    profile: "Perfil",
    jobs: "Portal de Empleos",
    trainings: "Entrenamientos",
    career_Path: "Rutas Profesionales",
    profileOverview: "Descripción general",
    projects: "Proyectos",
    campaigns: "Campañas",
    documents: "Documentos",
    connections: "Conexiones",
    wizards: "Magos",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Cuenta",
    accountOverview: "Descripción general",
    settings: "Configuraciones",
    company_Related: "Relacionado con la Empresa",
    Account_Privacy: "Privacidad de la cuenta",
    authentication: "Autenticación",
    basicFlow: "Flujo básico",
    signIn: "Registrarse",
    signUp: "Inscribirse",
    passwordReset: "Restablecimiento de contraseña",
    multiStepSignUp: "Regístrese Multi-Pasos",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Aplicaciones",
    chat: "Chat",
    privateChat: "Chat privado",
    groupChat: "Grupo de chat",
    drawerChat: "Chat del cajón",
    widgets: "Widgets",
    widgetsLists: "Liza",
    widgetsStatistics: "Estadísticas",
    widgetsCharts: "Gráficos",
    widgetsMixed: "Mezclada",
    widgetsTables: "Mesas",
    widgetsFeeds: "Alimenta",
    changelog: "Registro de cambios",
    docsAndComponents: "Documentos & Componentes",
    megaMenu: "Mega menú",
    exampleLink: "Enlace de ejemplo",
    modals: "Modales",
    general: "General",
    inviteFriends: "Invitar A Amigos",
    viewUsers: "Ver Usuarios",
    upgradePlan: "Plan De Actualización",
    shareAndEarn: "Compartir Y Ganar",
    forms: "Formas",
    newTarget: "Nuevo Objetivo",
    newCard: "Nueva Tarjeta",
    newAddress: "Nueva Direccion",
    createAPIKey: "Crea Clave De Api",
    twoFactorAuth: "Dos Factores",
    createApp: "Crear Aplicacion",
    createAccount: "Crear Una Cuenta",
    activity: "Actividad",
    documentation: "Documentación",
    components: "Componentes",
    resources: "Recursos",
    customers: "Clientes",
    gettingStarted: "Empezando",
    customersListing: "Listado De Clientes",
    customerDetails: "Detalles De Los Clientes",
    calendarApp: "Calendario",
    subscriptions: "Suscripciones",
    getStarted: "Empezando",
    subscriptionList: "Lista de suscripción",
    addSubscription: "Añadir suscripción",
    ViewSubscription: "Suscripción",
    personal_information: "Información personal",
    language: "Idioma",
    my_Profile: "Perfil",
    my_saved_jobs: "Trabajos guardados",
    my_careers: "Mis carreras",
    accountSettings: "Configurar cuenta",
    sign_Out: "Cerrar sesión",
    on_Favorites: "Favoritos",
    on_Results: "Resultados",
    recommendations: "Recomendaciones",
    profile_Completion: "Completar perfil",
    overview: "Información general",
    copyright_Seccuri:
      "Copyright © 2024 Seccuri, Inc. Todos los derechos reservados.",
    terms_Conditions: "Términos y Condiciones",
    and: " y ",
    privacy_Statement: "Declaración de Privacidad",
    profile_Details: "Detalles del perfil",
    talent_Tittle1: "¡Necesitamos tu atención!",
    talent_Subtittle1:
      "Asegúrate de completar tu perfil de Talent para obtener una mejor calificación y permitir que las empresas que publican trabajos te encuentren más fácil.",
    please_Get_Your_Roles: "¡Obtenga su Roles!",
    to_get_Roles_Please_Follow:
      "Para obtener los roles NIST NICE, siga los pasos 1, 2 y 3.",
    you_Can_Start_With_First_Step:
      "Puedes empezar con el primer paso desde aquí",
    select_your_Areas: "1. Selecciona tus áreas",
    profile_Overview: "Información general del perfil",
    completed_Sections: "Secciones completadas: ",
    home: "Inicio",
    full_Profile: "Perfil Completo",
    all_Jobs: "Todos los empleos",
    Saved_as_dratf_Jobs: "Guardado como dratf Jobs",
    published_jobs: "Trabajos publicados",
    jobs_Menu: "Empleos",
    edit_Profile: "Editar perfil",
    saved_Jobs: "Empleos guardados",
    applied_Jobs: "Empleos aplicados",
    jobs_with_candidates: "Empleos con candidatos",
    trainings_Menu: "Entrenamientos",
    all_Trainings: "Todos los entrenamientos",
    saved_Trainings: "Entrenamientos guardados",
    training_Progress: "Progreso de entrenamientos",
    career_Paths: "Carrera profesional",
    Cybersecurity_from_Scratch: "Ciberseguridad desde cero",
    all_Career_Paths: "Planes de carrera de ciberseguridad",
    Company_Career_Paths: "Planes de Carrera de la empresa",
    my_Careers: "Mis planes de carrera",
    saved_Careers: "Carreras Guardadas",
    company_Related_Menu: "Empresa relacionada",
    admin_Employees: "Usuarios administradores de la empresa",
    cybersecurity_Talent_Team: "Equipo de ciberseguridad",
    seccuriawards_path: "Seccuri Awards",
    MexicoAwards: "Premios México",
    ColombiaAwards: "Premios Colombia",
    ChileAwards: "Premios Chile",
    RoleDiscoveryProgress: "Progreso en el descubrimiento de roles",
    Are_you_new_to_cybersecurity: "¿Eres nuevo en ciberseguridad?",
    This_module_is_designed:
      "Este módulo está diseñado para ayudarte a descubrir los roles en ciberseguridad que mejor se adaptan a tu experiencia y tus intereses. Respondiendo unas preguntas, analizaremos tu perfil y te ofreceremos sugerencias personalizadas para guiarte hacia una carrera en ciberseguridad. ¡Empecemos!",
    I_have_experience_in_cybersecurity: "Tengo experiencia en ciberseguridad",
    I_am_an_enthusiast: "Sí, soy un entusiasta",
    Where_are_you_in_your_career: "¿En qué etapa de tu carrera estás?",
    Graduated_High_School: "Graduado/a del colegio",
    I_am_just_graduated: "Estoy cursando/soy recién egresado de la universidad",
    I_want_to_change: "Quiero cambiar mi carrera actual",
    I_am_doing_just_graduated_from_my_Bachelors:
      "Estoy cursando/soy recién egresado de la universidad",
    I_have_finished_at_least_one_training_in_cybersecurity:
      "He realizado al menos un curso de ciberseguridad",
    I_work_in_cybersecurity: "Trabajo en cibeseguridad",
    Let_get_started: "¡Vamos a comenzar!",
    Choose_only_one: "Elige solo uno",
    Choose_all_that_apply: "Elige todos los que correspondan",
    Math: "Matemáticas",
    Languages: "Idiomas",
    Social_Studies: "Ciencias Sociales",
    Art: "Arte",
    Sciences: "Ciencias (física, química, biología)",
    Technology: "Tecnología / Computación",
    Business: "Negocios / Economía",
    Major: "Área general de estudio",
    Write_in: "Escribe aquí",
    Minor_Specialization: "Especialidad",
    Marketing: "Mercadeo",
    Human_Resources: "Recursos Humanos",
    Physical_Security: "Seguridad Física",
    Accounting: "Contabilidad",
    Sales: "Ventas",
    Data_Analysis: "Análisis de Datos",
    Other: "Otro :",
    What_area_are_you_currently_working_school:
      "¿Qué materias te gustaron más en el colegio?",
    Solving_riddles_and_puzzles_or_logical_problems:
      "Resolver acertijos o problemas lógicos",
    Reading_Writing: "Leer / Escribir",
    Building_things_or_craft_making: "Construir cosas o hacer manualidades",
    Learning_new_things: "Aprender cosas nuevas",
    Work_in_group_projects: "Trabajar en proyectos/clases grupales",
    What_are_you_interested_in: "¿Qué te interesa?",
    What_skills_future:
      "¿Qué tipo de habilidades prefieres desarrollar en un futuro?",
    Which_area_interesting: "¿Cuál de estas áreas te parece más interesante?",
    Would_you_like_problems_under_pressure:
      "¿Te gustaría trabajar en un rol donde resuelvas problemas bajo presión?",
    Information_security_and_system_protection:
      "Seguridad de la información y proteger sistemas",
    Security_incident_and_event_response:
      "Responder a incidentes y problemas de seguridad",
    Understand_human_behavior_related_to_cybersecurity:
      "Entender el comportamiento humano en temas de seguridad",
    Analyze_data_and_identify_patterns: "Analizar datos y encontrar patrones",
    Investigate_vulnerabilities_and_analyze_cyber_attacks:
      "Investigar vulnerabilidades y analizar ciber ataques",
    Protect_and_improve_networks_IT_and_OT_systems:
      "Proteger y mejorar redes y sistemas de información",
    Design_security_policies_and_strategies:
      "Diseñar políticas y estrategias de seguridad",
    Train_others_on_cybersecurity_good_practices:
      "Educar a otros sobre buenas prácticas de seguridad",
    Yes_I_work_well_under_pressure_and_solving_problems_quickly:
      "Sí, disfruto resolver problemas rápidamente",
    It_depends_on_the_situation: "Depende de la situación",
    I_prefer_to_work_on_a_less_pressure_environment:
      "Prefiero trabajar en un ambiente menos presionado",
    What_area_are_you_currently_working_in: "¿En qué trabajas actualmente?",
    I_am_doing_just_graduated_from_my_Bachelor:
      "¿Qué estás estudiando/Qué estudiaste?",
    What_are_you_studying_What_did_you_study_Major: "Área general de estudio",
    What_are_you_studying_What_did_you_study_Minor: "Especialidad",
    What_did_you_study: "¿Qué estás estudiando/Qué estudiaste?",
    Major_This_includes_any_Graduate_Degree:
      "Área general de estudio / Esto incluye cualquier título de posgrado, título de escuela vocacional, título de colegio comunitario u otro.",
    Minor_Specialization_description: "Especialización",
    What_coding_language_do_you_know: "¿Qué lenguage de programación conoces?",
    I_dont_know_coding_languages: "No uso lenguajes de programación",
    Endpoint_Security_Tools:
      "Herramientas de Seguridad de Endpoints (ej. Antivirus y Antimalware, EDR, MDM)",
    Encryption_and_Data_Protection_Tools:
      "Herramientas de Encripción y Protección de Datos (ej. encripción de disco o archivos)",
    IAM_Tools:
      "Herramientas de IAM (Gestión de Identidades y Acceso) (ej. MFA, SSO, PAM)",
    Cloud_Security_Tools:
      "Herramientas de Seguridad en la Nube (ej. CSPM, CASB)",
    Security_Information_and_Event_Management: "Herramientas para de SIEM",
    Penetration_Testing_Tools: "Herramientas de Pruebas de Penetración",
    Vulnerability_Management_Tools:
      "Herramientas de Gestión de Vulnerabilidades (ej. escaneos de vulnerabilidades, gestion de parcheo)",
    Backup_and_Disaster_Recovery_Tools:
      "Herramientas de Backup y Recuperación de Desastres",
    Network_Security_Tools:
      "Herramientas de seguridad en la Red (ej. Firewalls, IDS/IPS, NAC, VPN, DNS, WAF)",
    Risk_Management_and_Compliance_Tools:
      "Herramientas de Gestión de Riesgo y Cumplimiento",
    Threat_Intelligence_Tools: "Herramientas de Inteligencia de Amenazas",
    Incident_Response_Tools:
      "Herramientas de Respuesta a Incidentes (ej. plataformas para gestionar incidentes, herramientas de forénsica)",
    None: "Ninguna",
    What_tools_do_you_use_or_have_learned_how_to_use:
      "¿Qué herramientas y tecnologías usas actualmente?",
    What_areas_in_cybersecurity_are_you_interested_in:
      "¿Qué áreas te interesa mas en ciberseguridad?",
    Securely_Provision: "Suministrar Protección",
    Operate_and_Maintain: "Operar y Mantener",
    Oversee_and_Govern: "Supervisar y Gobernar",
    Protect_and_Defend: "PRoteger y Defender",
    Analyze: "Analizar",
    Collect_and_Operate: "Recolectar y Operar",
    Investigate: "Investigar",
    What_training_or_certification_in_cyberescurity_have_you_completed:
      "¿Qué cursos o certificaciones de ciberseguridad has completado?",
    Training_certification: "Curso / Certificado",
    How_long_have_you_worked_in_cybersecurity:
      "¿Cuanto llevas trabajando en ciberseguridad?",
    Experience_less_than_1_year:
      "Tengo menos de 1 año de experiencia en ciberseguridad",
    Experience_1_to_3_years: "Tengo 1-3 años de experiencia en ciberseguridad",
    Experience_more_than_3_years:
      "Tengo más de 3 años de experiencia en ciberseguridad",
    What_is_your_current_role: "¿Cuál es tu cargo actual?",
    Role: "Rol",
    Industry: "Industria",
    Which_tools_technologies_do_you_use_or_have_learned_how_to_use:
      "¿Qué herramientas y tecnologías usas actualmente?",
    What_training_or_certification_in_cybersecurity_have_you_completed:
      "¿Qué cursos o certificaciones de ciberseguridad has completado?",
    Your_Results_Are_In: "¡Tus resultados están aquí!",
    Thanks_for_taking_the_quiz:
      "¡Gracias por realizar el cuestionario! A continuación, se muestra un desglose de cómo tu perfil se alinea con los roles de ciberseguridad (% de ajuste) según tus respuestas. Explora los módulos de Seccuri para comenzar a entrenar, obtener certificaciones y encontrar trabajos alineados con estos roles cuando estés listo.",
    Enhance_Your_Results: "Mejora tus resultados",
    Add_specific_NIST_NICE_KSTs:
      "Añade KSTs específicos de NIST NICE para obtener recomendaciones aún mejores. Las sugerencias de IA están personalizadas, pero no siempre son 100% precisas; ¡tu aporte las hace más inteligentes!",
    The_quiz_is_complete_In_just_a_moment:
      "¡El quiz ha finalizado! En unos momentos, nuestra IA analizará tus respuestas y te mostrará los roles de ciberseguridad que mejor se adaptan a ti.",
    Please_select_an_answer_to_continue:
      "Por favor, selecciona una respuesta para continuar.",
    Please_select_at_least_one_answer_to_continue:
      "Por favor, selecciona al menos una respuesta para continuar.",
    Please_include_answer_before_moving:
      "Por favor, incluya una respuesta antes de pasar al siguiente paso",
    Go_to_Result: "Ir al resultado",
  },
  pr: {
    jobsView: {
      index: {
        No_information_found:
          "Não foram encontradas informações sobre os filtros utilizados.",
        days: " dias atrás",
        EXPLORER_JOB_OPPORTUNITIES:
          "Oportunidades de emprego a serem exploradas",
        Date_Published: "Data de publicação",
        Deadline: "Data de encerramento",
        saved_jobs: "Empregos salvos",
        create_new_pos_button: " Criar novo cargo",
        edit_job_position_button: "Editar cargo",
        apply_button: "Aplicar",
        applied_button: "Aplicado",
        go_Certifications_button: "Ir para certificações",
        go_Languages_button: "Ir para to idiomas",
        continue_button: "Continuar",
        ok_Got_It_button: "Ok, entendi!",
        try_Again_button: "Tente novamente!",
        go_Nist_Nice_button: " Vá para NIST NICE",
        personal_information: "Informações pessoais",
        jobs: "Empregos",
        expiration_Date: "Data de validade",
        This_job_position: "Esta vaga de emprego será publicada até",
        according_your_suscription: "de acordo com seu plano de assinatura.",
        Your_profile_has_been: "Seu perfil foi atualizado com sucesso",
        salary_Range: "Faixa salarial",
        view_Job: "Ver trabalho",
        please_Wait: "Por favor, aguarde...",
        Required_Company: "Se exigido pela empresa",
        experience_Deleted_msj: "A experiência foi excluída.",
        deleting_Experience_msj: "Ocorreu um erro ao excluir a experiência.",
        your_Experiences_Have_msj:
          "Suas experiências foram atualizadas com sucesso.",
        certification_Been_Deleted_msj: "A certificação foi excluída.",
        have_Deleting_Certification_msj:
          "Ocorreu um erro ao excluir a certificação.",
        have_SomeErrors: "Ocorreu um erro, tente novamente mais tarde.",
        certification_Been_Updated_Successfully:
          "Sua certificação foi atualizada com sucesso.",
        please_Check_Red_Fields: "Verifique os campos em vermelho.",
        roles_Succesfully_Updated:
          "Suas Papéis NIST NICE foram atualizadas com sucesso!",
        invalid_Infromation: " informação inválida.",
        full_Time: "Tempo Integral",
        part_Time: "Meio período",
        self_Employed: "Por conta própria",
        freelance: "Freelance",
        contract: "Contrato de trabalho",
        internship: "Estágio",
        apprenticeship: " Aprendizagem",
        seasonal: "Sazonal",
        not_Specified: "Não especificado",
        less_Than_High_School: "Menos que colégio",
        high_School_Diploma_Equivalent: "Diploma de colégio ou equivalente",
        Some_College_Degree: "Alguma faculdade, sem diploma",
        postsecondary_Degree_Award: " Prêmio pós-secundário sem diploma",
        associate_Degree: "Diploma de associado",
        bachelor_Degree: "Bacharelato",
        master_Degree: "Maestrado",
        doctoral_Professional_Degree: " Doutorado ou título profissional",
        //clear_filter_button: "Limpar filtro",
        salary_range_salary:
          " O valor inserido na faixa máxima do salário deve ser maior que a faixa mínima.",
      },

      language: {
        ok_Got_It_button: "OK, entendi!",
        try_Again_button: "Tente novamente!",
        language: "Linguagem",
        select_Level: "Selecione um nível",
        begginer: "Nivel básico",
        knowledgeBasic:
          "Conhecimento de vocabulário básico, fala frases simples, habilidades elementares de leitura e escrita.",
        intermediate: "Intermediário",
        handle_Intermediate:
          " Capacidade de falar e entender para assuntos relacionados à rotina diária, pode lidar com comunicação intermediária, leitura e escrita.",
        proficient: "Proficiente",
        well_Advanced:
          "Um uso avançado do idioma, usando fala fluida, leitura e escrita.",
        native_Biligual: "Nativo/Bilíngue",
        more_fluent:
          "O nível de proficiência no idioma foi aumentado falando o idioma como língua nativa ou completamente fluente.",
        language_Been_Deleted: "O idioma foi excluído.",
        error_deleting_language: " Ocorreu um erro ao excluir o idioma.",
        languages_Succesfully_Updated:
          "Seus idiomas foram atualizados com sucesso",
      },
    },
    plans: {
      PostJobs: "Postar empregos",
      ViewTopTalent: "Ver os melhores Talent",
      ContactTalent: "Contacto Talent",
      TrainTalent: "Trenamiento de Talent",
      JobsPostNumber: "Número de empregos",
      JobPostDuration: "Duração do emprego",
      AdminCompanyUser: " Usuários da plataform",
      RecommendedTalent: " Top Talent",
      SoftSkill: " Habilidades interpessoais",
      HardSkill: "Habilidades técnicas",
      CareerDevelopment: "Desenvolvimento de carreira",
    },
    trainingView: {
      index: {
        Status: "Status:",
        clear_filter_button: "Limpar filtro",
        search_placeholder: "Procurar",
        TRAINING: "TREINAMIENTO",
        CERTIFICATION: "CERTIFICAÇÃO",
        learn_New_Skills_Through:
          "Aprenda novas habilidades por meio dos melhores treinamentos e certificações para avançar em sua carreira CyberSec!",
        learn_New_Skills_Through_t:
          "Descubra novos treinamentos de Cybersecurity para você!",
        Viewall: "Ver tudo",
        pricing: "Preços",
        not_Started: "Não Iniciado",
        free: "Grátis ",
        paid: "Pago",
        done: "Finalizado",
        all_Careers: "Todas as Carreiras",
        training_Provider: "Fornecedor",
        all_Roles: "Todas as Funções",
        categories: "Categorias",
        in_Progress: "Em Andamento",
        certification: "Certificação",
        have_Security_Clearance: "tem Security Clearance",
        Security_Clearance_required: "Security Clearance obrigatória",
        privateJobCompany: "Empregos em minha empresa",
        training: "Trenamiento",
        search: "Procurar",
        items_Found: "Itens encontrados",
        by_Recently_Added: "por Adicionado Recentemente ↓",
        recently_Updated: "Adicionado Recentemente",
        last_Month: "Mês Passado",
        last_Quarter: "Quarto Passado",
        last_Year: "Ano Passado",
        showing: "Mostrando",
        to: "para",
        of: "de",
        entries: "entradas",
        by: "Por:",
        author: "Autor:",
        duration: " Duração:",
        modules: " Módulos",
        regular_Price: " Preço regular:",
        subscription_for_take_training:
          "Assinatura mensal com possibilidade de teste gratuito para usuários iniciantes",
        education_for_take_training:
          "Consulte o site oficial do provedor para obter informações sobre preços",
        free_training: "Grátis",
        usd: "USD",
        seccuri_Price: " Preço Seccuri",
        contact_Us: " Contate-nos",
        target_Of_Training: "Detalhes do Treinamento",
        required_For: "Habilidades Associadas",
        There_are_no_skills:
          "Ainda não há habilidades associadas a esse treinamento.",
        contact_Seccuri_Team: "Contato a Equipe Seccuri",
        special_Prices: " Preços especiais Seccuri",
        complete_Training: "Processo de treinamento atualizado",
        as_Completed: "?",
        contact_For_Special_Seccuri_Price:
          "Contacte-nos para um preço especial para este treinamento!",
        take_This_Training: "Faça este treinamento >",
        Update_Training_Progress: "Atualize o processo de treinamento >",
        back_To_Trainings: "Voltar aos treinamentos",
        check_Training_progress: "Selecione uma Opção de Progresso",
        delete_progress: "Excluir Progresso",
        are_You_Interested_On_Taking_This_Training:
          "Are you interested on taking this training and gaining access to Seccuri's",
        by_Submitting_This_Request:
          " Ao enviar esta solicitação, enviaremos um e-mail com informações sobre como obter e aplicar nosso código promocional durante o processo de compra deste treinamento.",
        contact_To_Take_This_Training: "Contacte-nos para fazer o Trenamiento",
        please_Wait: "Por favor, aguante...",
        are_You_Sure_You_Want_To_Mark:
          " Você está fazendo o treinamento atualmente",
        or_have_already_completed_it: " ou já completou",
        Change_the_Trainings_Progress_below:
          "Altere o Progresso do Treinamento abaixo!",
        by_Submitting_This_Request_We_Will_Send:
          "Ao enviar esta solicitação, enviaremos um e-mail com informações sobre como iremos validar o treinamento concluído.",
        SelectProvider: "Selecione um Provedor",
        SelectLevel: "Selecione um Nível",
        SelectLocation: "Selecione Localização",
        SelectJobStatus: "Seleciona status do trabalho",
        SelectJobPrivacy: "Seleciona privacidade do trabalho",
        are_You_Interested_On_Taking_This_subscription:
          "Você está interessado em receber mais informações sobre nosso",
        are_You_Interested_On_Taking_This_subscription_2:
          "Por favor, deixe sua pergunta aqui",
        are_You_Interested_On_Taking_This_subscription_3: "Plano",
        by_Submitting_This_Request_We_Will_Send_subscription:
          " Ao enviar esta solicitação, enviaremos um e-mail com informações sobre como validaremos o processo de compra.",
        contact_To_Take_This_subscription: "Contate-nos",
        by_Submitting_This_Request_subscription:
          " Ao enviar esta solicitação, em breve um de nossos consultores entrará em contato com você por e-mail com as informações solicitadas.",
      },
    },
    talentView: {
      index: {
        Referrals: "Referências",
        If_a_teacher:
          "Se um professor convidou você para participar do Seccuri, digite o endereço de código de referência dele para criar o relacionamento",
        saved_Trainings: "Treinamentos salvos",
        edit_Profile_button: "Editar perfil",
        next_Step_button: " Próxima Etapa",
        career: "Carreira",
        profile_Details: " Detalhes de Perfil",
        roles: "Funções",
        education: "Educação",
        languages: "Idioma",
        certification: "Certificação",
        courses: "Cursos",
        recommendations: "Recomendações",
        personal_Information: "Informações Pessoais",
        personal_Data: "Datos Pessoais",
        nIST_NICE: "NIST NICE",
        experience: "Experiência",
        masters: "Títulos profissionais",
        certifications: "Certificações",
        courses_Finished: "Cursos terminados",
        external_Recommendations: "Recomendações externa",
        area: "Provisão Segura",
        overview: "Informações gerais",
        account: "Conta",
        is_Begginer: "Novo",
        is_BegginerDescription: "cybersecurity Talent",
        companies: "Empresas",
        relations: "Minha Empresa",
        my_Careers: "Meus planos de carreira",
        saved_Jobs: "Empregos Salvos",
        training_Progress: "Progresso de Treinamento",
        applied_Jobs: "Empregos Aplicados",
        jobs_with_candidates: "Empregos com Candidatos",
        jobs: "Empregos",
        view_applied_talents_Jobs: "Visualizar Talent",
      },

      roles: {
        edit_Roles_button: "Edite suas funções",
        select_Areas: "1. Selecione aqui as Áreas em que você trabalhou",
        improve_Your_Roles: "Melhore suas Funções cybersecurity!",
        can_Improve_The_Progress:
          "Você pode melhorar o progresso de suas Papéis NIST NICE a qualquer momento, atualizando suas áreas, especialidades e tarefas no",
        your_Nist_Nice_Roles: "Suas Papéis NIST NICE",
        your_Roles_And_Their_Progress:
          "Suas funções e seu progresso são baseados em suas áreas, especialidades e tarefas selecionadas.",
      },

      job: {
        back_button: "Voltar",
        submit_button: "Enviar",
        job_Placeholder1:
          " Diga à empresa por que você deseja se candidatar a esta posição aqui.",
        position_Details: " Detalhes da Posição",
        basic_Information: " Informação básica",
        nist_Nice_Roles: "Cybersecurity Roles",
        specialities_Tasks: "Especialidades e Tarefas",
        certifications: "Certificações ",
        certifications_Required: " Certificações Obrigatórias",
        languages: "Idiomas",
        job_Title: "Titulo de Cargo",
        company_Name: "Nome da Empresa",
        job_Department: "Departamento de Emprego",
        job_Description: "Descrição do Emprego",
        vacancy_Details: "Detalles da Vaga",
        salary_Range_Min: "Faixa Salarial Mín",
        salary_Range_Max: " Faixa Salarial Max",
        employment_Type: "Tipo de Emprego",
        duration: "Duração",
        requirements: "Requisitos",
        industry: "Indústria",
        level_Education: "Nível de Educação",
        open_Travel: "Aberto para Viajar",
        location: "Localização",
        nationatily: "Nacionalidade",
        contact_Company: "Contato com Empresa",
        certification: "Certificação",
        name: "Nome",
        view_Position: "Ver Posição",
        native_And_Learned_Languages_Required:
          "Idiomas nativos e aprendidos necessários",
        units: "Unidades (Dias, Meses, etc.)",
        modality: "Modalidade",
        this_Job_Does_Not_Require_Certifications:
          "Este trabalho não requer nenhuma função do NIST NICE.",
        please_Continue_Browsing_Through:
          "Continue navegando na próxima seção.",
        nist_Nice_Roles_Required: "Papéis NIST NICE obrigatórias",
        minimum_Percentage_Of_Nist_Nice_Roles:
          "Porcentagem mínima de Papéis NIST NICE necessárias para se candidatar a este cargo",
        this_Position_Has_No_Specified:
          "Esta posição ainda não possui Certificações especificadas!",
        this_Section_Provides_Information:
          " Esta seção fornece informações sobre as certificações exigidas pela Empresa para se candidatar a este trabalho.",
        this_Position_Has_No_specified_Languages:
          " Esta posição ainda não tem idiomas especificados!",
        provide_Information_About_Your_Languages:
          " Forneça informações sobre seus idiomas.",
        use_The_Text_Box_Below:
          "Use a caixa de texto abaixo para entrar em contato com esta empresa.",
        by_Pressing_Submit_You_Consent:
          "Ao clicar em Enviar, você indica que leu e aceitou os",
        please_Wait: "Por favor, aguarde...",
        jobs_you_Were_Interested_in_Saving:
          " Empregos que você estava interessado em salvar",
        saved_Trainings: "Treinamentos que você estava interessado em salvar",
        you_have_Already_Applied: "Você já se candidatou a esse emprego",
        Your_Professional_Careers: "Tus planes de carreira em cibersecurity",
      },

      settings: {
        update_Email_button: "Atualizar Email",
        cancel_button: "Cancelar",
        change_Email_button: "Mude o Email",
        update_Password_button: "Actualizar Senha",
        reset_Password_button: "Redefinir Sehna",
        eliminate_Account_button: "Eliminar Conta",
        update_privacy: "Uso de dados pessoais",
        privacy_Account_button: "Atualizar a privacidade",
        placeholder_Email_Adress: "Email",
        placeholder_Confirm_Email_Address: "Confirme o Email",
        email_Address: "Email",
        confirm_Email_Address: "Confirme Novo Email",
        new_Email_Address: "Digite Novo Email",
        confirm_Password: "Confirmar Senha",
        please_wait: "Por favor, aguarde...",
        password: "Senha",
        current_Password: "Senha Actual",
        new_Password: "Senha Nova",
        confirm_New_Password: "Confirme a Nova Senha",
        confirm_Account_Elimination: "Confirme a eliminação da conta",
        Share_information_companies:
          "Permitir que a Seccuri, Inc. compartilhe suas informações pessoais com empresas que estejam procurando contratar candidatos como você ou empresas com as quais você tenha atualmente uma relação na Plataforma Seccuri.",
        Data_processing:
          "Permitir que a Seccuri, Inc. realize o processamento de dados estritamente para os fins descritos em sua Declaração de Privacidade.",
        enter_Email_Address: "Digite Email",
        sign_In_Method: "Método de Login",
        password_Must_Be_At_Least:
          " A senha deve ter pelo menos 8 caracteres e conter símbolos",
        you_Are_Deleting_Your_Account: " Você está excluindo sua conta",
        by_Deleting_Your_Account:
          "Ao excluir sua conta, todos os dados e conteúdos associados ao seu perfil serão perdidos.",
        for_Extra_Security:
          "For extra security, this requires you to confirm your email and password.",
        company_Can_See_Your_Profile:
          "A empresa pode ver suas informações de perfil e sua carreira.",
        information: "Informação",
        relate_Your_Company:
          "Empresa atualmente relacionada à sua conta Seccuri",
        jobs_with_Talents:
          "Publicações Actuais de Emprego com Candidatos a Talent",
        no_relate_Company:
          "No momento, você não possui empresas relacionadas à sua conta Seccuri Talent.",
        active_User: "Usuário Ativo",
        relationship_Status: "Status de Relacionamento ",
        ProfileEmployee_Status: "Perfil do funcionário",
        unlink_Account_Button: " Desvincular Empresa",
        Managing_Consent:
          "Gerenciamento de seu consentimento para o uso de dados pessoais",
        Managing_Consent_text1:
          "Na Seccuri, acreditamos que seus dados pessoais são cruciais para nossa missão, e é por isso que garantimos que suas informações sejam bem protegidas e tratadas adequadamente.",
        Managing_Consent_text2:
          "Ao se registrar em nossa Plataforma Seccuri, você confirma seu consentimento para nossa",
        Managing_Consent_text3:
          "No entanto, você tem o direito de retirar esse consentimento a qualquer momento para o uso dos dados pessoais que nos forneceu voluntariamente.",
        Managing_Consent_text4:
          "Se não concordar, você poderá optar por interromper o uso de nossos serviços no Seccuri Plantform eliminando sua conta na seção abaixo.",
        have_read: "Eu li e concordo com os",
        in_wish: "em que vocês concordaram:",
        communication_preferences: "Preferências de comunicação",
        privacity_preferences: "Preferências de Privacidade",
        marketing_communications: "Comunicações de marketing",
        marketing_emails:
          "Concordo em receber e-mails de marketing ocasionais da Seccuri, Inc.",
        update_marketing_button: "Atualizar preferências",
        Top_Talent_Visibility: "Visibilidade Top Talent",
        Top_talent_description:
          "Autorizo que meu perfil apareça como Top Talent para empresas interessadas em encontrar talento na Seccuri.",
      },

      details: {
        talent_Details: "Detalhes do Talent",
        Teacher_details: "Detalhes do professor",
        contact: "Contato",
        basic_Information: "Informação Básica",
        nist_Nice_Roles: "Cibersecurity Roles",
        career_path: " Planos da Carreira",
        education: "Educação",
        experience: "Experiência",
        certifications: "Certificações",
        courses: "Cursos",
        current_Entity: " Entidade Atual",
        current_Position: "Posição Atual",
        current_Location: "Localização Atual",
        nationality: "Nacionalidade",
        seeking_Opportunities: "Buscando Oportunidades",
        yes: "Sim",
        no: "Não ",
        open_Travel: "Aberto para Viajar",
        desired_Employment_Type: "Tipo de Emprego Desejado",
        highest_Level_Education: "Nível mais alto de Educação",
        job_Title: "Cargo do Emprego",
        company: "Empresa",
        not_Specified: "Não especificado",
        industry: "Idústria ",
        start_Date: "Data de Início",
        end_Date: "Data Final",
        currenty_Work_Here: "Atualmente Trabalho Aqui",
        certification_Name: "Nome da certificação",
        issuing_Entity: "Entidade Emissora",
        date_Issued: "Data de emissão",
        this_certification_Expires: "Esta certificação expira",
        this_Certification_Not_Expires: " Esta certificação não expira",
        degree: "Grau",
        school: "Escola",
        field_Study: "Área de estudo",
        graduation_Date: "Data de Graduação",
        not_Graduated: "Não graduado",
        activities: "Atividades",
        name: "Nome",
        number: "Número",
        associated_With: "Associado com",
      },
    },
    companyView: {
      index: {
        Create_administrator_user: "Criar usuário administrador",
        Contacted_by_Company: "Contatado pela empresa",
        Talent_Applied_to_Job: "Talent Applied to Job",
        Conversation_in_Progress: "Conversa em andamento",
        Hired: "Contratado",
        Process_Closed: "Processo encerrado",
        active: "Ativo",
        Not_Acepted_jet: "Ainda não aceito",
        Public_Profile: "Talent público",
        Private_Profile: "Talent privado",
        monthly_button: "Por mês",
        annual_button: "Por ano",
        start_button: "Começar",
        contact_us_button: "Contate-nos",
        subscribe_button: "Se inscrever",
        edit_Profile_button: "Editar perfil",
        back_button: "Voltar",
        submit_button: "Enviar",
        Create: "Criar",
        try_Again_button: "Tente novamente!",
        ok_Got_It_button: "OK, entendi!",
        placeholder_Search: "Procurar",
        company_School_Name: "Nome de Empresa",
        security_clearance_level: "Nível Security Clearance",
        security_clearance_lastGrantedDate:
          "Ultima Security Clearance Concedida",
        contact_Number: "Número de Contato",
        current_Position: "Posição Atual",
        country: "País",
        seeking_Opportunities: "Buscando oportunidades",
        yes: "Sim",
        no: "Não",
        company_Name: "Nome da empresa",
        location: "Localização",
        ceo: "CEO",
        company_Website: "Site da Companhia",
        jobs: "Empregos",
        employees: " Usuários Admin da Empresa",
        cyberTeams: "Cyber Team",
        contacted_Candidates: "Candidatos contatados",
        profile_Completion: "Conclusão do perfil",
        full_Profile: "Conclusão do perfil ",
        overview: "Informações gerais",
        account: "Conta",
        settings: "Configurações",
        Account_Privacy: "Privacidade da conta",
        subscription: "Alterar Assinatura",
        profile_Overview: "Overview do Perfil",
        completed_Sections: "Seções concluídas: ",
        company_Information: "Informações da empresa: ",
        Security_Clearance_required: "Exigência de autorização de segurança",
        company_industry: "Indústria",
        basic_data: "Dados Básicos",
        soft_skill: "Habilidades interpessoais",
        added_Employees: "Funcionários adicionados",
        created_Positions: "Posições Criadas",
        edit_Company_Profile: "Editar perfil da Empresa",
        basic_Information: "Informação Básica",
        company_Details: "Detalhes da Empresa",
        specified_Organization: "Organização Especificada",
        contact_Information: "Informações de Contato",
        we_Need_Your_Attention: "Precisamos de sua atenção!",
        choose_Your_Plan: "Escolha seu Plano",
        company_Profile_Details: "Detalhes do perfil da Empresa",
        please_Make_Sure_To_Complete_Your_Talent:
          "Para melhorar sua experiência na Seccuri e facilitar que as empresas encontrem você, recomendamos preencher seu perfil.",
        you_can_upload:
          "Você pode enviar o PDF do LinkedIn com seu currículo e nossa Seccuri AI o ajudará a preencher as informações.",
        if_You_Need_More_Info_About_Our_Pricing:
          "Se você precisar de mais informações sobre nossos preços, envie um email para",
        Here: "aquí",
        or_send_us_a_message: "ou envie-nos uma mensagem",
        Upgrade_a_Plan: "Actualizar um Plano",
        fill_The_Basic_Data: "Preencha os Dados Básico",
        fill_the_Contact_Information: "Preencha as Informações de Contato",
        clear_filter_button: "Limpar Filtros",
        Filters: "Filtros",
        items_Found: "Itens Encontrados",
        by_Recently_Added: "por Adicionado Recentemente ↓",
        SelectLocation: "Seleccione o Local",
        SelectSalaryRange: "Selecione Faixa Salarial",
        SelectSEducationLevel: "Selecione Níveis de Educação",
        training_Pie_De_Pagina: "Mostrando",
        training_Pie_De_Pagina2: "para",
        training_Pie_De_Pagina3: "de",
        training_Pie_De_Pagina4: "entradas",
        recently_Updated: "Adicionado Recentemente",
        last_Month: "Mês Passado",
        last_Quarter: "Quarto Passado",
        last_Year: "Ano Passado",
        please_Check_Fields_msj: "Verifique os campos vermelhos.",
        have_SomeErrors: "A plataforma está apresentando alguns erros.",
        company_Information_Been_Saved:
          "As informações da sua empresa foram salva!",
        Upgrade_Subscription: "Actualize a Assinatura",
        bill_to: "Email de Cobrança",
        customer_Name: "Nome de Cliente",
        currency: "Moeda",
        subscribed_Product: "Produto Subscrito",
        billing_Method: "Método de Cobrança",
        Annually: "Anualmente",
        Monthly: "Mensualmente",
        USDDollar: "USD - Dólar americano",
        phone: "Telefone",
        talents: "Talent",
        billing_Address: "Informações de Pagamento: ",
        reset_Button: "Reiniciar",
        apply_Button: "Aplicar",
        appliedJobs: "Empregos Aplicados",
        savedTrainings: "Treinamentos Salvos",
        talent_List: "Talent",
        Back_to_Applied: "Voltar para Empregos Aplicados",
        for_Applied_Position_as: "Talent para Cargo como",
        jobs_with_candidates: " Trabalhos com Candidatos",
        change_status: "Alterar Estado",
      },

      ajustes: {
        update_Password_button: "Actualizar Senha",
        reset_Password_button: "Redefinir Senha",
        email_Address: "Email",
        confirm_Password: "Confirmar Senha",
        update_Email: "Actualizar Email",
        sign_In_Method: "Método de Login",
        cancel: "Cancelar",
        change_Email: "Mudar Email",
        password: "Senha",
        current_Password: "Senha Actual",
        new_Password: "Nova Senha",
        confirm_New_Password: "Confirme a Nova Senha",
        eliminate_Account: "Eliminar Conta",
        confirm_Account_Elimination: "Confirme a Eliminação da Conta",
        enter_Email_Address: "Digite Email",
        enter_New_Email_Address: "Digite Novo Email",
        confirm_New_Email_Address: "Confirme Novo Email",
        please_Wait: "Por favor, espere...",
        Password_Must_Be_At_Least_8_Character:
          "A senha deve ter pelo menos 8 caracteres e conter símbolos",
        deseas_Eliminar_Tu_Cuenta: "Você está excluindo sua conta",
        by_Deleting_Your_Account:
          "Ao excluir sua conta, todos os dados e conteúdos associados ao perfil de sua empresa serão perdidos.",
        for_Extra_Security_This_Requires:
          "Para segurança extra, isso requer que você confirme seu e-mail e senha.",
      },

      employees: {
        add_Admin_button: "Adicionar Usuário Admin",
        add_Talent_button: "Adicionar Talent",
        cyberteam: "Seu Seccuri Equipe Cyber",
        delete_button: "Excluir",
        submit_button: "Enviar",
        current_Employees: " Funcionários Atuais",
        current_Admin_Employees: "Usuários Admin Atuais da Empresa ",
        instructions: "Instruções: ",
        In_this_section:
          "Nessa seção, você poderá revisar seu perfil de acordo com as informações fornecidas pelo nomeador e fazer as alterações necessárias.",
        first_Name: "Nome",
        last_Name: "Sobrenome",
        job_Title: "Cargo",
        password: "Senha",
        email: "Email",
        confirm_Password: "Confirme Senha",
        please_wait: "Por favor, espere...",
        add_Company_Admin: "Adicionar usuários administradores da empresa",
        add_Company:
          "Adicionar Talent da Empresa para o seu Seccuri Equipe Cyber!",
        subscription_details: "Detalhes do Plano de Assinatura",
        add_A_Company_User: "Adicionar Usuário da Empresa para o Equipe Cyber",
        add_A_Company_Admin: "Adicionar Usuário Admin da Empresa",
        add_An_Admin_User: "Adicionar um Usuário Admin",
        send_Invitation_Button: "Indicação de professor",
        send_Invitation_ButtonEmployee: "Enviar convite",
        company_Admin_Users: "Usuário Admin da Empresa: ",
        company_Admin_User_You_Will:
          "Um Usuário Admin da Empresa é qualquer colaborador interno que você deseja convidar para a plataforma Seccuri AI para ajudá-lo a realizar tarefas específicas.",
        Once_added_Company_Managers:
          "Depois de adicionado, o Usuário Admin da Empresa poderá editar o perfil da sua empresa, adicionar Talent de Empresa, criar anúncios de emprego e pesquisar novos Talent.",
        company_Users: "Company Talent: ",
        allow_Other_Members_Of_Your_Company:
          "Acompanhe o desenvolvimento da carreira de cybersecurity de seus funcionários atuais, convidando-os a se registrar no Seccuri AI como usuários Talent!",
        ask_Other_Members_Of_Your_Company:
          "Uma vez inscritos, eles poderão se conectar ao perfil da sua empresa e fazer parte do seu Seccuri Equipe Cyber!",
        as_A_Company_Admin_User:
          "Como Admin de Empesa principal, você é responsável por criar todas as contas e senhas dos Usuários Admin da Empresa.",
        Make_sure_to:
          "Certifique-se de compartilhar internamente as senhas designadas com o Usuário Admin da Empresa correspondente.",
        All_fields_are_mandatory: "Todos os campos são obrigatórios",
        your_Company_Wants:
          "Será enviado um convite para adicionar o usuário com o e-mail atribuído para integrar a Equipe Cyber na plataforma. Todos os campos são obrigatórios.",
        are_you_sure:
          "Tem certeza de que deseja remover este Usuário Admin da Empresa? Este usuário não terá mais acesso à conta Seccuri de sua Empresa.",
        delete_employee: "Remover Usuário Admin da Empresa",
        delete: "Excluir",
        user: "Usuário",
        location: "Localização",
        current_Job_Title: "Cargo Atual",
        join_Status: "Join Status",
        talent_profile: "User Profile",
        actions: "Ações",
        pending: "Pendente",
        join_Talent: "Talent Ativo",
        contact_Talent_Button: "Contacto Talent",
        delete_Talent_Button: "Deletar Talent",
        export_Info_to_PDF_Button: "Exportar informações para PDF",
        public: "Públic",
        private: "Privado",
      },

      jobs: {
        Date_by_which_applications:
          "Data em que as inscrições dos candidatos serão aceitas.",
        create_new_pos_button: "Criar um novo emprego",
        save_As_button: "Salvar Como ",
        change_Status_button: "Mudar de Estado",
        Status: "Estado:",
        edit_Job_button: "Editar Emprego",
        show_Profile_button: "Mostrar Perfil",
        delete_button: "Excluir",
        delete_Job: "Excluir Emprego",
        back_button: "Voltar ",
        jobs: "Empregos",
        Post_job: "Postear um Emprego!",
        Job_Board: "Board de Empregos",
        position_Details: "Detalhes da Posição",
        nist_Nice_Roles: "Cybersecurity Roles",
        certifications: "Certificações",
        languages: "Idiomas",
        job_Title: "Cargo",
        company_Name: "Nome da Empresa",
        job_Department: "Departamento de Emprego",
        job_Description: "Descrição de Emprego",
        vacancy_Details: "Detalhes da Vaga",
        salary_Range_Min: "Faixa Salaria Min",
        salary_Range_Max: " Faixa Salaria Max",
        select_Employment_Type: "Selecione o Tipo de Emprego",
        duration: "Duração ",
        requirements: "Requisitos",
        industry: "Indústria",
        level_Of_Education: "Nível de Educação",
        location: "Localização",
        select_A_Country: "Seleccione um Pais...",
        All_Countries: "Todos os países",
        select_a_city: "Selecione uma cidade...",
        nationatily: "Nationalidade",
        expiration_Date: "Data de Validade",
        salary_Range: "Faixa Salarial",
        top: "Top ",
        candidates: "Candidatos",
        details: "Detalhes",
        contact: "Contato",
        email: "Email",
        langauge: "Idioma",
        english: "Inglês",
        change_Job_Status: "Mudar Estado de Emprego",
        published: "Publicados",
        delete_Job_Position: "Excluir Cargo",
        only_required_Company: "Somente se exigido pela Empresa",
        create_New_Job_Position: "Criar um novo emprego",
        basic_Information: "Informação Básica",
        are_You_Ready_To_Start_Finding_Talent:
          "Você está pronto para começar a encontrar novos Talent para sua Empresa?",
        privateJobsforCompany: "private Jobs for Company",
        jobs_Subtitle2: "Mostrando 1 a 10 de 50 entradas",
        edit_Job_Position: "Editar emprego",
        fill_In_Your_Job_Data: "Preencha os dados do seu emprego",
        specialities_And_Tasks: "Especialidades e Tarefas",
        list_Certifications_Required: "Lista de certificações necessárias",
        fill_Native_And_Learned_Languages:
          "Preencher idiomas nativos e aprendidos necessários",
        please_Wait: "Por favor, espere...",
        select_An_Option: "Selecione uma Opção...",
        public: "Recrutamento externo",
        private: "Recrutamento interno",
        units: "Unidades (dias, meses, etc.)",
        open_To_Travel: "Aberto a Viagens",
        include_Job_Functions_And_Responsibilities:
          "(Inclua as funções e responsabilidades do emprego)",
        these_Are_The: "estes são os",
        candidates_With_More_Match:
          "candidatos com maior correspondência para esta posição.",
        Cant_find_any_Top_Talent:
          "Ainda não consegue encontrar nenhum Top Talent? Tente ajustar as informações do seu anúncio de emprego para nos ajudar a ampliar a busca e matching inteligente do candidato Top Talent.",
        the_Percentage_Displayed_For_Each_Talent:
          "A porcentagem exibida para cada talent indica o quanto o candidato corresponde a cada uma das funções do seu anúncio de emprego!",
        the_Current_Status_For_This_Job_Position:
          "O status atual para este cargo é",
        are_You_Sure_You_Want_To_Change:
          ". Tem certeza de que deseja alterar o status dessa posição de trabalho para",
        saved_as_Draft: "Salvo como rascunho",
        are_You_Sure_You_Want_To_Delete:
          "Tem certeza de que deseja excluir o cargo?",
        tell_this_talent:
          "Conte a este Talent por que sua Empresa está interessada em contatá-lo para este trabalho",
        use_the_text:
          "Use a caixa de texto abaixo para entrar em contato com esse candidato. Envie-lhes uma mensagem curta para iniciar o contato!",
        use_the_text_placeholder: "Sua mensagem…",
        contact_to: "Contato",
        the_talent: "o Talent",
        here_You_Can_Send_Message:
          "Use a caixa de texto abaixo para enviar uma mensagem curta para o seu talent em cybersecurity!",
        write_Here_The_Message: "Sua mensasgem…",
        view_applied_Talents: "Exibir Talent que se candidataram",
        Deadline: "Prazo final",
      },

      profile: {
        select_Industry_Placeholder: "Selecione uma Indústria...",
        select_Country_Placeholder: "Selecione um Pais...",
        select_year_Placeholder: "Selecione um Ano...",
        select_Option_Placeholder: "Selecione um Opção...",
        basic_Information: "Informação Básica",
        company_Name: "Nome da Empresa",
        industry: "Indústria",
        location: "Localização",
        description: "Descrição",
        ceo: "CEO",
        year_Of_Fundation: "Ano de Fundação",
        company_Details: " Detalhes da Empresa",
        number_Of_Employees: " Número de Empregados",
        revenue_Range: "Faixa de receita",
        contact_Information: "Informações de contato",
        admin_First_Name: " Nome Admin",
        admin_Last_Name: "Sobrenome Adin",
        admin_Job_Title: "Cargo Admin",
        phone_Number: "Número de telefone",
        Connected_Accounts: "Contas conectadas",
        Connect_authentication_services:
          "Conecte os serviços de autenticação que você precisa usar para acessar sua conta seccuri.",
        Connect: "Conectar",
        Desconnect: "Desconectar",

        phone_Number_Example: "Exemplo de número de telefone",
        website: "Website",
        secondary_Email: "Email Secundário",
        country_Code: "Código do País",
        this_Email_Will:
          "Este email permitirá que você recupere o email principal caso o esqueça.",
        upload_Company_Logo: "Carregar logotipo da empresa",
        allowed_File_Types: "Tipos de arquivo permitidos: png, jpg, jpeg.",
        select_An_Industry: "Selecione uma Indústria...",
        select_A_Country: "Selecione um Pais...",
        select_a_city: "Selecione uma cidade...",
        select_An_Option: "Select uma Opção...",
        give_Us_A_Short_Description:
          "Dê-nos uma breve descrição da Empresa (declaração de missão, história, estrutura de negócios, etc.)",
      },

      roles: {
        delete_button: "Excluir",
        save_button: "Salvar",
        placeholder_Search_Tasks: "Procurar as Tarefas",
        nist_Nice_Roles: "Cybersecurity Roles",
        options: "opções.",
        next_Step: "Próxima Etapa",
        confirm_Changes: "Confirmar alterações",
        certifications: "Certificações",
        add_New: "Adicionar Novo",
        Add_new_certification: "Adicionar nova certificação",
        certification: "Certificação",
        name: "Nome",
        languages: "Idiomas",
        add_New_Language: "Adicionar Novo Idioma",
        please_Define_The_Roles_Associated:
          "Por favor, defina as funções associadas ao seu cargo!",
        in_This_Section_Which_Consists:
          "Nesta seção, que consiste em três etapas, você selecionará as Papéis NIST NICE do emprego.",
        please_start_By_Selecting_At_Least:
          "Comece selecionando pelo menos uma área de especialização relacionada a este cargo",
        by_Clicking_Here: "clicando aqui",
        improve_Your_Roles: "Melhore suas funções!",
        you_can_Improve_The_Progress_Of_Your_Roles:
          "ocê pode melhorar o progresso de suas Papéis NIST NICE a qualquer momento, atualizando suas áreas, especialidades e tarefas no ",
        Edit_Profile_section: "Editar seção de perfil.",
        select_Your_Areas: "1. Selecione as Áreas em que você trabalhou",
        your_Nist_Nice_Roles: "Suas Papéis NIST NICE",
        the_Job_Position_Roles_And_Their_Progress:
          "As funções do cargo e seu progresso são baseados em suas especialidades e tarefas selecionadas",
        edit_Your_Roles: "Edite suas funções",
        select_Your_Specialities: "2. Selecione suas especialidades",
        select_Your_Tasks: "3. Selecione suas tarefas",
        please_Select_At_Least_One_Area:
          "Por favor, selecione pelo menos uma área!",
        before_You_Proceed_With_Step:
          "Antes de prosseguir com a Etapa 2, selecione pelo menos uma área de expertise...",
        please_Select_At_Least_One_Speciality:
          "Por favor, selecione pelo menos uma especialidade!",
        to_Continue_With_The_Next_Step:
          "Para continuar com a próxima etapa, selecione pelo menos uma especialidade para o emprego.",
        please_Select_At_Least_One_Task:
          "Por favor, selecione pelo menos uma tarefa!",
        to_Continue_With_The_Next_Step_Is_Important:
          "Para continuar com a próxima etapa é importante que você selecione pelo menos uma tarefa.",
        choose_Your_Areas: "Escolha suas áreas",
        select_The_Main_Areas_That_Make:
          "Selecione as principais áreas que compõem o seu cargo. Escolha acima",
        choose_Your_Specialities: "Escolha suas especialidades",
        select_The_Main_Specialities_Required:
          "Selecione as principais especialidades necessárias para o cargo. Escolha até",
        choose_Your_Tasks: "Escolha suas tarefas",
        select_All_Of_The_Tasks_That_Will_Need:
          "Selecione todas as tarefas que precisam ser executadas para o trabalho",
        show_Only_Selected: "Mostrar apenas selecionados",
        You_can_use_the_Search_bar:
          "Você pode usar a barra de pesquisa para procurar palavras-chave relacionadas às suas tarefas.",
        provide_Information_About_Your_Certifications:
          "Forneça informações sobre suas certificações.",
        provide_Information_About_The_Languages:
          "Esta seção fornece informações sobre os idiomas exigidos pela Empresa para se candidatar a esta vaga.",
      },

      language: {
        delete_button: "Excluir",
        save_button: "Salvar",
        language: "Idioma",
        begginer: "Inciante",
        intermediate: "Intermediário",
        proficient: "Proficiente",
        native_Biligual: "Nativo/Biligual",
        select_An_Option: "Selecione uma opção...",
        select_A_Level: "Selecione um nível",
        knowledge_Of_Basic_Vocabulary_Words:
          "Conhecimento de palavras de vocabulário básico, fala frases simples, habilidades elementares de leitura e escrita.",
        can_Handle_Intermediate_Communication:
          "Pode lidar com comunicação intermediária, leitura e escrita.",
        a_Well_Advanced_Use_Of_The_Language:
          "Um uso bem avançado do idioma, usando fala, leitura e escrita fluidas.",
        more_Than_Fluent_In_The_Language: "Mais do que fluente no idioma.",
      },

      searchTalent: {
        find_The_Best_Cybersecurity_Talent:
          "Encontre os melhores Talent de cybersecurity que sua empresa precisa",
      },
    },
    loginView: {
      index: {
        continue_button: "Continuar",
        register_button: "Registrar",
        email: "Email",
        password: "Senha",
        forgot_Password: "Esqueceu a Senha?",
        company_Account: "Conta de Empresa",
        talent_Account: "Conta de Talent",
        first_Name: "Nome",
        last_Name: "Sobrenome",
        admin_First_Name: "Nome de Admin",
        admin_Last_Name: "Sobrenome de Admin",
        admin_Email: "Email Admin",
        phone: "Telefone",
        country: "País",
        confirm_Password: "Confirmar Senha",
        login_Tittle1: "Login a Seccuri",
        login_Tittle2: "Escolha o Tipo de onta",
        login_Titlle3: "Criar uma Conta de Talent",
        login_Tittle4: "Criar uma Conta de Empresa",
        login_Subtittle1: "Novo aqui?",
        login_subtittle2: "Criar uma Conta",
        login_Subtittle3: "Por favor, espere...",
        login_subtittle4: "Procurando emprego",
        login_Subtittle5: "Buscando oportunidades de treinamento",
        login_Subtittle6: "Procurando contratar Talent",
        login_Subtittle7:
          "Buscando oportunidades de treinamento para funcionários",
        login_Subtittle8: "Já tem uma conta?",
        login_Subtittle9: "Faça login aqui",
        login_Subtittle10: "Selecione um país...",
        login_subtittle11:
          "Use 12 ou mais caracteres com uma mistura de letras (maiúsculas e minúsculas), números e símbolos.",
        login_Subtittle12: "Eu concordo com o",
        login_Subtittle13: "Termos e Condições.",
      },

      forgotPassword: {
        reset_Password_button: "Redefinir Senha",
        cancel_button: "Cancelar",
        continue_button: "Continuar",
        forgotPassword: "Esqueci a Senha",
        email: "Email",
        OldEmail: "Email Antigo",
        newEmail: " Email Novo",
        please_Wait: "Por favor, espere...",
        reset_Your_Password: "Redefinir sua senha",
        reset_password_acept_invitation:
          "Redefina sua Senha e Aceite o convite",
        reset_Your_Email: "Mude seu Email",
        token: "Token",
        password: "Senha",
        confirm_Password: "Confirmar Senha",
        forgot_Subtitle1: "Digite seu email para redefinir sua senha.",
        forgot_Subtitle2:
          "Use 12 ou mais caracteres com uma mistura de letras, números e símbolos.",
        forgot_Subtitle3:
          "Digite e confirme sua nova senha com o código temporal recebido por Email.",
        forgot_Subtitle4:
          "Digite e confirme seu novo e-mail com o código temporal recebido por Email.",
        To_login_to_your_new_seccuri:
          "Para acessar sua nova conta Seccuri e concordar em fazer parte da equipe de cybersecurity de sua empresa, siga as etapas abaixo e selecione continuar.",
        You_can_unlink_the_company:
          "Você pode desvincular a empresa a qualquer momento na opção empresa.",
        Accept_award_new_User:
          "Para acceder a su nueva cuenta Seccuri y aceptar la participacion en los Seccuri Awards, siga los pasos que se indican a continuación",
        Accept_award_new_User_not_Accept:
          "Si por algún motivo no desea aceptar la nominacion pero quiere ser parte de la plataforma, puede registrarse desde nuestra pagina pricipal www.Seccuri.com",
      },
    },
    profileView: {
      index: {
        next_Step_butoon: "Próximo Passo",
        confirm_Changes_button: "Confirmar alterações",
        back_button: "Voltar",
        submit_button: "Enviar",
        save_Continue_button: "Salvar y Continuar",
        continue_button: "Continuar",
        go_Education_button: "Ir para Educação",
        go_Experiences_button: "Ir para Experiências",
        go_Certifications_button: "Ir para Certificações",
        go_Courses_button: "Ir para Cursos",
        go_Languages_button: "Ir para Idiomas",
        go_References_button: "Ir para Referências",
        next_Section_button: "Próxima Seção",
        search_Tasks_Placeholder: "Pesquisar Tarefas",
        edit_Profile: "Editar perfil",
        personal_Details: "Detalhes Perssoais",
        nist_Nice_Roles: " Cybersecurity Roles",
        experience: "Experience",
        education: "Educação",
        certifications: "Certificações",
        languages: "Idiomas",
        references: "Referências",
        courses: "Cursos",
        basic_Information: "Informação Básica",
        first_Name: "Nome",
        middle_Name: "Nome do Meio",
        when_was_it_last: "Quando foi concedido pela última vez?",
        security_clearance_renewal: "Renovação Security Clearance",
        last_Name: "Sobrenome",
        gender: "Gênero",
        nationality: "Nacionalidade",
        location: "Localização",
        contact_Information: "Informação de Contato",
        phone_Number: "Número de telefone",
        website: "Website",
        birthDate: "Data de Nascimento",
        secondary_Email: "Email Secundário",
        this_Email_Will:
          "Este Email permitirá que você recupere o Email principal caso o esqueça.",
        education_Information: "Informação Actual",
        school_Name: "Nome de Escola",
        degree_Title: "Título do último grau ",
        professional_Information: "Novas Oportunidades",
        please_Take_this_Test:
          "Faça seu teste de personalidade e preencha as informações solicitadas abaixo.",
        personality: "Personalidade",
        select_personalityType: "Seleciona tu resultado",
        have_Personality_Test: "Você já fez seu teste de personalidade?",
        personality_Test_result: "Resultado do teste",
        personality_Test_Up: "Faça seu teste de personalidade!",
        current_Job_Title: "Cargo Actual",
        desired_Employment_Type: "Tipo de Emprego Desejado",
        company_Name: "Nome da Empresa",
        select_your_Areas: "1. Selecione suas áreas",
        select_your_Specialities: "2. Selecione suas especialidades",
        select_your_Tasks: "3. Selecione suas tarejas",
        options: "opções.",
        please_Wait: "Por favor, espere...",
        upload_a_Photo: "Carregar uma Foto",
        profile_Tittle2: "Por favor, selecione pelo menos uma área!",
        profile_Subtittle1: "Preencha seus dados pessoais",
        profile_Subtittle2: "Especialidades e Tarefas",
        profile_Subtittle3: "Preencha seu histórico profissional ",
        profile_Subtittle4: "Liste seu histórico educacional",
        profile_Subtittle5: "Liste suas certificações",
        profile_Subtittle6: "Liste seus idiomas nativos e aprendidos",
        profile_Subtittle7: "Liste suas referências externas",
        profile_Subtittle8: "Leste seu histórico de cursos ",
        allowed_File_Types: "Tipos de arquivo permitidos: png, jpg, jpeg.",
        gender_Profile: "Selecione um Gênero...",
        select_security_level: "Selecione um Security Level...",
        select_a_Country: "Selecione um País...",
        select_a_city: "Selecione uma cidade...",
        select_a_Department: "Selecione uma departamento...",
        select_a_Region: "Selecione uma região...",
        profile_Subtittle12: "Selecione o nível mais alto de educação",
        profile_Subtittle13: "Selecione uma Opção...",
        profile_Subtittle14: "Aberto para Viajar",
        profile_Subtittle15: "Procurando Oportunidades?",
        profile_Subtittle16:
          "Para continuar com a próxima etapa é importante que você selecione pelo menos uma área.",
        profile_Subtittle17: "Escolha suas áreas",
        profile_Subtittle18:
          "Selecione as principais áreas em que você já trabalhou. Escolha até",
        profile_subtittle19: "opções.",
        profile_Subtitle20:
          "Por favor, selecione pelo menos uma especialidade!",
        profile_Subtitle21:
          "Para continuar com a próxima etapa é importante que você selecione pelo menos uma especialidade.",
        profile_Subtitle22: "Por favor, selecione pelo menos uma tarefa!",
        profile_Subtitle23:
          "Para continuar com a próxima etapa é importante que você selecione pelo menos uma tarefa.",
        profile_Subtitle24: "Escolha suas especialidades",
        profile_Subtitle25:
          "Selecione as principais especialidades em que já trabalhou. Escolha até",
        profile_Subtitle26: "Escolha suas tarefas",
        profile_Subtitleskill: "Escolha suas habilidades",
        profile_Subtitle26knowledge: "Escolha seu conhecimento",
        profile_Subtitle27: "Selecione todas as tarefas que você executou.",
        profile_Subtitle28: "Mostrar apenas os selecionados",
        new_in_cybersecurity: "Sou novo em cybersecurity",
        hi_new_talent_cybersecurity: " Olá, entusiasta de cybersecurity!",
        hi_talent_cybersecurity: "Bem-vindo de volta, Talent em cybersecurity!",
        experience_in_cybersecurity: "Tenho experiência em cybersecurity",
        in_trainings:
          "Convidamo-lo a navegar na nossa secção de Formação, onde poderá mergulhar em diferentes temas através de cursos e certificações, que lhe permitirão iniciar a sua carreira na cybersecurity.",
        at_the_moment:
          "Depois de obter alguma experiência em Cybersecurity, vá para o ",
        para_que:
          "opção para que possamos avaliar suas habilidades atuais de acordo com a estrutura NIST NICE. Isso nos permitirá acompanhar seu progresso e garantir que seu perfil esteja mais completo ao se candidatar a novas oportunidades de emprego (se desejar).",
        select_begginer:
          "Ao selecionar esta opção, compartilharemos com você todas as informações sobre os cursos e treinamentos que você pode fazer para iniciar sua carreira profissional em cybersecurity.",
        nist_nice: "O que é o NIST NICE Framework?",
        here: "aqui.",
        start_your_career:
          "Sente-se pronto para avaliar seu Perfil atual de cibersecurity?",
        confirm: "Confirmar Seleção",
        nist_nice_framework:
          "Na Seccuri utilizamos diversos mecanismos para avaliar sua carreira em cybersecurity; um deles é através do framework NIST NICE. Se quiser saber mais sobre este método clique ",
        your_nist_nice:
          "Para conhecer e modificar suas Papéis NIST NICE selecione a opção",
        Seccuri_brings:
          "A Seccuri traz para você o Seccuri Awards, uma competição emocionante na qual buscamos homenagear os melhores educadores de cybersecurity do mundo. Participe agora nomeando seu professor de Cybersecurity favorito e comece a votar!",
        The_teacher:
          "O professor mais votado ao final da competição será nomeado 'Professor Estrela Seccuri' do Colombia 2024 e receberá prêmios incríveis de nossas empresas parceiras!",
        The_teacher_chile:
          "O professor mais votado ao final da competição será nomeado 'Professor Estrela Seccuri' do Chile 2025 e receberá prêmios incríveis de nossas empresas parceiras!",
        Criteriatoparticipate:
          "Critérios para participar como professor nomeado:",
        Be_teacher_affiliated:
          "Ser professor vinculado a uma instituição de ensino.",
        Teach_classes_in_Chile: "Ministrar aulas no Chile.",
        Have_at_least:
          "Ter pelo menos 1 ano de experiência ensinando cibersegurança.",
        Teach_a_minimum: "Ensinar no mínimo 20 alunos nesta área.",
        Provide_proof: "Comprovar experiência em cibersegurança.",
        Thank_you_for_your_interest:
          "Obrigado pelo seu interesse nos Prêmios Seccuri! Ambas as nomeações e votações estão encerradas.",
        Both_nominations:
          "Fique ligado em nossas redes sociais para saber quem é o vencedor do título de 'Professor Estrela Seccuri' e prêmios incríveis de nossas empresas patrocinadoras.",
        Mexico: "Seccuri Awards: México",
        Colombia: "Seccuri Awards: Colombia",
        Chile: "Seccuri Awards: Chile",
        Search_for_teacher: "Search for teacher by name",
        Nominate_Professor: "Indicação de professor",
        Courses_taught_in_cybersecurity:
          "Cursos de Cybersecurity e atividades acadêmicas",
        Cant_find_your_cybersecurity:
          "Não consegue encontrar seu professor Cyber em nossa lista? Não se preocupe, você pode indicá-lo e votar nele depois de avaliarmos seu perfil.",
        Share_Profile: "Compartilhar Perfil",
        View_Profile: "Ver perfil",
        you_have_voted: "¡Obrigado por seu voto!",
        vote: "voto",
        Knowledge_Area: "Área de conhecimento",
        Educational_Institution: "Instituição educacional",
        Nominate_your_teacher: "Indique seu professor para o Prêmio Seccuri",
        Please_fill_out:
          "Preencha o formulário com as informações solicitadas para indicar seu professor de Cybersecurity favorito e enviar a ele um convite para a premiação.",
        self_nominating:
          "Caso esteja se autonomeando, insira os campos para visualizar automaticamente seu cartão de concorrente, votar e compartilhar com seus colegas e alunos cibernéticos!",
        All_fields_marked: " Todos os campos marcados com um asterisco ",
        are_mandatory: " são obrigatórios",
        Describes_the_courses: "",
        comparte_tu_nominacion: "Comparte tu nominacion",
        Copia_pega:
          "Compartilhe o seguinte texto nas suas redes sociais para anunciar sua nomeação e permitir que seus alunos e colegas votem em você.",
        Orgulloso:
          "Tenho o prazer de anunciar que fui indicado para os Prêmios Seccuri, um evento prestigioso organizado por Seccuri, a plataforma global de habilidades e talentos em cibersegurança, junto com grandes empresas internacionais. Este evento tem como objetivo reconhecer os melhores professores de cibersegurança do mundo.",
        ComoVotar_step1: "1. Registre-se no Seccuri como usuário 'Talent' em ",
        ComoVotar_step2: "2. Ative sua conta e faça login na plataforma.",
        ComoVotar_step3:
          "3. Acesse o módulo Seccuri Awards - Chile Awards e vote no meu perfil.",
        Te_invito:
          "Eu te convido a me apoiar votando em mim para ganhar o título de 'Seccuri Star Professor - Chile 2025'. Para isso, basta seguir estes passos:",
        tu_apoyo_significa:
          "Seu apoio significa muito para mim. Conto com você!",
        Ciberseguridad:
          "#Ciberseguridad #Tecnología #TI #Reconocimiento #Educación #Chile #Docentes",
        Share_on_LinkedIn: "Compartilhar no LinkedIn",
        Copy_text: "Copiar texto",

        premiosseccuri: "Seccuri",
        eventoliderado: ", un evento liderado por",
        seccuri: "Seccuri",
        plataforma_global:
          ", la plataforma global de talent y habilidades en ciberseguridad, e importantes empresas globales para reconocer a los mejores docentes de ciberseguridad en el mundo",
        apoyame: "Apoyáme votando por mí para ganar el título de .",
        seccuriawards: "“Seccuri Star Professor - México 2023”",
        solo_debes:
          "Sólo debes registrarte como Talent en el enlace que incluyo abajo, activar tu cuenta e iniciar sesión en la plataforma, y en la sección de Seccuri Awards, votar por mi perfil.",
        Apoyame_votando: "Apóyame votando por mí para ganar el título de ",
        seccuri_star: "“Seccuri Star Professor - Colombia 2024”.",
        solo_debes_registrarte:
          "Tudo que você precisa fazer é se registrar como Talent no link abaixo, fazer login na plataforma Seccuri e acessar a seção Seccuri Awards para votar no meu perfil.",
        hatstag:
          "#Cibersegurança #Tecnologia #TI #Reconhecimento #Educação #Colômbia #Ciber",
        link_seccuri: "Link para Seccuri: ",
        cuento_con_tu_voto: "Conto com seu voto!",
        seccuri_career_path: "Planos de Carreira em Cybersecurity",
        Seccuri_Career_Paths: "Planos de Carreira Seccuri: ",
        These_are:
          "Estes são os planos de carreira exclusivamente recomendados pela Seccuri, baseados em análises de mercado.",
        public_Career_Paths: "Planos de Carreira Públicos: ",
        explore_career:
          "Explore os planos de carreira criados e compartilhados abertamente por outras empresas usando a plataforma Seccuri, servindo como referência para o desenvolvimento das suas equipes de cybersecurity.",

        Develop_and_strengthen:
          "Desenvolva e fortaleça as equipes de cybersecurity da sua empresa com uma estratégia de desenvolvimento profissional focada na retenção de Talent e na proteção aprimorada.",
        Explore_relevant_cybersecurity:
          "Primeiro, explore os diferentes planos de carreira em cybersecurity, cada um equipado com os treinamentos, certificações e conhecimentos necessários para avançar em todos os níveis da carreira.",
        Feel_free_to_save_and_duplicate:
          "Você pode duplicar os planos de carreira para personalizá-los de acordo com as necessidades de treinamento da sua empresa. Eles serão automaticamente armazenados no módulo Planos de Carreira da Empresa para facilitar o acesso e uso. Em seguida, atribua esses planos de carreira aos membros da sua Equipe Cyber para gerenciar e monitorar o seu crescimento profissional.",
        seccuri_career_Path_this_module:
          "Este módulo foi desenvolvido com base na análise de marketing da equipe Seccuri para oferecer as carreiras de cybersecurity disponíveis no mercad.",
        seccuri_career_Path_each_career:
          "Cada Plano de Carreira fornece o progresso, o conhecimento e os treinamentos detalhados de que você precisa para continuar a melhorar seu nível. Seus níveis atuais serão atualizados sempre que você terminar um treinamento ou complementar a seção NIST NICE do seu perfil.",
        seccuri_career_Path_being_updated:
          "Este módulo está sendo constantemente atualizado com mais planos de carreira, treinamentos e recursos. ",
        Develop_and_strengthen_company:
          "Desenvolva e fortaleça as habilidades da sua equipe de Cybersecurity com uma estratégia de desenvolvimento profissional para reter Talent e proteger melhor a sua organização",
        Explore_relevant_cybersecurity_company:
          "Explora planes de carrera relevantes en ciberseguridad, con entrenamientos y certificaciones recomendadas para adquirir habilidades en todos los niveles.",
        Feel_free_to_save_and_duplicate_company:
          "Atribua esses planos de carreira à sua equipe de Cybersecurity para gerenciar seu crescimento profissional, alinhado com os objetivos da empresa. ",
        seccuri_career_Path_each_career_company:
          "Atribua esses planos de carreira à sua equipe de Cybersecurity para gerenciar seu crescimento profissional, alinhado com os objetivos da empresa. ",
        seccuri_career_Path_being_updated_company:
          "Este módulo se actualiza constantemente con más planes de carrera, entrenamientos y funcionalidades.",
        have_Security_Clearance: "Tenho Security Clearance",
        security_Clearance_required: "Security Clearance Necessária",
        polygraph_Performed: " Polígrafo ealizado",
        Make_Career_Path_Public: "Tornar público o plano de carreirac",
        Make_Cyberteam_Public: "Tornar o funcionário público",
        security_level: "Nível de Segurança",
        career_Progress: "Progresso na carreira: ",
        view_Button: "Visualizar",
        customize: "Personalizar",
        description: "Descrição",
        current_Career_Path_Progress: "Seu Progresso Atual na Carreira",
        entry_Level: "Nível Iniciante",
        mid_Career: "Meio da Carreira ",
        Professional: "Profissional",
        for: "Para",
        career_Path_Current_Level: " plano de carreira, seu nível atual é ",
        progress_Bar_Above_Serves_Tool:
          "A barra de progresso acima serve como uma ferramenta para ajudá-lo a rastrear seus avanços na carreira de cybersecurity selecionada. O cálculo inicial vem da análise NIST NICE realizada em sua seção de perfil, com base em seus KSAs atuais (conhecimento, habilidades, habilidades) e Tarefas pré-formadas..",
        if_You_Have_Experience_Cybersecurity:
          "Se você tem experiência em Cybersecurity e ainda não concluiu a análise NIST NICE, volte para a seção Editar perfil. Caso você seja novo no Cyber (Entusiasta da cybersecurity), sinta-se à vontade para iniciar alguns dos treinamentos listados abaixo!",
        continue_Growing_Every_Time_Complete_Training:
          "Essa barra continuará crescendo toda vez que você concluir um treinamento que o certifique em novas habilidades necessárias para avançar na carreira selecionada.",
        browse_Suggested_Trainings_Below:
          "Navegue pelos treinamentos sugeridos abaixo para começar a se aprimorar e se tornar um dos melhores especialistas em cybersecurity em seu campo!.",
        trainings_For_This_Career: "Treinamentos para esta carreira",
        trainings_For_begginer: "Treinamentos para iniciantes",
        back_All_Career_Paths: "Voltar para Todos os Planos de Carreira",
        view_All_Trainings: "Ver Todos os Treinamentos",
        career_Path: "Planos de Carreira em Cybersecurity",
        overview: "Overview",
        account: "Conta",
        settings: "Configurações",
        hey_talent: "Ei, Talent! Qual é o seu status atual de cibersecurity?",
        back_how_to_start: "Voltar",
        jobs_For_This_Career: "Empregos para iniciantes",
        view_All_jobs: "Ver todos os empregos",
        view_All_blogs: "Ver todos os blogs",
        blogs_For_This_Career: "Informações básicas para você",
      },
      personalities: {
        Inspector:
          "À primeira vista, os ISTJs são intimidadores. Eles parecem sérios, formais e adequados. Eles também amam as tradições e os valores da velha escola que defendem a paciência, o trabalho duro, a honra e a responsabilidade social e cultural. Eles são reservados, calmos, quietos, e ereto. Esses traços resultam da combinação de I, S, T e J, um tipo de personalidade que muitas vezes é mal compreendido.",
        Counselor:
          "Os INFJs são visionários e idealistas que exalam imaginação criativa e ideias brilhantes. Eles têm uma maneira diferente e geralmente mais profunda de ver o mundo. Eles têm substância e profundidade na maneira como pensam, nunca pegando nada no nível superficial ou aceitando as coisas do jeito que são. Outros às vezes podem percebê-las como estranhas ou divertidas por causa de suas diferentes perspectivas de vida.",
        Mastermind:
          "INTJs, como introvertidos, são quietos, reservados e confortáveis estando sozinhos. Eles geralmente são autossuficientes e preferem trabalhar sozinhos do que em grupo. Socializar drena a energia de um introvertido, fazendo com que eles precisem recarregar. INTJs estão interessados em ideias e teorias. Ao observar o mundo estão sempre questionando por que as coisas acontecem do jeito que acontecem. Eles se destacam no desenvolvimento de planos e estratégias e não gostam de incertezas.",
        Giver:
          "Os ENFJs são indivíduos focados nas pessoas. Eles são extrovertidos, idealistas, carismáticos, francos, altamente éticos e éticos, e geralmente sabem como se conectar com os outros, independentemente de sua origem ou personalidade. Confiando principalmente na intuição e nos sentimentos, eles tendem a viver em sua imaginação e não no mundo real. Em vez de se concentrar em viver no “agora” e no que está acontecendo atualmente, os ENFJs tendem a se concentrar no abstrato e no que poderia acontecer no futuro.",
        Craftsman:
          "ISTPs são pessoas misteriosas que geralmente são muito racionais e lógicas, mas também bastante espontâneas e entusiasmadas. Seus traços de personalidade são menos facilmente reconhecíveis do que os de outros tipos, e mesmo as pessoas que os conhecem bem nem sempre podem antecipar suas reações. No fundo, , ISTPs são indivíduos espontâneos e imprevisíveis, mas eles escondem essas características do mundo exterior, muitas vezes com muito sucesso.",
        Provider:
          "ESFJs são os extrovertidos estereotipados. Eles são borboletas sociais, e sua necessidade de interagir com os outros e fazer as pessoas felizes geralmente acaba tornando-os populares. O ESFJ geralmente tende a ser o líder de torcida ou herói do esporte no ensino médio e na faculdade. Mais tarde, na vida, eles continuam a se divertir no centro das atenções e estão focados principalmente na organização de eventos sociais para suas famílias, amigos e comunidades. ESFJ é um tipo de personalidade comum e apreciado por muitas pessoas.",
        Idealist:
          "INFPs, como a maioria dos introvertidos, são quietos e reservados. Eles preferem não falar sobre si mesmos, especialmente no primeiro encontro com uma nova pessoa. Eles gostam de passar o tempo sozinhos em lugares tranquilos onde possam entender o que está acontecendo ao seu redor. Adoram analisar signos e símbolos, e os consideram como metáforas que possuem significados mais profundos relacionados à vida. Perdem-se em sua imaginação e devaneios, sempre afogados nas profundezas de seus pensamentos, fantasias e ideias.",
        Performer:
          "ESFPs têm uma personalidade Extrovertida, Observadora, Sentidora e Perceptiva, e são comumente vistos como Artistas. Nascidos para estar na frente dos outros e para capturar o palco, os ESFPs adoram os holofotes. ESFPs são exploradores atenciosos que adoram aprender e compartilhar o que aprendem com os outros. ESFPs são pessoas sociáveis com fortes habilidades interpessoais. Eles são animados e divertidos e gostam de ser o centro das atenções. Eles são calorosos, generosos e amigáveis, simpáticos e preocupados com o bem-estar de outras pessoas.",
        Champion:
          "ENFPs têm uma personalidade extrovertida, intuitiva, sentimental e perceptiva. Esse tipo de personalidade é altamente individualista e os Campeões se esforçam para criar seus próprios métodos, aparência, ações, hábitos e ideias - eles não gostam de pessoas padronizadas e odeiam quando são forçados para viver dentro de uma caixa. Eles gostam de estar perto de outras pessoas e têm uma forte natureza intuitiva quando se trata de si mesmos e dos outros. Eles operam a partir de seus sentimentos na maior parte do tempo e são altamente perspicazes e atenciosos.",
        Doer: "ESTPs têm uma personalidade extrovertida, sensível, pensante e perceptiva. ESTPs são regidos pela necessidade de interação social, sentimentos e emoções, processos lógicos e raciocínio, juntamente com a necessidade de liberdade. Teoria e resumos não mantêm ESTP's interessados por muito tempo. Os ESTPs saltam antes de olhar, corrigindo seus erros à medida que avançam, em vez de ficarem ociosos ou preparando planos de contingência.",
        Supervisor:
          "ESTJs são organizados, honestos, dedicados, dignos, tradicionais e acreditam muito em fazer o que acreditam ser certo e socialmente aceitável. Embora os caminhos para o bom e o certo sejam difíceis, eles ficam felizes em assumir seu lugar como os líderes do bando. Eles são o epítome da boa cidadania. As pessoas procuram os ESTJs em busca de orientação e conselho, e os ESTJs estão sempre felizes por serem procurados para obter ajuda.",
        Commander:
          "O modo de vida primário de um ENTJ se concentra em aspectos externos e todas as coisas são tratadas racional e logicamente. Seu modo de operação secundário é interno, onde a intuição e o raciocínio entram em vigor. ENTJs são líderes natos entre os 16 tipos de personalidade e gostam de estar em cobram. Eles vivem em um mundo de possibilidades e muitas vezes veem desafios e obstáculos como grandes oportunidades para se impulsionarem. Eles parecem ter um dom natural para liderança, tomar decisões e considerar opções e ideias com rapidez, mas com cuidado. Eles são assumir o controle ” pessoas que não gostam de ficar paradas.",
        Thinker:
          "INTPs são bem conhecidos por suas teorias brilhantes e lógica implacável, o que faz sentido, já que eles são indiscutivelmente os mais lógicos de todos os tipos de personalidade. Eles adoram padrões, têm um olho aguçado para detectar discrepâncias e uma boa capacidade de ler pessoas, o que torna uma má ideia mentir para um INTP. Pessoas com esse tipo de personalidade não estão interessadas em atividades e manutenção práticas do dia-a-dia, mas quando encontram um ambiente onde seu gênio criativo e potencial podem ser expressos, não há limite para o tempo e a energia que os INTPs gastarão no desenvolvimento de uma solução perspicaz e imparcial.",
        Nurturer:
          "Os ISFJs são filantropos e estão sempre prontos para retribuir e retribuir a generosidade com ainda mais generosidade. As pessoas e coisas em que acreditam serão mantidas e apoiadas com entusiasmo e altruísmo. Os ISFJs são calorosos e de bom coração. Eles valorizam a harmonia e a cooperação , e provavelmente são muito sensíveis aos sentimentos de outras pessoas. As pessoas valorizam o ISFJ por sua consideração e consciência, e sua capacidade de trazer o melhor para os outros.",
        Visionary:
          "Aqueles com a personalidade ENTP são alguns dos mais raros do mundo, o que é completamente compreensível. Embora sejam extrovertidos, eles não gostam de conversa fiada e podem não prosperar em muitas situações sociais, especialmente aquelas que envolvem pessoas muito diferentes. do ENTP. Os ENTPs são inteligentes e experientes, precisam ser constantemente estimulados mentalmente. Eles têm a capacidade de discutir teorias e fatos em detalhes extensos. Eles são lógicos, racionais e objetivos em sua abordagem de informações e argumentos.",
        Composer:
          "Os ISFPs são introvertidos que não parecem introvertidos. É porque, mesmo que tenham dificuldades em se conectar com outras pessoas no início, eles se tornam calorosos, acessíveis e amigáveis eventualmente. Eles são divertidos e muito espontâneos, o que os torna os amigo perfeito para acompanhar qualquer atividade, independentemente se planejada ou não planejada. Os ISFPs querem viver sua vida ao máximo e abraçar o presente, então eles se certificam de que estão sempre fora para explorar coisas novas e descobrir novas experiências. Estás na experiência que eles encontram sabedoria, então eles veem mais valor em conhecer novas pessoas do que outros introvertidos.",
        default:
          "Uma breve descrição do resultado do seu teste de personalidade aparecerá aqui depois que você fizer o teste e incluir o resultado.",
        InspectorISTJ: "Inspector - ISTJ",
        CounselorINFJ: "Conselheiro - INFJ",
        MastermindINTJ: "Mentalidade - INTJ",
        GiverENFJ: "Doador - ENFJ",
        CraftsmanISTP: "Artesão - ISTP",
        ProviderESFJ: "Prestador - ESFJ",
        IdealistINFP: "Idealista - INFP",
        PerformerESFP: "Performer - ESFP",
        ChampionENFP: "Campeão - ENFP",
        DoerESTP: "Fazedor - ESTP",
        SupervisorESTJ: "Supervisor - ESTJ",
        CommanderENTJ: "Comandante - ENTJ",
        ThinkerINTP: "Pensador - INTP",
        NurturerISFJ: "Nutridor - ISFJ",
        VisionaryENTP: "Visionário - ENTP",
        ComposerISFP: "Compositor - ISFP",
      },

      workExperince: {
        add_Job_button: "Adicionar nova experiência de trabalho",
        delete_button: "Excluir",
        save_button: "Salvar",
        employment_History: "Histórico do Emprego",
        employment: "Emprego",
        job_Title: "Cargo",
        company: "Empresa",
        start_Date: "Data de Início",
        end_Date: "Data Final",
        employment_Type: "Timpo de Emprego",
        industry: "Indústria",
        job_Description: "Descrição do seu Emprego Atual",
        experience_Subtitle1:
          "Conte-nos sobre sua experiência profissional, começando com seu último trabalho recente.",
        experience_Subtitle2: " Atualmente trabalha aqui?",
        experience_Subtitle3: "Selecione uma Opção...",
        experience_Subtitle4: "Sobre a Empresa",
        experience_Subtitle5:
          "(Incluir indústria, localização, declaração de missão, etc...)",
        experience_Subtitle6:
          "Incluir funções e responsabilidades de trabalho, experiência de trabalho, realizações, etc...)",
      },

      education: {
        add_New_Education_button: "Adicionar Nova Educação",
        delete_button: "Excluir",
        save_button: "Salvar",
        select_date_Placeholder: "Selecionar dato",
        education_Placeholder2:
          "(Escreva um resumo dos grupos e atividades em que você esteve envolvido enquanto estudava.)",
        education: "Educação",
        education1: "Educação",
        school: "Escola",
        degree: "Grau",
        field_Of_Study: "Campo de Estudo",
        graduated: "Graduado",
        graduation_Date: "Data da Formatuda",
        groups_Activities: "Grupos/ Atividades",
        education_Subtitle1:
          "Uma educação variada em seu currículo resume o valor que seus aprendizados e experiência trarão para um trabalho.",
      },

      certifications: {
        add_New_Certification_button: "Adicionar Nova Certificação",
        delete_button: "Excluir",
        save_button: "Salvar",
        select_month_Placeholder: "Selecione um mês",
        certifications: "Certificações",
        certification: "Certificação",
        name: "Nome",
        issuing_Entity: "Entidade Emissora",
        date_Issued: "Data de Emissão",
        date_Expired: "Data Expirada",
        certifications_Subtitle1:
          "Forneça informações sobre as certificações conquistadas. Caso ainda não tenha concluído alguma certificação, basta pular para a próxima seção.",
        certifications_Subtitle_personality:
          "Forneça informações sobre as certificações obtidas. Caso não tenha concluído uma certificação, pule para a próxima seção.",
        certification_Subtitle2: "Esta certificação expira",
        Please_provide_following:
          "Forneça as seguintes informações gerais sobre a empresa. ",
      },

      courses: {
        add_New_Course_button: "Adicionar Novo Curso",
        delete_button: "Excluir",
        save_button: "Salvar",
        courses: "Cursos",
        course: "Curso",
        name: "Nome",
        number: "Número",
        associated_With: "Associado com",
        courses_Subtitle1:
          "Fornecer informações sobre os cursos realizados. Caso você não tenha concluído um curso, apenas pule para a próxima seção",
      },

      languages: {
        add_New_Language_button: "Adicionar Novo Idioma",
        delete_button: "Excluir",
        save_button: "Salvar",
        select_Language_Placeholder: "Selecione um idioma...",
        languages: "Idiomas",
        language: "Idioma",
        select_level: "Selecione um nível",
        begginer: "Iniciante",
        intermediate: "Intermediário",
        proficient: "Proficiente",
        native_Biligual: "Nativo/Biligual",
        languages_Subtitle1: "Forneça informações sobre seus idiomas.",
        language_Subtitle2: "Selecione uma Opção...",
        language_Subtitle3:
          "Conhecimento de palavras de vocabulário básico, fala frases simples, habilidades elementares de leitura e escrita.",
        language_Subtitle4:
          "Pode lidar com comunicação intermediária, leitura e escrita.",
        language_Subtitle5:
          "Um uso bem avançado do idioma, usando fala, leitura e escrita fluidas.",
        fluent_in_the_Language: "Você é fluente no idioma.",
      },

      references: {
        add_New_button: "Adicionar Novo",
        delete_button: "Excluir",
        save_button: "Salvar",
        references: "Referências",
        reference: "Referência",
        email: "E-mail ou código de referência",
        references_Subtitle1: "Forneça informações sobre suas referências.",
      },
    },
    alertsView: {
      alerts: {
        form_Successfully_Submitted: "Formulário enviado com sucesso!",
        ok_Got_It_button: "OK, entendi!",
        Passwords_must_match: "As Senhas devem corresponder",
        try_again_button: "Tente novamente!",
        Ok_button: "OK!",
        Got_it: "Entendi!",
        reset_Password_button: "Redefinir senha!",
        sorry_Looks_Like:
          "Desculpe, parece que alguns erros foram detectados, tente novamente",
        customer_Required: "O nome do cliente é obrigatório",
        customer_Email_Required: "O email do cliente é obrigatório",
        address_Required: "Endereço 1 é obrigatório",
        town_Required: "Cidade é obrigatória",
        state_Required: "Estado é obrigatório",
        post_Code_required: "Código Postal é Obrigatório",
        last_Week: "Semana passada",
        last_month: "Mês passado",
        last_Three_months: "Últimos 3 meses",
        date_Range_Required: "O intervalo de datas é obrigatório",
        new_Been_Added:
          "Seu novo usuário foi registrado na equipe de Cybersecurity de sua empresa.",
        error_Adding_User: "Erro ao adicionar usuário.",
        have_Some_Problems:
          "temos alguns problemas em candidatar-nos ou enviar mensagem à empresa",
        hooray_Succesfully_Applied:
          "Viva! Você se candidatou com sucesso a esta vaga. A empresa revisará seu perfil em breve e entrará em contato com você, se estiver interessado.",
        Status_Job_Posting: "O status deste anúncio de emprego foi alterado.",
        error_Changing_Status: "Temos um erro ao alterar o status.",
        succesfullyCompleted_Training:
          "Você atualizou com sucesso este status para  ",
        problem_Apply_Job:
          "temos alguns problemas em candidatar-nos ou enviar mensagem à empresa",
        sending_Mail_Information:
          "Em breve enviaremos um e-mail com informações sobre como obter nosso código promocional.",
        employee_Been_Deleted:
          "Este Usuário Admin da Empresa foi removido de sua equipe.",
        error_Deleting_Employee: "Ocorreu um erro ao excluir o funcionário",
        join_talent_pending:
          "Sua empresa está esperando que você aceite o convite para se juntar ao time de Talent em cybersecurity.",
        position_Deleted: "A posição foi deletada.",
        error_Deleting_Position: " Ocorreu um erro ao excluir a posição.",
        now_Submit_Form: "Tudo bem! Agora você pode enviar este formulário",
        your_Job_Information:
          "Suas informações de trabalho foram salvas! Nosso algoritmo de IA especializado está pronto para combinar seu trabalho com nosso banco de Talent!",
        profile_Information_Saved: "Suas informações de perfil foram salvas!",
        check_Red_Fields: "Por favor verifique os campos vermelhos.",
        securityClearanceRequired:
          "Este trabalho tem autorização de segurança necessária. Somente as pessoas que configuraram a autorização de segurança nas informações do seu perfil podem se candidatar.",
        Your_file_has_been_successfully:
          "Seu arquivo foi enviado com sucesso. Nossa IA da Seccuri o analisará para melhorar as informações fornecidas até agora.",
        sorry: "Desculpe",
        All_set: "Tudo pronto!",
        by_acepting_you:
          "Ao aceitar que você possui autorização de segurança (ou que recebeu uma no passado), você concorda em fornecer informações verdadeiras que possam ser necessárias para uma investigação mais aprofundada e processos de validação de candidatos.",
        in_case_you_currently:
          "Caso você atualmente tenha um status de liberação ativo ou atual e esteja se candidatando a um cargo que exija um determinado nível de liberação, você ainda pode estar sujeito a revisões de antecedentes em nome do Departamento de Recursos Humanos de uma empresa ou pelo Federal Governo.",
        We_have_successfully:
          "¡Hemos recibido tu solicitud de nominación con éxito!",
        If_you_have_nominated:
          "Si has nominado a un docente, te recomendamos estar alerta a la publicación de su perfil en este módulo para empezar a votar cuanto antes.",
        form_Several_Errors:
          "O formulário contém vários erros, verifique os campos vermelhos.",
        course_Deleted: "O curso foi deletado.",
        error_Deleting_Course: "Ocorreu um erro ao excluir o curso.",
        courses_Updated_Successfully:
          "Seus cursos foram atualizados com sucesso.",
        certification_Deleted: "A certificação foi excluída.",
        error_Deleting_Certification:
          "Ocorreu um erro ao excluir a certificação.",
        certifications_Updated_Successfully:
          "Suas certificações foram atualizadas com sucesso.",
        dateIssueIsRequired: "Preencha todos os campos: Data de emissão",
        startdate: "Preencha todos os campos: Data de início",
        enddate: "Preencha todos os campos: Data de conclusão",
        please_name: "Preencha todos os campos: Nome do curso",
        Select_level_error: "Preencha todos os campos: Selecione um nível",
        education_Deleted: "A educação foi excluída.",
        error_deleting_Education: "Ocorreu um erro ao excluir a educação.",
        education_History_Updated_Successfully:
          "Seu histórico educacional foi atualizado com sucesso.",
        Select_school_error: "Preencha todos os campos: Escola",
        Select_degree_error: "Preencha todos os campos: Grau",
        certification_error: "Erro ao adicionar Certificação",
        language_Deleted: "O idioma foi excluído.",
        error_Deleting_Language: "Ocorreu um erro ao excluir o idioma.",
        languages_Updated_Successfully:
          "Seus idiomas foram atualizados com sucesso.",
        reference_Deleted: " A referência foi excluída.",
        error_deleting_Reference: "Ocorreu um erro ao excluir a referência.",
        references_Updated_Successfully:
          "Suas referências foram atualizadas com sucesso.",
        information_Succesfully_Submitted:
          "Suas informações foram enviadas com sucesso!",
        invalid_Infromation: "informação inválida.",
        roles_Updated_Successfully:
          "Suas funções foram atualizadas com sucesso.",
        message_Already_Talent:
          "Sua mensagem foi entregue com sucesso no Email do seu Cyber Talent!",
        problems_Sending_Message_Talent:
          "Ocorreu um erro ao enviar a mensagem para o talent.",
        account_Eliminated: "Sua conta foi eliminada.",
        Your_communication_preferences:
          "Suas preferências de privacidade foram atualizadas com sucesso.",
        email_Password_Invalid: "Email o senha inválida.",
        email_Been_reset:
          "Seu e-mail foi redefinido. Use o novo email para fazer login.",
        password_Been_Reset:
          "Sua senha foi redefinida. Use o novo email para fazer login.",
        password_Confirmation_Password_Invalid:
          "Nova senha ou senha de confirmação inválida.",
        email_Successfully_Changed: "Email foi alterado com sucesso!",
        password_Successfully_Changed: "Senha foi alteradoacom sucesso!",
        account_Already_Activated:
          "Sua conta foi ativada com sucesso! Você agora será direcionado para sua conta Seccuri..",
        token_Invalid: "Token inválido.",
        instructions_Reset_Password:
          "Você receberá um email com as instruções para redefinir sua senha ",
        user_Password_Invalid: "Usuário ou senha inválida.",
        password_Must_More_Characters:
          "Senha deve ter pelo menos 12 ou mais caracteres com uma mistura de letras, números e símbolos.",
        password_Changed_Succesfully:
          "Sua senha foi alterada com sucesso. Use a nova senha para fazer login.",
        email_Changed_Succesfully:
          "Seu email foi alterado com sucesso. Use o novo email para fazer login.",
        maximun_Number_Account_Blocked:
          "Você atingiu o número máximo de tentativas de login e sua conta está bloqueada. Para começar a usar a plataforma Seccuri, você precisará redefinir sua senha",
        fill_Valid_Phone_Number:
          "Por favor, preencha um número de telefone válido.",
        password_More_Characters:
          "Senha deve ter pelo menos 12 ou mais caracteres com uma mistura de letras (maiúsculas e minúsculas), números e símbolos.",
        accept_Termns_Conditions:
          "Por favor, leia e aceite os termos de condições.",
        accept_privacy_statement:
          "Leia e aceite nossa Declaração de privacidade.",
        successfully_Registered_Seccuri:
          "Parabéns! Você se registrou com sucesso no Seccuri. Verifique sua caixa de entrada para verificar e ativar sua conta.",
        successfully_Registered_Seccuri_talent:
          "Parabéns! Você se registrou com sucesso no Seccuri. Faça login com seu e-mail e senha.",
        happened_Error_During_Register:
          "Desculpe! Ocorreu um erro durante o cadastro, por favor tente novamente.",
        exit_edit_profile:
          "Espere! Se você fechar seu perfil de edição agora, poderá perder suas informações de perfil. Certifique-se de salvar seu perfil antes de sair. Tem certeza de que deseja sair?",
        confirm_exit_edit_profile: "Saor",
        Open_edit_profile_Hi: "Olá",
        Open_edit_profile:
          "Você está prestes a começar a editar seu perfil. Certifique-se de salvar suas informações ao inseri-las usando os botões azuis salvar para cada seção, para evitar perdê-las no final do processo.",
        password_Expired:
          "Por questões de segurança sua senha expirou. Para continuar usando a Plataforma Seccuri você deve alterá-la seguindo as instruções",
        password_Expired_Alert:
          "Por motivos de segurança, sua senha expirará em",
        password_Expired_Alert_2:
          "dias. Por favor, altere a senha da sua conta o mais rápido possível para continuar usando a Plataforma Seccuri. ",
        temporary_Emails:
          "A plataforma Seccuri não oferece suporte a endereços de email temporários.",
        please_Wait: "Por favor, espere...",
        submit: "Enviar",
        we_have_errors:
          "Ocorremos erros ao carregar a carreira selecionada. Por favor, tente novamente.",
        we_have_errors_link_account:
          "Temos erros ao vincular sua conta de Talent. Tente novamente a partir do seu email de ativação.",
        link_company:
          "Vamos prosseguir para vincular sua conta Talent com o painel da empresa. Por favor, faça login para concluir.",
        link_talent_company_success:
          "Vinculamos sua Conta de Talent com sucesso. Você pode ver a guia 'Relacionado à empresa' em suas informações de perfil.",
        link_talent_company_fail: "Vinculo no gerado.",
        link_accept_Award_success:
          "Você já está na competição! 🎉 Agora você pode encontrar sua carta de docente no módulo Seccuri Awards, votar e compartilhá-la com seus alunos e colegas de cibersegurança para ganhar mais votos. Muito sucesso!🚀",
        confirm_acept_Awards: "Fazer login",
        confirm_link_talent_account: "Aceitar Convite",
        not_acept_link_talent_account: "Não Aceito",
        try_again: "Tente a ativação novamente",
        settings: "Configurações",
        account: "Conta",
        the_Employee_Has_Been_Terminated:
          "O funcionário foi desvinculado da empresa.",
        the_Talent_Has_Been_Added:
          "O talent foi adicionado à empresa e uma notificação por e-mail foi enviada para você.",
        there_is_Already_a_User:
          "Já existe um usuário relacionado a uma empresa com o mesmo endereço de email.",
        The_email_registered:
          "O endereço de e-mail está registrado na plataforma como uma empresa. Por favor, use um endereço diferente.",
        your_info_was_saved: "Suas informações foram atualizadas.",
        your_Experiences_Could_Not:
          "Suas experiências não puderam ser atualizadas com sucesso. Tente novamente.",
        error_Occurred_in_the_Invitation:
          "Ocorreu um erro no convite que você tentou fazer ao talent.",
        unlink_Company: "Desvincular Empresa",
        you_want_to_Unlink_your_Account_From_the_Company:
          "A Tem certeza de que deseja desvincular sua conta da empresa? A empresa será notificada sobre o seu cancelamento. Você poderá continuar usando sua conta Seccuri normal.",
        unlink_Button: "Desvincular",
        unlinked_From_the_Company:
          "Sua conta de usuário foi desvinculada da empresa. Continue usando sua conta Seccuri gratuita.",
        error_When_Unlinking_the_Company:
          "Ocorreu um erro ao tentar desvincular a empresa. Tente novamente mais tarde.",
        presenting_Errors_to_Unlink_your_Talent:
          "Ocorreu um erro ao tentar desvincular sua conta de Talent. Tente novamente a partir do seu e-mail.",
        already_Exists_an_Account_Registered:
          "Desculpe! Já existe uma conta cadastrada com o email.",
      },
    },
    careerPath: {
      RecommendedByCompany: "Recomendado por sua empresa",
      SubscriptionsPlans: "Planos de assinatura",
      Assign_Career_Path:
        "Conheça o progresso deste Company Talent em relação ao plano de carreira selecionado e recomende-o caso esteja alinhado com seus objetivos de desenvolvimento profissional.",
      Assign_Career_Path_Description:
        "Recomende o plano de carreira selecionado aos membros da sua Equipe Cyber para garantir o desenvolvimento das habilidades necessárias, alinhadas aos objetivos de cibersecurity da sua empresa.",
      Recommend: "Recomendar",
      undo: "Desfazer",
      Asign_careerPath_employee:
        "Atribua o plano de carreira à sua Equipe Cyber",
      Asign_careerPath_employee_description:
        "Recomende o plano de carreira selecionado aos membros da sua Equipe Cyber para garantir o desenvolvimento das habilidades necessárias, alinhadas aos objetivos de cibersecurity da sua empresa",
      Duplicate_and_Edit: "Duplicar e editar",
      Duplicate_and_Edit_title: "Personalize o plano de carreira Seccuri",
      Duplicate_and_Edit_description:
        "Personalize o plano de carreira selecionado duplicando-o e editando seus treinamentos conforme necessário.",
      edit_current_career: "Editar carreira Selecionado",
      view_Button: "Visualização",
      customize: "Personalizar",
      Your_Cybersecurity_Career_Paths:
        "Seus planos de carreira profissional em cybersecurity",
      Your_Cybersecurity_Career_Paths_Descipiion:
        "Neste módulo, você encontrará todos os seus planos de carreira salvos e duplicados para que possa usá-los como guia e personalizá-los de acordo com seus objetivos profissionais.",
      Recommended_Company_Career_Paths:
        "Planos de Carreira Recomendados pela Empresa",
      Recommended_Company_Career_Paths_description:
        "Neste módulo, você poderá conhecer os planos de carreira recomendados pela sua empresa vinculada, com base nos seus objetivos de desenvolvimento de Talent em cybersecurity.",
      Your_Company_Career_Paths: "Os planos de carreira da sua empresa ",
      Your_Company_Career_Paths_Descroption:
        "Neste módulo, você encontrará todos os planos de carreira salvos e duplicados pela sua empresa. Você poderá visualizá-los, personalizá-los e atribuí-los facilmente aos membros da sua Equipe de Cyber.",

      Career_Path_Personalization: "Personalização da trajetória de carreira",
      Career_Path_Personalization_descripcion:
        "Bem-vindo ao módulo Career Path Edition! Aqui, você pode adaptar o caminho de carreira selecionado personalizando os treinamentos associados. O caminho original vem com treinamentos sugeridos para cada nível, mas, nesta seção, você pode:",
      optimize_your_cubersecurity:
        "Otimize sua jornada em Cybersecurity criando um plano de carreira personalizado que se alinhe perfeitamente com seus objetivos. Vamos começar!",
      optimize_your_cubersecurity_company:
        "Otimize a jornada de Cybersecurity de seus Talent criando uma trajetória de carreira personalizada que se alinhe perfeitamente com seus objetivos mútuos. Vamos começar!",
      Remove_Trainings: "Remover treinamentos",
      Remove_Trainings_description:
        ": Elimine os treinamentos existentes para refinar sua carreira de acordo com seus objetivos.",
      Remove_Trainings_description_company:
        ": Elimine os treinamentos existentes para refinar a trajetória de carreira de acordo com os objetivos de desenvolvimento de Talent de sua empresa.",

      ReCategorize: "Re-categorizar",
      ReCategorize_description:
        ": Coloque os treinamentos em um nível de carreira de sua escolha.",
      Add_New_Trainings: "Add New Trainings",
      Add_New_Trainings_description:
        ": Explore o ecossistema de treinamento da Seccuri e atribua novos programas a níveis específicos de carreira.",
      Remove: "Remover o treinamento da carreira",
      view_trainings: "Guardar e editar treinamentos",
      edit_trainings_info:
        "Os treinamentos que você excluir podem ser adicionados novamente na opção Pesquisar treinamentos.",
      delete_career: "Excluir",
      are_you_sure:
        "Tem certeza de que deseja excluir o plano de carreira selecionado?",
      SearchTrainings: "Busca de treinamentos",
      entry_Level: "Nível Inicial",
      mid_Career: "Meio de carreira",
      Professional: "Profissional",
      CareerPathName: "Nome da carreira",
      Security_Architect: "Arquiteto de Segurança",
      SOC_Professional: "Profissional de SOC",
      Red_Team_Professional: "Profissional da Equipe Vermelha",
      Cybersecurity_Auditor: "Auditor de Cybersecurity",
      Security_ArchitectDescription:
        "Esta trajetória profissional supervisiona a avaliação dos sistemas de uma organização em busca de fraquezas e é responsável por projetar, construir, testar e implementar sistemas de segurança da TI.",
      SOC_ProfessionalDescription:
        "Um profissional do SOC monitora, analisa e responde a incidentes de segurança que uma organização pode estar enfrentando. As funções mais comuns incluem avaliar sistemas de TI, identificar e fechar vulnerabilidades e reforçar a resistência da segurança.",
      Red_Team_ProfessionalDescription:
        "Esta trajetória profissional supervisiona a real força da defesa cibernética de uma organização, encontrando fraquezas durante as práticas de teste do sistema e fornecendo feedback valioso que ajudará a reforçar os controles de cibersecurity.",
      Cybersecurity_AuditorDescription:
        "Avalia e relata a eficácia dos controles de cibersecurity implementados, políticas e segurança geral dos sistemas de TI de uma organização.",
      source: "Fonte: ",
      Learning_Path: "Cybersecurity do Zero:",
      Learning_Paths: "Plano de aprendizado para iniciantes",
      Start_your_journey:
        "Comece sua jornada na cybersecurity (Cybersecurity)com este módulo para indivíduos sem experiência anterior na área.",
      Start_by_exploring:
        "Explore recursos sobre Cybersecurity, inscreva-se nos principais treinamentos online para obter e certificar conhecimentos e descubra empregos e estágios iniciais globais para aplicar e solidificar seu conhecimento.",
      Start_your_journey_company:
        "Apresente às equipes digitais da sua organização a cybersecurity com este módulo projetado para equipar os indivíduos com conhecimentos básicos no campo.",
      Start_by_exploring_company:
        "Acesse recursos, treinamentos e certificações essenciais que abordam os fundamentos da cybersecurity, ideais para aprimorar a conscientização organizacional e capacitar os participantes com os conhecimentos necessários para começar a implementar práticas eficazes de cybersecurity ou buscar um plano de carreira profissional nesse campo. ",
      Next_Steps: "Próximas etapas:",
      As_you_acquire:
        " à medida que você adquire novos conjuntos de habilidades e ganha experiência, revisite o módulo NIST NICE Roles da Seccuri para entender seu perfil de Cybersecurity em evolução e explorar os planos de carreira sugeridos que se alinham com sua experiência crescente, para continuar aprimorando e requalificando de acordo com seus objetivos profissionais.",
      Let_get_started: "Vamos começar!",
      Basic_Information: "Recursos",
      Trainings: "Treinamentos",
      Jobs: "Empregos",
      Basic_Information_desc:
        "Acesse materiais importantes para aprender sobre cybersecurity e seus fundamentos. Compreenda os princípios básicos da cybersecurity, as diferentes áreas de especialização e o papel crítico que desempenha no cenário digital.",
      Trainings_desc:
        "Explore cursos online de alta qualidade para iniciar uma carreira abrangente em cibersecurity, adquirir habilidades técnicas essenciais e obter certificações para validar sua expertise.",
      Basic_Information_desc_company:
        "Acesse recursos fundamentais para aprender sobre cybersecurity e seus princípios, e compartilhe-os com suas equipes digitais. Conheça os princípios básicos, as diferentes áreas de especialização e o papel crítico da cybersecurity no ambiente digital.",
      Trainings_desc_company:
        "Explore e compartilhe cursos, treinamentos e certificações online de alta qualidade com suas equipes digitais para fornecer uma compreensão abrangente da cybersecurity ou ajudá-las a adquirir as habilidades técnicas necessárias para iniciar uma carreira profissional no campo.",
      Jobs_desc:
        "Descubra e candidate-se a alguns dos empregos e estágios globais de nível inicial mais populares para testar seus conhecimentos e habilidades e obter experiência valiosa no campo.",
      Basic_Information_Cybersecurity_Foundations:
        "Recursos: Fundações de cybersecurity",
      Welcome_starting:
        "Bem-vindo ao ponto de partida da sua jornada de cybersecurity!",
      variety_of_carefully:
        "Neste módulo, você descobrirá uma variedade de recursos cuidadosamente selecionados, incluindo sites, artigos, blogs, vídeos, white papers e muito mais, para fornecer uma compreensão global e sistemática da cybersecurity.",
      Explore_these_valuable:
        "Explore essas referências valiosas no seu próprio ritmo para reunir conhecimentos básicos essenciais para a sua compreensão da cybersecurity. Sinta-se à vontade para navegar a qualquer momento e iniciar o emocionante caminho para se tornar um especialista em cybersecurity!",
      Welcome_starting_company:
        "Bem-vindo ao ponto de partida para que suas equipes digitais comecem a adquirir habilidades e conhecimentos em cybersecurity!",
      variety_of_carefully_company:
        "Neste módulo, você encontrará uma variedade de recursos cuidadosamente selecionados, incluindo sites, artigos, blogs, vídeos, white papers e muito mais, para compartilhar com seus colaboradores e obter uma compreensão global e sistemática da cybersecurity. ",
      Explore_these_valuable_company:
        "Explorar essas valiosas referências ajudará os colaboradores a adquirir conhecimentos fundamentais essenciais para sua compreensão da cybersecurity. Sinta-se à vontade para navegar a qualquer momento e iniciar o caminho rumo a equipes digitais seguras com expertise em cybersecurity!",
      Trainings_Cybersecurity_Skill_Building: "Treinamentos:",
      Cybersecurity_Skill: "Desenvolvimento de habilidades em cybersecurity",
      Find_a_selection:
        "Encontre uma seleção de alguns dos cursos, treinamentos e certificações de cybersecurity mais recomendados para iniciantes, oferecidos pelos principais provedores de treinamento globais.",
      As_you_explore:
        "À medida que você explora e realiza esses treinamentos e certificações, identifique os principais tópicos que lhe interessam. Esse conhecimento será valioso posteriormente, ao identificar planos de carreira em cybersecurity que melhor correspondam aos seus interesses e objetivos.",
      Find_a_selection_company:
        "Aqui está uma seleção de alguns dos cursos, treinamentos e certificações de cybersecurity mais recomendados para iniciantes, oferecidos pelos principais provedores globais de treinamento.",
      As_you_explore_company:
        "Ao compartilhá-los com colaboradores interessados em iniciar uma carreira em cybersecurity, eles poderão identificar os temas-chave que lhes interessam. Este conhecimento será valioso mais tarde para identificar os planos de carreira em Cybersecurity.",

      Entry_Level_Jobs: "Empregos de Nível Inicial",
      Start_Gaining_Professional: "Comece a Ganhar Experiência Profissional",
      Explore_top_entry_level:
        "Descubra algumas das melhores oportunidades de emprego e estágios em nível mundial para iniciar sua carreira profissional. Encontre descrições detalhadas de cada posição e conecte-se diretamente com as empresas contratantes para se candidatar às vagas que estejam alinhadas com suas habilidades e interesses. Sua carreira em cibersecurity começa aqui!",
      Learn_About_Top:
        "Saiba mais sobre as principais funções de Cybersecurity para iniciantes",
      Top_Cibersecurity: "Principais papéis de Cybersecurity para iniciantes",
      close: "Fechar",
      viewAll: "Ver tudo",
    },
    sessions: {
      description: "Não perca nosso próximo",
      register_button: "Salvar o evento em seu calendário",
      know_more: "Mais informações",
      SeccuriSession: "Seccuri Session",
      webinar: "webinar!",
      date: "Date",
      hour_PST: "PST",
      california_hour: "California",
      NewYork_hour: "New York",
    },
    SeccuriSessionsMenu: "Seccuri Session Webinars",
    SeccuriSessions: "Seccuri Sessions",
    jobs: "Empregos",
    trainings: "Treinamentos",
    level: " Nível:",
    provider: "Provedor:",
    Career_Path_Level: "Nível do plano de carreira:",
    Status: "Estado:",
    statussinpoint: "Estado",
    Company_Job_Listings: "Listagens de empregos da empresa",
    Your_CV_is_in_process: "Seu CV está sendo processado!",
    Our_AI_is_analyzing:
      "Nossa IA está analisando seu arquivo PDF e isso pode levar alguns minutos. Enquanto isso, continue explorando o Seccuri. Avisaremos quando estiver pronto para revisão.",
    since_we_use_AI:
      "Importante: Alguns dados podem não ser 100% precisos, por isso recomendamos verificar as informações.",
    Everything_ready: "Tudo pronto!",
    Your_file_has_been_successfully:
      "Seu arquivo foi carregado e analisado com sucesso por nosso Seccuri AI. Recomendamos que você atualize a página e revise as informações do seu perfil para confirmar se tudo está correto.",
    File_Upload: "Carregar arquivo",
    Drag_and_Drop: "Arraste e solte seu arquivo.",
    Draganddropbrowse: "Arraste e solte seu arquivo ou <u>explore</u>",
    Please_try_again: "Tente novamente",
    Oops: "Ops!",
    Something_went_wrong:
      "Algo deu errado ao carregar seu arquivo. Por favor, tente novamente ou carregue um arquivo diferente.",
    tap_to_retry: "Toque para tentar novamente",
    Completed: "Concluído",
    InProgress: "Em andamento",
    Certification: "Certificação",
    Training: "Treinamento",
    Awareness: "Conscientização",
    Education: "Educação",
    Course: "Curso",
    Foundations: "Básico",
    Introductory: "introdução",
    Intermediate: "Intermediário",
    Advanced: "Avançado",
    New_to_Cyber: "Novo na Cybersecurity",
    Training_in_Progress: "Treinamento em andamento",
    Completed_Training: "Treinamento concluído",
    Delete_Training: "Excluir treinamento",
    language_English: "Inglês",
    language_Spanish: "Espanhol",
    language_Portugues: "Portugues",
    learning: "Meus aprendizados",
    accomplishments: "Minhas realizaçõe",
    path: "Meu Caminho",
    dashboard: "Painel ",
    layoutBuilder: "Construtor de Layout",
    craft: "Crafted",
    pages: "Pages",
    profile: "Perfil",
    profileOverview: "Overview",
    projects: "Projetos",
    campaigns: "Campanhas",
    documents: "Documentos",
    connections: "Conexões",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Conta",
    accountOverview: "Overview",
    settings: "Configurações",
    company_Related: "A Minha Empresa",
    Account_Privacy: "Privacidade da conta",
    authentication: "Autenticação",
    basicFlow: "Flujxo Básico",
    signIn: "Iniciar Sessão",
    signUp: "Inscreva-se",
    passwordReset: "Restaurar Senha",
    multiStepSignUp: "Inscrever-se em várias etapas",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Apps",
    chat: "Chat",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Listas",
    widgetsStatistics: "Estatisticas",
    widgetsCharts: "Gráficos",
    widgetsMixed: "Mixed",
    widgetsTables: "Tabuadas",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    docsAndComponents: "Docs & Components",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    inviteFriends: "Convidar Usuário",
    viewUsers: "Ver Usuário",
    upgradePlan: "Actualizar Plano",
    shareAndEarn: "Compartir & Ganhar",
    forms: "Formulários",
    newTarget: "Novo alvo",
    newCard: "Novo cartão",
    newAddress: "Novo endereço",
    createAPIKey: "Criar API Key",
    twoFactorAuth: "Autenticação de dois fatores",
    createApp: "Criar App",
    createAccount: "Create Conta",
    documentation: "Documentação",
    components: "Componentes",
    resources: "Recursos",
    activity: "Actividade",
    customers: "Clientes",
    gettingStarted: " Começando",
    customersListing: "Listagens de clientes",
    customerDetails: "Detalhes do cliente",
    calendarApp: "Calendário",
    subscriptions: "Assinaturas",
    getStarted: "Começando",
    subscriptionList: "Lista de assinaturas",
    addSubscription: "Adicionar assinatura",
    viewSubscription: "Visualizar assinatura",
    my_Profile: "Meu Perfil",
    my_saved_jobs: "Empregos Salvados",
    my_careers: "Meus planos de carreira",
    language: "Idioma",
    accountSettings: "Configurações de Conta",
    sign_Out: "Sair",
    on_Favorites: "Em Favoritos",
    on_Results: "Em Resultados",
    recommendations: "Recomendações",
    profile_Completion: "Perfil Completo",
    overview: "Informações gerais",
    copyright_Seccuri:
      "Copyright © 2024 Seccuri, Inc. All Rights Todos os direitos reservados.",
    terms_Conditions: "Termos & Condições",
    and: " e ",
    privacy_Statement: " Declaração de privacidade",
    profile_Details: " Detalhes de perfil",
    talent_Tittle1: "Precisamos da sua atençãon!",
    talent_Subtittle1:
      "Por favor, certifique-se de completar o seu perfil de Talent para obter uma classificação melhor e permitir que as Empresas que estão publicando vagas o encontrem mais facilmente!",
    please_Get_Your_Roles: "Por favor, descubra seus papéis!",
    to_get_Roles_Please_Follow:
      "Para descobrir suas Papéis NIST NICE, siga as etapas 1, 2 e 3.",
    you_Can_Start_With_First_Step:
      "Você pode começar com o primeiro passo aqui",
    select_your_Areas: "1. Selecione suas áreas",
    profile_Overview: "Visão geral do perfil",
    completed_Sections: "Seções concluídas: ",
    home: "Home",
    full_Profile: "Preenchimento de Perfil",
    all_Jobs: "Todos os Empregos ",
    published_jobs: "Trabalhos publicados",
    Saved_as_dratf_Jobs: "Trabalhos salvos como rascunhos",
    jobs_Menu: "Empregos",
    edit_Profile: "Editar perfis",
    saved_Jobs: "Empregos Salvos",
    applied_Jobs: "Empregos Aplicados",
    jobs_with_candidates: "Empregos com Candidatos",
    trainings_Menu: "Treinamentos",
    all_Trainings: "Todos os Treinamentos ",
    saved_Trainings: "Treinamentos Salvos",
    training_Progress: "Progreso do Treinamiento",
    career_Paths: "Planos de Carreira",
    Cybersecurity_from_Scratch: "Cybersecurity do Zero",
    all_Career_Paths: "Planos de Carreira em Cybersecurity",
    Company_Career_Paths: "Planos de carreira da empresa",
    my_Careers: "Meus planos de carreira",
    saved_Careers: "Carreiras Salvas",
    company_Related_Menu: "Minha Empresa",
    admin_Employees: "Usuários Admin da Empresa",
    cybersecurity_Talent_Team: "Equipe Cyber",
    seccuriawards_path: "Seccuri Awards",
    MexicoAwards: "Awards México",
    ColombiaAwards: "Awards Colombia",
    ChileAwards: "Awards Chile",
    RoleDiscoveryProgress: "Progresso na Descoberta de Funções",
    Are_you_new_to_cybersecurity: "Você é novo em cibersegurança?",
    This_module_is_designed:
      "Este módulo foi projetado para ajudá-lo a descobrir os papéis em cibersegurança que melhor se adequam ao seu perfil e interesses. Ao responder a um breve conjunto de perguntas, analisaremos seu perfil e ofereceremos sugestões personalizadas para guiá-lo rumo a uma carreira satisfatória em cibersegurança. Vamos começar sua jornada!",
    I_have_experience_in_cybersecurity:
      "Tenho experiência em segurança cibernética",
    I_am_an_enthusiast: "Sim, sou um entusiasta",
    Where_are_you_in_your_career: "¿En qué etapa de tu carrera estás?",
    Graduated_High_School: "Graduado/a del colegio",
    I_am_just_graduated: "Estoy cursando/soy recién egresado de la universidad",
    I_want_to_change: "Quiero cambiar mi carrera actual",
    I_am_doing_just_graduated_from_my_Bachelors:
      "Estoy cursando/soy recién egresado de la universidad",
    I_have_finished_at_least_one_training_in_cybersecurity:
      "He realizado al menos un curso de ciberseguridad",
    I_work_in_cybersecurity: "Trabajo en cibeseguridad",
    Let_get_started: "¡Vamos começar!",
    Choose_only_one: "Escolha apenas um",
    Choose_all_that_apply: "Selecione todas as opções aplicáveis",
    Math: "Matemática",
    Languages: "Idiomas",
    Social_Studies: "Ciências Sociais",
    Art: "Arte",
    Sciences: "Ciências (física, química, biologia)",
    Technology: "Tecnologia / Computação",
    Business: "Negócios / Economia",
    Major: "Área geral de estudo",
    Write_in: "Escreva aqui",
    Minor_Specialization: "Especialidade",
    Marketing: "Marketing",
    Human_Resources: "Recursos Humanos",
    Physical_Security: "Segurança Física",
    Accounting: "Contabilidade",
    Sales: "Vendas",
    Data_Analysis: "Análise de Dados",
    Other: "Outro :",
    What_area_are_you_currently_working_school:
      "De quais matérias você mais gostava na escola?",
    Solving_riddles_and_puzzles_or_logical_problems:
      "Resolver enigmas e quebra-cabeças ou problemas lógicos",
    Reading_Writing: "Leitura / Escrita",
    Building_things_or_craft_making: "Construir coisas ou fazer artesanato",
    Learning_new_things: "Aprender coisas novas",
    Work_in_group_projects: "Trabalhar em projetos de grupo",
    What_are_you_interested_in: "O que lhe interessa?",
    What_skills_future: "Que competências você prefere desenvolver no futuro?",
    Which_area_interesting: "Qual(is) área(s) você acha mais interessante?",
    Would_you_like_problems_under_pressure:
      "Você gostaria de trabalhar em uma posição onde resolvesse problemas sob pressão?",
    Information_security_and_system_protection:
      "Segurança da informação e proteção de sistemas",
    Security_incident_and_event_response:
      "Resposta a incidentes e eventos de segurança",
    Understand_human_behavior_related_to_cybersecurity:
      "Entender o comportamento humano relacionado à cibersegurança",
    Analyze_data_and_identify_patterns: "Analisar dados e identificar padrões",
    Investigate_vulnerabilities_and_analyze_cyber_attacks:
      "Investigar vulnerabilidades e analisar ataques cibernéticos",
    Protect_and_improve_networks_IT_and_OT_systems:
      "Proteger e melhorar redes, sistemas de TI e OT",
    Design_security_policies_and_strategies:
      "Desenhar políticas e estratégias de segurança",
    Train_others_on_cybersecurity_good_practices:
      "Treinar outros sobre boas práticas de cibersegurança",
    Yes_I_work_well_under_pressure_and_solving_problems_quickly:
      "Sim, eu trabalho bem sob pressão e resolvendo problemas rapidamente",
    It_depends_on_the_situation: "Depende da situação",
    I_prefer_to_work_on_a_less_pressure_environment:
      "Eu prefiro trabalhar em um ambiente com menos pressão",
    What_area_are_you_currently_working_in:
      "No que você está trabalhando atualmente?",
    I_am_doing_just_graduated_from_my_Bachelor:
      "O que você está estudando/O que você estudou?",
    What_are_you_studying_What_did_you_study_Major: "Área de estudo geral",
    What_are_you_studying_What_did_you_study_Minor: "Especialidade",
    What_did_you_study: "O que você está estudando / O que você estudou?",
    Major_This_includes_any_Graduate_Degree:
      "Principal / Isso inclui qualquer graduação, curso de escola profissionalizante, faculdade comunitária ou outro.",
    Minor_Specialization_description: "Menor / Especialização",
    What_coding_language_do_you_know:
      "Que linguagem(s) de codificação você conhece?",
    I_dont_know_coding_languages: "Não sei/uso linguagens de codificação",
    Endpoint_Security_Tools:
      "Ferramentas de Segurança de Endpoint (ou seja, Antivirus e Antimalware, EDR, MDM)",
    Encryption_and_Data_Protection_Tools:
      "Ferramentas de Criptografia e Proteção de Dados (ou seja, Criptografia de Disco Completo e de Arquivos)",
    IAM_Tools:
      "Ferramentas de IAM (Gerenciamento de Identidade e Acesso) (ou seja, MFA, SSO, PAM)",
    Cloud_Security_Tools:
      "Ferramentas de Segurança em Nuvem (ou seja, CSPM, CASB)",
    Security_Information_and_Event_Management:
      "Ferramentas de Gerenciamento de Informações e Eventos de Segurança (SIEM)",
    Penetration_Testing_Tools: "Ferramentas de Teste de Penetração",
    Vulnerability_Management_Tools:
      "Ferramentas de Gerenciamento de Vulnerabilidades (ou seja, scanners de vulnerabilidade, gerenciamento de patches)",
    Backup_and_Disaster_Recovery_Tools:
      "Ferramentas de Backup e Recuperação de Desastres",
    Network_Security_Tools:
      "Ferramentas de Segurança de Rede (ou seja, Firewalls, IDS/IPS, NAC, VPN, DNS, WAF)",
    Risk_Management_and_Compliance_Tools:
      "Ferramentas de Gerenciamento de Riscos e Conformidade",
    Threat_Intelligence_Tools: "Ferramentas de Inteligência de Ameaças",
    Incident_Response_Tools:
      "Ferramentas de Resposta a Incidentes (ou seja, ferramentas de gerenciamento de incidentes, ferramentas forenses)",
    None: "Nenhum",
    What_tools_do_you_use_or_have_learned_how_to_use:
      "Quais ferramentas e tecnologias você usa atualmente?",
    What_areas_in_cybersecurity_are_you_interested_in:
      "Quais áreas da segurança cibernética mais lhe interessam?",
    Securely_Provision: "Provisão Segura",
    Operate_and_Maintain: "Operar e manter",
    Oversee_and_Govern: "Supervisionar e governar",
    Protect_and_Defend: "Proteger e defender",
    Analyze: "Analisar",
    Collect_and_Operate: "Coletar e operar",
    Investigate: "Investigar",
    What_training_or_certification_in_cyberescurity_have_you_completed:
      "Que treinamento(s) ou certificação em segurança cibernética você concluiu?",
    Training_certification: "Treinamento / certificação",
    How_long_have_you_worked_in_cybersecurity:
      "Há quanto tempo você trabalha com segurança cibernética?",
    Experience_less_than_1_year:
      "Tenho menos de 1 ano de experiência em segurança cibernética",
    Experience_1_to_3_years:
      "Tenho entre 1 e 3 anos de experiência em segurança cibernética",
    Experience_more_than_3_years:
      "Tenho mais de 3 anos de experiência em segurança cibernética",
    What_is_your_current_role: "Qual é sua função atual?",
    Role: "Função",
    Industry: "Indústria",
    Which_tools_technologies_do_you_use_or_have_learned_how_to_use:
      "Quais ferramentas e tecnologias você usa atualmente?",
    What_training_or_certification_in_cybersecurity_have_you_completed:
      "Quais cursos ou certificações de segurança cibernética você concluiu?",
    Your_Results_Are_In: "Seus resultados chegaram!",
    Thanks_for_taking_the_quiz:
      "Obrigado por fazer o quiz! Abaixo está uma análise de como seu perfil se alinha com os papéis de cibersegurança (% de ajuste) com base em suas respostas. Explore os módulos da Seccuri para começar a treinar, obter certificações e encontrar empregos alinhados com esses papéis quando estiver pronto!",
    Enhance_Your_Results: "Melhore seus resultados",
    Add_specific_NIST_NICE_KSTs:
      "Adicione KSTs específicos do NIST NICE para obter recomendações ainda melhores. As sugestões de IA são personalizadas, mas nem sempre são 100% precisas; sua contribuição as torna mais inteligentes!",
    The_quiz_is_complete_In_just_a_moment:
      "O quiz foi concluído! Em instantes, nossa IA analisará suas respostas e mostrará os papéis de cibersegurança que melhor combinam com você.",
    Please_select_an_answer_to_continue:
      "Por favor, selecione uma resposta para continuar.",
    Please_select_at_least_one_answer_to_continue:
      "Por favor, selecione pelo menos uma resposta para continuar.",
    Please_include_answer_before_moving:
      "Inclua uma resposta antes de passar para a próxima etapa",
    Go_to_Result: "Ir para Resultado",
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "en",
  globalInjection: true,
  messages,
});

export default i18n;
