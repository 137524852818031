enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  REGISTER_COMPANY = "registerCompany",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  RESET_PASSWORD = "resetPassword",
  RESET_EMAIL_TOKEN = "resetEmail",
  ACTIVATION_ACCOUNT = "activationAccount",
  GET_JOBS = "getJobsByFilter",
  GET_NEW_TO_CYBER_JOBS = "GET_NEW_TO_CYBER_JOBS",
  GET_SAVED_JOBS = "getSaveJobs",
  GET_SAVED_TRAININGS = "getSaveTraining",
  GET_SAVED_CAREERS = "getSaveCareer",
  GET_APPLIED_JOBS = "getAppliedJobs",
  GET_AWARDS_NOMINATED = "getAwardsNominated",
  SAVE_VOTE_AWARDSNOMINEES = "savevoteawardsnominees",
  SECCURI_AI_SERCH = "SECCURI_AI_SERCH",
  GET_APPLIED_JOBS_COMPANY = "getAppliedJobsCompany",
  GET_APPLIED_TALENT_JOBS_COMPANY = "getAppliedTalentJobCompany",
  GET_JOBS_META = "getJobsMeta",
  GET_AWARD_META = "getAwardMeta",
  GET_JOB_DETAILS = "getJobsById",
  CHANGE_PASSWORD = "changePassword",
  DEACTIVATE_ACCOUNT = "deactivateAccount",
  CHANGE_EMAIL = "changeEmail",
  DELETE_ACCOUNT = "deleteAccount",
  APPLY_JOB = "applyJob",
  SAVE_JOB = "SaveJob",
  SAVE_NOMINATED = "saveNominated",
  SAVE_TRAINING = "SaveTraining",
  SAVE_CAREER = "SaveCareer",
  RECOMMEND_CAREER = "RECOMMEND_CAREER",
  DELETECAREERPATH = "DELETECAREERPATH",
  WORK_CATEGORY = "workAreas",
  WORK_SPECIALITY = "workSpeciality",
  WORK_TASK = "workTask",
  WORK_SKILL = "WORK_SKILL",
  WORK_KNOWLEDGE = "WORK_KNOWLEDGE",
  WORK_ROLE = "workRoles",
  UPDATE_PROFILE = "updateProfile",
  UPDATE_PROFILE_PDF = "UPDATE_PROFILE_PDF",
  CREATE_JOB = "createJob",
  UPDATE_PICTURE_PROFILE = "updatePictureProfile",
  UPDATE_WORK_EXPERIENCE_ITEM = "updateWorkExperienceItem",
  DELETE_WORK_EXPERIENCE_ITEM = "deleteWorkExperienceItem",
  UPDATE_CERTIFICATION_ITEM = "updateCertificationItem",
  DELETE_CERTIFICATION_ITEM = "deleteCertificationItem",
  UPDATE_EDUCATION_ITEM = "updateEducationItem",
  DELETE_EDUCATION_ITEM = "deleteEducationItem",
  UPDATE_COURSE_ITEM = "updateCourseItem",
  DELETE_COURSE_ITEM = "deleteCourseItem",
  UPDATE_LANGUAGE_ITEM = "updateLanguageItem",
  DELETE_LANGUAGE_ITEM = "deleteLanguageItem",
  UPDATE_RECOMMENDATION_ITEM = "updateRecommendationItem",
  DELETE_RECOMMENDATION_ITEM = "deleteRecommendationItem",
  UPDATE_PROFILE_NIST_NICE = "updateProfileNistNice",
  UPDATE_JOB_NIST_NICE = "updateJobNistNice",
  DELETE_PICTURE_PROFILE = "deletePictureProfile",
  UPDATE_COMPANY = "updateCompany",
  ADD_JOB_CERTIFICATION_ITEM = "addJobCertificationItem",
  DELETE_JOB_CERTIFICATION_ITEM = "deleteJobCertificationItem",
  ADD_JOB_LANGUAGE_ITEM = "addJobLanguageItem",
  DELETE_JOB_LANGUAGE_ITEM = "deleteJobLanguageItem",
  UPDATE_JOB = "updateJob",
  GET_COMPANY_JOBS = "getCompanyJobs",
  loginMicrosoft = "loginMicrosoft",
  DELETE_JOB = "deleteJob",
  GET_TOP_TALENT_JOB = "getTopTalentJob",
  GET_TOP_TALENT_JOB_FILTER = "getTopTalentJobFilter",
  REGISTER_EMPLOYEE = "registerEmployee",
  JOIN_TALENT_EMPLOYEE = "joinTalentEmployee",
  DELETE_COMPANY_USERS = "deleteCompanyUsers",
  DELETE_COMPANY_TALENTS = "deleteCompanyTalents",
  DELETE_COMPANY_RELATED_TALENTS = "deleteCompanyRelatedTalents",
  CONFIRM_JOIN_TALENT_COMPANY = "confirmJoinTalentCompany",
  CONFIRM_JOIN_AWARD = "confirmJoinAward",
  CONFIRM_JOIN_NEW_TALENT_COMPANY = "confirmJoinNewTalentCompany",
  CONTACT_TALENT = "contactTalent",
  GET_CURRENT_TALENT_DETAILS = "getCurrentTalentDetails",
  UPDATE_PUBLIC_COMPANY_TALENT_PROFILE = "UPDATE_PUBLIC_COMPANY_TALENT_PROFIL",
  GET_CURRENT_SUBSCRIPTION = "getCurrentSubscription",
  GET_SUBSCRIPTION_URL = "getSubscriptionUrl",
  GET_TRAININGS_LIST = "getTrainingsList",
  GET_TRAININGS_LIST_FILTER = "getTrainingsListFilter",
  GET_BLOG_LIST_FILTER = "GET_BLOG_LIST_FILTER",
  GET_TRAININGS_LIST_FILTER_PROVIDER = "getTrainingsListFilterProvider",
  GET_TRAININGS_LIST_FILTER_LEVEL = "getTrainingsListFilterLevel",
  GET_TRAINING_DETAILS = "getTrainingDetails",
  GET_COURSE_DETAILS = "getCourseDetails",
  TAKE_TRAINING_REQUEST = "takeTrainingRequest",
  TAKE_SUBSCRIPTION_REQUEST = "takeSubscriptionRequest",
  COMPLETE_TRAINING = "completeTraining",
  GET_TALENT_LIST = "getTalentList",
  UPDATE_TOPTALENT = "updateTopTalent",
  SCORE_CAREER_PATH = "CareerPath",
  QUIZ_ATTRIBUTE = "QUIZ_ATTRIBUTE",
  CAREERS = "Careers",
  GET_CAREER_TRAININGS = "getCareerTrainings",
  CHANGE_TRAINING_PROGRESS = "ChangeTrainingProgress",
  GET_TRAININGS_WITH_PROGRESS = "GetTrainingWithProgress",
  CYBERTEAM_COMPANY_TALENTS = "cyberteam_company_talents",
  EMPLOYEE_CAREER_PATH = "employee_career_path",
  CHANGE_COMMUNICATION_STATUS_APPLIED_TALENT = "change_communication_status_applied_tallent",
  UPDATE_COMMUNICATION_PREFERENCES = "UPDATE_COMMUNICATION_PREFERENCES",
  GET_COMMUNICATION_PREFERENCES = "GET_COMMUNICATION_PREFERENCES",
  GET_CONNECTED_ACCOUNTS = "GET_CONNECTED_ACCOUNTS",
  EDIT_TRAINING_LEVEL_CAREER_PATH = "EDIT_TRAINING_LEVEL_CAREER_PATH",
  CREATE_CAREER_PATH = "CREATE_CAREER_PATH",
  GET_NEW_TO_CYBER_TRAININGS_LIST = "GET_NEW_TO_CYBER_TRAININGS_LIST",
  SCORE_CAREER_PATH_EMPLOYEE = "SCORE_CAREER_PATH_EMPLOYEE",
  TALENT_SCORE_CAREER_PATH = "TALENT_SCORE_CAREER_PATH",
  CREATE_CAREER_PATH_COMPANY = "CREATE_CAREER_PATH_COMPANY",
  EDIT_TRAINING_LEVEL_CAREER_PATH_COMPANY = "EDIT_TRAINING_LEVEL_CAREER_PATH_COMPANY",
  IMPROVE_DESCRIPTION = "IMPROVE_DESCRIPTION",
}

enum Mutations {
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_MESSAGE = "setMessage",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  SET_AUTH_TOKEN = "setAuthToken",
  SET_JOBS = "filteredJobs",
  SET_NEW_TO_CYBER_JOBS = "SET_NEW_TO_CYBER_JOBS",
  GET_TRAININGS_META_MUTATION = "filterTrainingsMutation",
  GET_JOBS_META_MUTATION = "filterJobsMutation",
  GET_AWARD_META_MUTATION = "filterAwardMutation",
  JOB_DETAILS = "getJobsdetailsMutation",
  CHANGE_PASSWORD = "changePassword",
  CHANGE_EMAIL = "changeEmail",
  SET_APPLY_JOB = "setApplyJob",
  SAVE_VOTE_AWARDSNOMINEES_MUTATION = "savevoteawardsnomineesmutation",
  SECCURI_AI_SERCH_MUTATION = "SECCURI_AI_SERCH_MUTATION",
  SET_SAVE_JOB = "setSaveJob",
  SET_SAVE_TRAINING = "setSaveTraining",
  SET_SAVE_TRAINING_CAREERPATH = "SET_SAVE_TRAINING_CAREERPATH",
  SET_SAVE_CAREER = "setSaveCareer",
  DELETE_CAREER_MUTATION = "DELETE_CAREER_MUTATION",
  GET_SAVED_JOBS_MUTATION = "GetSaveJobMutation",
  GET_SAVED_TRAINING_MUTATION = "GetSaveTrainingMutation",
  GET_SAVED_CAREER_MUTATION = "GetSaveCareerMutation",
  GET_TRAININGS_WITH_PROGRESS_MUTATION = "GeTrainingWithProgressMutation",
  GET_APPLIED_JOBS_MUTATION = "GetAppliedJobMutation",
  GET_AWARDS_NOMINATED_MUTATION = "GetAwardsNominatedMutation",
  GET_APPLIED_JOBS_COMPANY_MUTATION = "GetAppliedJobsCompanyMutation",
  GET_BLOGS_CMS_MUTATION = "GetBlogsCMSMutation",
  GET_APPLIED_TALENT_JOBS_COMPANY_MUTATION = "GetAppliedTalentJobsCompanyMutation",
  SET_WORK_CATEGORY = "setWorkCategories",
  SET_WORK_TASKS = "setWorkTask",
  SET_WORK_SKILLS = "SET_WORK_SKILLS",
  SET_WORK_KNOWLEDGES = "SET_WORK_KNOWLEDGES",
  SET_WORK_ROLES = "setWorkRoles",
  QUIZ_ATTRIBUTE_MUTATION = "QUIZ_ATTRIBUTE_MUTATION",
  SET_WORK_SPECIALTY = "setWorkSpecialty",
  UPDATE_PROFILE_MUTATION = "updateProfileMutation",
  UPDATE_PDF_MUTATION = "UPDATE_PDF_MUTATION",
  UPDATE_WORK_EXPERIENCE_ITEM_MUTATION = "updateWorkExperienceItemMutation",
  DELETE_WORK_EXPERIENCE_ITEM_MUTATION = "deleteWorkExperienceItemMutation",
  UPDATE_CERTIFICATION_ITEM_MUTATION = "updateCertificationItemMutation",
  ADD_JOB_CERTIFICATION_ITEM_MUTATION = "addJobCertificationItemMutation",
  DELETE_CERTIFICATION_ITEM_MUTATION = "deleteCertificationItemMutation",
  DELETE_JOB_CERTIFICATION_ITEM_MUTATION = "deleteJobCertificationItemMutation",
  UPDATE_EDUCATION_ITEM_MUTATION = "updateEducationItemMutacion",
  DELETE_EDUCATION_ITEM_MUTATION = "deleteEducationItemMutacion",
  UPDATE_COURSE_ITEM_MUTATION = "updateCourseItemMutacion",
  DELETE_COURSE_ITEM_MUTATION = "deleteCourseItemMutacion",
  UPDATE_LANGUAGE_ITEM_MUTATION = "updateLanguageItemMutacion",
  ADD_JOB_LANGUAGE_ITEM_MUTATION = "addJobLanguageItemMutacion",
  DELETE_JOB_LANGUAGE_ITEM_MUTATION = "deleteJobLanguageItemMutacion",
  DELETE_LANGUAGE_ITEM_MUTATION = "deleteLanguageItemMutacion",
  UPDATE_RECOMMENDATION_ITEM_MUTATION = "updateRecommendationItemMutacion",
  DELETE_RECOMMENDATION_ITEM_MUTATION = "deleteRecommendationItemMutacion",
  UPDATE_NISTNICE_SPECIALITY = "updateNistNiceSpeciality",
  UPDATE_NISTNICE_TASK = "updateNistNiceTask",
  MREGE_CATEGORIES_NIST_NICE = "mergeCategoriesNistNice",
  MREGE_SPECIALITIES_NIST_NICE = "mergeSpecialitiesNistNice",
  MREGE_TASKS_NIST_NICE = "mergeTasksNistNice",
  MREGE_NIST_NICE = "mergeNistNice",
  MERGE_NIST_NICE_AI = "MERGE_NIST_NICE_AI",
  UPDATE_CURRENT_ROLE = "updateCurrentRole",
  UPDATE_CURRENT_SUBSCRIPTION = "updateCurrentSubscription",
  CREATE_JOB_MUTATION = "createJobMutation",
  CLEAR_CURRENT_JOB = "clearJobMutation",
  UPDATE_CURRENT_JOB = "updateCurrentJob",
  UPDATE_CURRENT_ROLES = "UPDATECURRENROLES",
  VIEW_NOMINEE_PROFILE = "VIEW_NOMINEE_PROFILE",
  VIEW_APPLIED_TALENTS_JOB_MUTATION = "ViewAppliedTalentJobMutation",
  UPDATE_APPLIED_JOB_SELECTED = "UpdateAppliedJobSelected",
  DELETE_JOB_MUTATION = "deleteJobMutation",
  GET_TOP_TALENT_JOB_MUTATION = "getTopTalentJobMutation",
  SET_STORE_MUTATION = "setStoreMutation",
  UPDATE_COMPANY_USERS = "updateCompanyUsers",
  UPDATE_COMPANY_TALENT_EMPLOYEE = "updateCompanyTalentEmployee",
  DELETE_COMPANY_USERS_MUTATION = "deleteCompanyUsersMutation",
  DELETE_COMPANY_TALENT_MUTATION = "deleteCompanyTalentMutation",
  DELETE_COMPANY_RELATED_TALENT_MUTATION = "deleteCompanyRelatedTalentMutation",
  UPDATE_CURRENT_EMPLOYEE = "updateCurrentEmployee",
  UPDATE_CURRENT_TALENT_DETAILS = "updateCurrentTalentDetails",
  GET_CURRENT_SUBSCRIPTION_MUTATION = "getCurrentSubscriptionMutation",
  GET_SUBSCRIPTION_URL_MUTATION = "getSubscriptionUrlMutation",
  GET_TRAININGS_LIST_MUTATION = "getTrainingsListMutation",
  GET_NEW_TO_CYBER_TRAININGS_LIST_MUTATION = "GET_NEW_TO_CYBER_TRAININGS_LIST_MUTATION",
  GET_TRAININGS_LIST_FILTER_MUTATION = "getTrainingsListFilterMutation",
  GET_TRAININGS_LIST_FILTER_PROVIDER_MUTATION = "getTrainingsListFilterProviderMutation",
  GET_TRAININGS_LIST_FILTER_LEVEL_MUTATION = "getTrainingsListFilterLevelMutation",
  GET_TRAINING_DETAILS_MUTATION = "getTrainingDetailsMutation",
  SET_TRAINING_DETAILS_MUTATION = "SetTrainingDetailsMutation",
  GET_COURSE_DETAILS_MUTATION = "getCourseDetailsMutation",
  SET_COMPLETE_TRAINING_MUTATION = "completeTrainingMutation",
  GET_TALENT_LIST_MUTATION = "getTalentListMutation",
  SUBSCRIPTION_SELECTED = "SUBSCRIPTION_SELECTED",
  SET_UPDATED_PASSWORD_ALERT_MUTATION = "setUpdatedPasswordAlert",
  SET_CAREER_PATH = "setCareerPath",
  SET_CAREERS = "setCareers",
  CAREER_PATH_COMPLETE = "selectedCareerPath",
  TRAININGS_CAREER_PATH_COMPLETE = "TrainingsCareerPathComplete",
  CONFIRM_JOIN_TALENT_COMPANY_MUTATION = "confirmJoinTalentCompanyMutation",
  CONFIRM_JOIN_AWARD_MUTATION = "confirmJoinAwardMutation",
  SET_CAREER_PATH_TALENT = "SetCareerPathTalent",
  SET_SEARCH_AWARDS = "SetSearchAwards",
  UPDATE_APPLIED_JOB = "UpdateApplied_Job",
  RELOAD_JOBS_MUTATION = "ReloadJobsMutation",
  RELOAD_TRAININGS_MUTATION = "ReloadTrainingsMutation",
  CYBERTEAM_COMPANY_TALENTS_MUTATION = "cyberteam_company_talents_mutation",
  EMPLOYEE_CAREER_PATH_MUTATION = "employee_career_path_mutation",
  CURRRENT_MASTER_DATA = "current_master_data",
  Current_languageCode = "Current_languageCode",
  CurrentFeatureSubcription = "CurrentFeatureSubcription",
  SET_SAVE_NOMINATED = "Set_Save_Nominated",
  GET_COMMUNICATION = "GET_COMMUNICATION",
  GET_CONNECTED_ACCOUNTS_MUTATION = "GET_CONNECTED_ACCOUNTS_MUTATION",
  EDIT_TRAINING_LEVEL_CAREER_PATH_MUTATION = "EDIT_TRAINING_LEVEL_CAREER_PATH_MUTATION",
  CREATE_CAREER_PATH_MUTATION = "CREATE_CAREER_PATH_MUTATION",
  SET_LEVEL_CAREER_TRAINING = "SET_LEVEL_CAREER_TRAINING",
  CAREER_PATH_DETAILS_COMPANY = "CAREER_PATH_DETAILS_COMPANy",
  CREATE_CAREER_PATH_COMPANY_MUTATION = "CREATE_CAREER_PATH_COMPANY_MUTATION",
  EDIT_TRAINING_LEVEL_CAREER_PATH_COMPANY_MUTATION = "EDIT_TRAINING_LEVEL_CAREER_PATH_COMPANY_MUTATION",
  SET_LEVEL_CAREER_TRAINING_COMPANY = "SET_LEVEL_CAREER_TRAINING_COMPANY",
  SET_CAREER_PATH_EMPLOYEE = "SET_CAREER_PATH_EMPLOYEE",
  RECOMMEND_CAREER_MUTATION = "RECOMMEND_CAREER_MUTATION",
  SET_IMPROVED_TEXT = "SET_IMPROVED_TEXT",
  NistNiceQuizResponse_MUTATION = "NistNiceQuizResponseMutation",
}

export { Actions, Mutations };
